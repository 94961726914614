import { useState, useEffect, useContext } from 'react'
import {DataContext} from "../../context/data/data.context";

export const usePaymentModContext = () => {
    const dataContext = useContext(DataContext)
    const [paymentModContext, setPaymentModContext] = useState<any>([]);
    const [paymentModList, setPaymentModList] = useState<any>([]);
    const [activePaymentModList, setActivePaymentModList] = useState<any>([]);

    useEffect(() => {
        setPaymentModContext(dataContext.paymentModContext);

        if (!dataContext.paymentModContext.isLoaded) {
            dataContext.loadPaymentMod()
        }
        else {
            const items = dataContext.paymentModContext.items
            loadPaymentModList(items);
            loadActivePaymentModList(items);
        }
    }, [dataContext.paymentModContext.isLoaded]);

    const loadPaymentModList = (list: any) => {
        setPaymentModList(list);
    }

    const loadActivePaymentModList = (list: any) => {
        const activeList = list.filter(item => item.status === 1)
        setActivePaymentModList(activeList);
    }

    const getPaymentModById = (id) => {
        if(!paymentModList) {
            return;
        }
        return paymentModList.find(item => item.key === id)
    }

    const setPaymentModFieldValue = (formRef, key, value) => {
        if (activePaymentModList?.find(item => item.key ==  value )) {
            formRef.setFieldsValue({[key]: value})
        }
        else {
            formRef.setFieldsValue({[key]: null})
        }
    }

    return { paymentModContext, paymentModList, activePaymentModList, setPaymentModFieldValue, getPaymentModById }
}