import React, {FC, useEffect, useState} from 'react'
import {Table} from "antd";
import {Message, NumberUtils} from "../../../../../utils";
import {PricingApi} from "../../../../../api";
import '../../../styles/Scm.module.listing.scss';
import '../List/PricingList.style.scss';

const initialState  = {
    listData: [],
    loading: false,
}

const PricingContentCustomer: FC<any> = props => {
    const { customerId, salesOrderId, parentSalesOrderId, reloadTab } = props;

    const [listData, setListData] = useState<any[]>(initialState.listData);
    const [loading, setLoading] = useState(initialState.loading);

    useEffect(() => {
        if (customerId) {
            loadData();
        }
    }, [customerId, salesOrderId, reloadTab])

    const loadData = (): void => {
        setLoading(true);
        const payload = {
            'customer_id': customerId,
            'sales_order_id': parentSalesOrderId || salesOrderId,
         }
        PricingApi.salesOrderPricingList(payload)
            .then(res => {
                prepareListData(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.error("error", err);
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
            });
    }

    const prepareListData = (dataArray) => {
        setListData(dataArray);
    }

    const columns = [
        {
            dataIndex: "product_type",
            key: 'product_type',
            title: "Product Type",
        },
        {
            dataIndex: "delivery_mode",
            key: 'delivery_mode',
            title: "Delivery Mode",
        },
        {
            dataIndex: "warehouse",
            key: 'warehouse',
            title: "Warehouse",
        },
        {
            dataIndex: "price",
            key: 'price',
            title: "Price",
            render: (text: string, record: any, index:number) => NumberUtils.formatNumber(text),
        }
    ];

    return (
        <div className="listing-content listing-content-security">
            <Table
                className="table-layout"
                rowKey={record => record.id.toString()}
                rowClassName={(record, index) => (index % 2 === 0 ? 'odd' : 'even')}
                dataSource={listData}
                columns={columns}
                pagination={false}
                loading={loading}
                bordered={false}
            />
        </div>
    );
}

export default PricingContentCustomer;
