import React from 'react';
import { Switch } from 'react-router-dom';
import {AuthLayout, MainLayout, SettingLayout, ToDoListLayout} from "../theme";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";

const AuthRoutes = React.lazy(() => import('./../modules/Auth/AuthRoutes'));
const PagesRoutes = React.lazy(() => import('./../modules/Pages/PagesRoutes'));

const ToDoListRoutes = React.lazy(() => import('../modules/ToDoList/ToDoListRoutes'));
const ReportRoutes = React.lazy(() => import('../modules/Report/ReportRoutes'));
const SettingRoutes = React.lazy(() => import('../modules/Setting/SettingRoutes'));
const UIKitRoutes = React.lazy(() => import('../components/UIKit/UIKitRoutes'));
const AccessDenied = React.lazy(() => import('../components/Error/AccessDenied/AccessDenied'));
const NotFound = React.lazy(() => import('../components/Error/NotFound/NotFound'));

const DealerList = React.lazy(() => import('../modules/Profile/components/Dealer/List/DealerList.controller'));

const SalesOrderList = React.lazy(() => import('../modules/Order/components/SalesOrder/List/SalesOrderList.controller'));
const SalesOrderBookingList = React.lazy(() => import('../modules/Order/components/SalesOrder/Booking/List/SalesOrderBookingList.controller'));

const DeliveryOrderList = React.lazy(() => import('../modules/Order/components/DeliveryOrder/List/DeliveryOrderList.controller'));
const ChallanList = React.lazy(() => import('../modules/Order/components/Challan/List/ChallanList.controller'));
const CreditLimitList = React.lazy(() => import('../modules/Order/components/CreditLimit/List/CreditLimitList.controller'));
const CollectionList = React.lazy(() => import('../modules/Order/components/Collection/List/CollectionList.controller'));

const DashboardRoutes = React.lazy(() => import('../modules/Dashboard/DashboardRoutes'));

const Routes: React.FC = () => {
  return (
      <Switch>
          <ProtectedRoute layout={MainLayout} exact path="/" component={DashboardRoutes} />
          <PublicRoute layout={MainLayout} path="/page" component={PagesRoutes} />
          <PublicRoute layout={AuthLayout} path="/auth" component={AuthRoutes} />
          <PublicRoute layout={ToDoListLayout} path="/workspace" component={ToDoListRoutes} />
          <PublicRoute layout={MainLayout} path="/report" component={ReportRoutes} />
          <PublicRoute layout={SettingLayout} path="/setting" component={SettingRoutes} />
          <PublicRoute layout={MainLayout} path="/ui-kit" component={UIKitRoutes} />

          <ProtectedRoute layout={MainLayout} exact path="/dealer" component={DealerList} />
          <ProtectedRoute layout={MainLayout} exact path="/collection" component={CollectionList} />
          <ProtectedRoute layout={MainLayout} exact path="/challan" component={ChallanList} />
          <ProtectedRoute layout={MainLayout} exact path="/delivery-order" component={DeliveryOrderList} />
          <ProtectedRoute layout={MainLayout} exact path="/sales-order" component={SalesOrderList} />
          <ProtectedRoute layout={MainLayout} exact path="/sales-order-multi-schedule" component={SalesOrderBookingList} />
          <ProtectedRoute layout={MainLayout} exact path="/credit-limit" component={CreditLimitList} />
          <ProtectedRoute layout={MainLayout} exact path="/dashboard" component={DashboardRoutes} />
          <ProtectedRoute layout={MainLayout} exact path="/access/denied" component={AccessDenied} />
          <ProtectedRoute layout={MainLayout} component={NotFound} />
      </Switch>
  );
};

export default Routes;