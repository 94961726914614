import {
  UPDATE_MENU_KEY,
  UPDATE_SETTING,
  UPDATE_TAB_GROUP,
} from './setting.types';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_SETTING:
      return {
        ...state,
        fields: action.payload.fields,
        isLoaded: true,
      };

    case UPDATE_TAB_GROUP:
      return {
        ...state,
        headerTabGroup: action.payload.headerTabGroup,
      };

    case UPDATE_MENU_KEY:
      return {
        ...state,
        activeMenuKey: action.payload.activeMenuKey,
      };

    default:
      return state;
  }
};