import React, { Fragment, useState } from 'react'

export const ErrorBoundary = ({ children }: any) => {
    const [hasError, setHasError] = useState(false)

    if (hasError) {
        return (
            <Fragment>
                <p>
                    <strong>Sorry, An unexpected error has occurred. Please refresh the page and try again.</strong>
                </p>
            </Fragment>
        );
    }

    return children
}