import companyLogo from './company-logo.png';
import adminloginLogo from './adminlogin-logo.png';
import LogoLogin from './logo-login.png';
import SpinnerGif from './spinner.gif';
import notificationIcon from './icon/notification-icon.png';
import ImgLazyLoading from './placeholder/img-lazy-loading.svg';
import ImageNotFound from './placeholder/image-not-found.jpg';
import WorkspaceBanner from './banner/workspace.png';
import SettingBanner from './banner/settings.png';
// import bgicon from './icons/bgicon.png';
// import nurtech from './icon/nurtech.png';

const images = {
    icons: {
        SpinnerGif,
        notificationIcon,
    },
    placeholder: {
        ImgLazyLoading,
        ImageNotFound
    },
    layout: {
        companyLogo,
        adminloginLogo,
        LogoLogin
    },
    common: {

    },
    assets: {

    },
    banner: {
        WorkspaceBanner,
        SettingBanner,
    },
}

export default images;