import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.SCM_SERVER_PREFIX}/report`
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getProductWiseSales: () => `${RESOURCE_ENDPOINT}/product-wise-sales`,
    getDealerWiseSales: () => `${RESOURCE_ENDPOINT}/dealer-wise-sales`,
    getTallyDealerSalesReport: () => `${RESOURCE_ENDPOINT}/tally-wise-dealer-sales-report`,
    getDealerWiseDeliveryReport: () => `${RESOURCE_ENDPOINT}/dealer-wise-delivery`,
    getNonTradePriceHistoryReport: () => `${RESOURCE_ENDPOINT}/non-trade-price-history-report`,
    getChallanReport: () => `${RESOURCE_ENDPOINT}/challan`,
    getSecurityReport: () => `${RESOURCE_ENDPOINT}/security-report`,
    getCreditLimitReport: () => `${RESOURCE_ENDPOINT}/credit-limit-report`,
    getCollectionTargetReport: () => `${RESOURCE_ENDPOINT}/collection-target-report`,
    getMonetaryReport: () => `${RESOURCE_ENDPOINT}/monetary-report`,
    getGrandTotalReport: () => `${RESOURCE_ENDPOINT}/grand-total-report`,    
    getDealerLedgerReport: () => `${RESOURCE_ENDPOINT}/ledger-daily-basis-report`,
    getLedgerEventBasisReport: () => `${RESOURCE_ENDPOINT}/ledger-event-basis-report`,
    getInventoryReport: () => `${RESOURCE_ENDPOINT}/inventory-report`,
    getAgeingReport: () => `${RESOURCE_ENDPOINT}/ageing-report`,
    getSalesTargetReport: () => `${RESOURCE_ENDPOINT}/sales-target-report`,
    getSalesQuantityReport: () => `${RESOURCE_ENDPOINT}/sales-quantity-report`,
    getDealerAndRetailerWiseSalesQuantityReport: () => `${RESOURCE_ENDPOINT}/dealer-and-retailer-wise-sales-quantity-report`,
    getClientAndProjectWiseSalesQuantityReport: () => `${RESOURCE_ENDPOINT}/client-and-project-wise-sales-quantity-report`,
}

export default class ReportApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    }

    public getProductWiseSales = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getProductWiseSales();
        return HttpService.get(url, params, headers);
    }

    public getDealerWiseSales = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDealerWiseSales();
        return HttpService.get(url, params, headers);
    }

    public getTallyDealerSalesReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTallyDealerSalesReport();
        return HttpService.get(url, params, headers);
    }

    public getDealerWiseDeliveryReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDealerWiseDeliveryReport();
        return HttpService.get(url, params, headers);
    }

    public getNonTradePriceHistoryReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNonTradePriceHistoryReport();
        return HttpService.get(url, params, headers);
    }

    public getChallanReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getChallanReport();
        return HttpService.get(url, params, headers);
    }

    public getSecurityReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSecurityReport();
        return HttpService.get(url, params, headers);
    }

    public getCreditLimitReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getCreditLimitReport();
        return HttpService.get(url, params, headers);
    }

    public getCollectionTargetReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getCollectionTargetReport();
        return HttpService.get(url, params, headers);
    }

    public getMonetaryReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getMonetaryReport();
        return HttpService.get(url, params, headers);
    }

    public getGrandTotalReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getGrandTotalReport();
        return HttpService.get(url, params, headers);
    }

    public getDealerLedgerReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDealerLedgerReport();
        return HttpService.get(url, params, headers);
    }

    public getLedgerEventBasisReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getLedgerEventBasisReport();
        return HttpService.get(url, params, headers);
    }

    public getInventoryReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getInventoryReport();
        return HttpService.get(url, params, headers);
    }

    public getAgeingReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getAgeingReport();
        return HttpService.get(url, params, headers);
    }

    public getSalesTargetReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSalesTargetReport();
        return HttpService.get(url, params, headers);
    }

    public getSalesQuantityReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSalesQuantityReport();
        return HttpService.get(url, params, headers);
    }

    public getDealerAndRetailerWiseSalesQuantityReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDealerAndRetailerWiseSalesQuantityReport();
        return HttpService.get(url, params, headers);
    }

    public getClientAndProjectWiseSalesQuantityReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getClientAndProjectWiseSalesQuantityReport();
        return HttpService.get(url, params, headers);
    }

}