import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDTC_Gxc0haAnlIebIpxzMUouk_Ng2H71o",
    authDomain: "e-mapp.firebaseapp.com",
    projectId: "e-mapp",
    storageBucket: "e-mapp.appspot.com",
    messagingSenderId: "134570499013",
    appId: "1:134570499013:web:2be09c95e0e05d968609ac",
    measurementId: "G-PBF76Q4CLT"
};

export default class PushNotificationService {

    private messaging: firebase.messaging.Messaging;

    constructor() {
        firebase.initializeApp(firebaseConfig);
        this.messaging = firebase.messaging();
    }

    public getPushToken = ():Promise<any> => {
        return new Promise((resolve, reject) => {
            this.messaging
                .getToken()
                .then((fcmToken: string) => {
                    resolve(fcmToken);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    public requestPermission = ():Promise<any> => {
        return new Promise((resolve, reject) => {
            this.messaging
                .requestPermission()
                .then(() => {
                    return this.messaging.getToken()
                })
                .then((fcmToken: string) => {
                    resolve(fcmToken);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    public showNotification = (title, message, icon = null) => {
        if (!window.Notification) {
            return;
        }

        if (Notification.permission !== "granted") {
            return;
        }

        if(!document.hidden){
            return new Notification(title, {
                body: message,
                icon: 'push-notification-web-icon.png',
            });
        }

    }

}
