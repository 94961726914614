import React, { FC, Fragment, useEffect} from 'react'
import {Form, Input, Row, Col, Select, DatePicker, Radio} from 'antd';
import {CONSTANT_COMMON} from "../../../../../constants";
import {useAdjustmentTypeContext} from "../../../../../hooks/context/useAdjustmentTypeContext";
import {useCustomerContext} from "../../../../../hooks/context/useCustomerContext";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const AddOrEditForm: FC<any> = props => {
    const { Option } = Select;
    const { TextArea } = Input;
    const { adjustmentTypeContext, setAdjustmentTypeFieldValue, activeAdjustmentTypeList} = useAdjustmentTypeContext();
    const { customerContext, setCustomerFormFieldValue, customerList} = useCustomerContext();
    const { formRef, itemData, rules, initialValues, formValues, customerInfo, handleChange, handleSubmit, handleSubmitFailed } = props;

    useEffect(() => {
        if (itemData.customer_id && customerContext.isLoading == false) {
            setCustomerFormFieldValue(formRef, 'customer_id', itemData.customer_id);
        }
        if (itemData.adjustment_type && adjustmentTypeContext.isLoading == false) {
            setAdjustmentTypeFieldValue(formRef, 'adjustment_type', itemData.adjustment_type);
        }
    }, [itemData, customerContext.isLoading]);

    return (
        <Fragment>
            <div className="form-page-content form-page-content-adjustment">
                <Form
                    {...formItemLayout}
                    form={formRef}
                    name="adjustmentForm"
                    scrollToFirstError={true}
                    initialValues={initialValues}
                    onValuesChange={handleChange}
                    onFinish={handleSubmit}
                    onFinishFailed={handleSubmitFailed}
                >
                    <Row>
                        <Col span={24}>
                            {!customerInfo?.id && (
                            <Form.Item
                                label="Customer Group"
                                name="customer_group"
                                rules={rules.customer_group}
                            >
                                <Radio.Group
                                    onChange={e => formRef.setFieldsValue({customer_id: null}) }
                                >
                                    <Radio value={'TRADE'}>Trade</Radio>
                                    <Radio value={'NONTRADE'}>Non-trade</Radio>
                                </Radio.Group>
                            </Form.Item>
                            )}

                            {!customerInfo?.id && (
                            <Form.Item
                                label="Dealer/Client"
                                name="customer_id"
                                rules={rules.customer_id}
                            >
                                <Select
                                    placeholder="-- Select --"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                    }
                                    loading={customerContext.isLoading}
                                >
                                    {customerList && customerList.filter((item) => item.customer_group == formValues.customer_group).map((item, index) => (
                                        <Option key={`customer-${index}`} value={item.id}>{`${item.org_name} [${item.code}, ${item.division_name}]`}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            )}

                            <Form.Item
                                label="Adjustment Type"
                                name="adjustment_type"
                                rules={rules.adjustment_type}
                            >
                                <Select
                                    placeholder="-- Select --"
                                    loading={adjustmentTypeContext.isLoading}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {activeAdjustmentTypeList && activeAdjustmentTypeList.map((item, index) => (
                                        <Option key={`adjustment-type-${index}`} value={item.key}>{item.value}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={rules.amount}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Date"
                                name="date"
                                rules={rules.date}
                            >
                                <DatePicker
                                    format={CONSTANT_COMMON.DATE_PICKER_DATE_FORMAT}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Ref. SO No"
                                name="sales_order_no"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Ref. Challan No"
                                name="challan_no"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Note"
                                name="note"
                            >
                                <TextArea
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Fragment>
    );
}
export default React.memo(AddOrEditForm);