const initialState = {
  isLoading: false,
  isLoaded: false,
  userContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  roleContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  employeeContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  customerContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  clientContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  dealerContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  retailerContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  projectContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  driverContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  divisionContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  districtContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  thanaContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  departmentContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  designationContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  productContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  warehouseContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  addressContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  vehicleContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  securityTypeContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  adjustmentTypeContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  bagTypeContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  paymentModContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  bankListContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  error: null,

  loadDivision: () => null,
  loadDistrict: () => null,
  loadThana: () => null,
  loadProduct: () => null,
  loadDepartment: () => null,
  loadDesignation: () => null,
  loadWarehouse: () => null,
  loadUser: () => null,
  loadRole: () => null,
};

export default initialState;
