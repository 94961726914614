import {
  UPDATE_NOTIFICATION_COUNTER_BADGE,
  UPDATE_SALES_ORDER_COUNTER_BADGE,
  UPDATE_DELIVERY_ORDER_COUNTER_BADGE,
  UPDATE_CHALAN_COUNTER_BADGE,
  UPDATE_COLLECTION_COUNTER_BADGE,
  UPDATE_CREDIT_LIMIT_COUNTER_BADGE,
  UPDATE_RETAILER_COUNTER_BADGE,
} from './count.types';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION_COUNTER_BADGE:
      return {
        ...state,
        countNotificationBadge: action.payload.updateType == 'UPDATE_VALUE' ? action.payload.value : state.countNotificationBadge + 1,
      };

    case UPDATE_SALES_ORDER_COUNTER_BADGE:
      return {
        ...state,
        countSalesOrderBadge: action.payload.updateType == 'UPDATE_VALUE' ? action.payload.value : state.countSalesOrderBadge + 1,
      };

    case UPDATE_DELIVERY_ORDER_COUNTER_BADGE:
      return {
        ...state,
        countDeliveryOrderBadge: action.payload.updateType == 'UPDATE_VALUE' ? action.payload.value : state.countDeliveryOrderBadge + 1,
      };

    case UPDATE_CHALAN_COUNTER_BADGE:
      return {
        ...state,
        countChalanBadge: action.payload.updateType == 'UPDATE_VALUE' ? action.payload.value : state.countChalanBadge + 1,
      };

    case UPDATE_COLLECTION_COUNTER_BADGE:
      return {
        ...state,
        countCollectionBadge: action.payload.updateType == 'UPDATE_VALUE' ? action.payload.value : state.countCollectionBadge + 1,
      };

    case UPDATE_CREDIT_LIMIT_COUNTER_BADGE:
      return {
        ...state,
        countCreditLimitBadge: action.payload.updateType == 'UPDATE_VALUE' ? action.payload.value : state.countCreditLimitBadge + 1,
      };

    case UPDATE_RETAILER_COUNTER_BADGE:
      return {
        ...state,
        countRetailerBadge: action.payload.updateType == 'UPDATE_VALUE' ? action.payload.value : state.countRetailerBadge + 1,
      };

    default:
      return state;
  }
};