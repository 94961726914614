import React, {FC, useEffect, useState} from 'react'
import SalesOrderViewDrawer from "./SalesOrderView.drawer";
import {Message} from "../../../../../utils";
import {CustomerApi, SalesOrderApi} from "../../../../../api";
import {useEmployeeContext} from "../../../../../hooks/context/useEmployeeContext";
import {CommonUtils} from "../../../../../utils";
import '../../../styles/Order.module.view.scss';
import './SalesOrderView.style.scss';

interface IProps {
    entityId: any,
    reloadView: any,
    [key: string]: any,
}

const initialState  = {
    modalTitle: 'Sales Order Info',
    itemData: {
        id: null,
        customer_id: null,
        amount_credit_limit: null,
        days_credit_limit: null,
        closing_balance: null,
        dso: null,
        rolling: null,
        block_amount: null,
        today_collection: null,
        pending_qty: null,
    },
    customerInfo: {
        id: null,
        org_name: null,
        contact_person: null,
        address: null,
        contact_number: null,
        amount_credit_limit: null,
        days_credit_limit: null,
        closing_balance: null,
        dso: null,
        rolling: null,
        block_amount: null,
        today_collection: null,
        pending_qty: null,
    },
    employeeInfo: {
        id: null,
        name: null,
    },
    loading: false,
}

const SalesOrderViewController: FC<IProps> = props => {
    const { entityId, reloadView, isShowView, viewTabIndex, handleCallbackFunc, ...restProps } = props

    const modalTitle = initialState.modalTitle;
    const [itemData, setItemData] = useState<any>(initialState.itemData);
    const [employeeInfo, setEmployeeInfo] = useState(initialState.employeeInfo);
    const [customerInfo, setCustomerInfo] = useState<any>(initialState.customerInfo);
    const { employeeContext, getEmployeeById } = useEmployeeContext();
    const [loading, setLoading] = useState(initialState.loading);

    useEffect(() => {
        if (entityId && isShowView && employeeContext.isLoaded) {
            loadData();
        }
    }, [employeeContext.isLoaded, entityId, reloadView])

    const loadData = (): Promise<any> => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            loadSalesOrderData()
                .then(res => {
                    return Promise.all([
                        res,
                        loadCustomerInfo(res.customer_id),
                        loadEmployeeInfo(res.employee_id),
                    ])
                })
                .then(res => {
                    overwriteSalesOrderData(res[0], res[1]);
                    setLoading(false);
                    resolve(res);
                })
                .catch(function (err) {
                    setLoading(false);
                    reject(err);
                });
        });
    }

    const loadSalesOrderData = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            SalesOrderApi.getById(entityId)
                .then(res => {
                    // setItemData(res.data);
                    resolve(res.data);
                })
                .catch(err => {
                    Message.error('A network error occurred. Please try again later.');
                    reject(err);
                });
        });
    }

    const loadCustomerInfo = (customerId: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            CustomerApi.getById(customerId)
                .then(res => {
                    setCustomerInfo(res.data);
                    resolve(res.data);
                })
                .catch(err => {
                    Message.error('A network error occurred. Please try again later.');
                    reject(err);
                });
        });
    }

    const loadEmployeeInfo = (employeeId: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            try {
                const employeeInfo = getEmployeeById(employeeId);
                setEmployeeInfo(employeeInfo);
                resolve(employeeInfo);
            }
            catch (err) {
                reject(err);
            }
        });
    }

    const overwriteSalesOrderData = (salesOrderItemData, customerInfo): void => {
        setItemData({
            ...salesOrderItemData,
            amount_credit_limit:    CommonUtils.isNotEmpty(salesOrderItemData.amount_credit_limit) ? salesOrderItemData.amount_credit_limit : customerInfo.amount_credit_limit,
            days_credit_limit:      CommonUtils.isNotEmpty(salesOrderItemData.days_credit_limit) ? salesOrderItemData.days_credit_limit : customerInfo.days_credit_limit,
            closing_balance:        CommonUtils.isNotEmpty(salesOrderItemData.closing_balance) ? salesOrderItemData.closing_balance : customerInfo.closing_balance,
            dso:                    CommonUtils.isNotEmpty(salesOrderItemData.dso) ? salesOrderItemData.dso : customerInfo.dso,
            rolling:                CommonUtils.isNotEmpty(salesOrderItemData.rolling) ? salesOrderItemData.rolling : customerInfo.rolling,
            block_amount:           CommonUtils.isNotEmpty(salesOrderItemData.block_amount) ? salesOrderItemData.block_amount : customerInfo.block_amount,
            today_collection:       CommonUtils.isNotEmpty(salesOrderItemData.today_collection) ? salesOrderItemData.today_collection : customerInfo.today_collection,
            pending_qty:            CommonUtils.isNotEmpty(salesOrderItemData.pending_qty) ? salesOrderItemData.pending_qty : customerInfo.pending_qty,
        });
    }

    return (
        <SalesOrderViewDrawer
            {...restProps}
            isShowView={isShowView}
            loading={loading}
            reloadView={reloadView}
            modalTitle={modalTitle}
            itemData={itemData}
            employeeInfo={employeeInfo}
            customerInfo={customerInfo}
            viewTabIndex={viewTabIndex}
            handleCallbackFunc={handleCallbackFunc}
        />
    );
}

export default React.memo(SalesOrderViewController);
