import React, { useEffect, useState} from 'react';
import {FileApi} from "../../api";

interface Props {
    fileId: string;
}

const FileName: React.FC<Props> = ({ fileId }) => {
    const [fileName, setFileName] = useState('')

    useEffect(() => {
        FileApi.getFileById(fileId)
            .then(res => {
                setFileName(res.data.original_filename)
            });

    }, [fileId])

    return (
        <>{fileName}</>
    );
}

export default React.memo(FileName);