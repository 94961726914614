import React, {FC, Fragment, useEffect, useState} from 'react'
import {Form, Input, Row, Col, Select, DatePicker} from 'antd';
import {EnumUtils, MathUtils} from "../../../../../../../utils";
import { DeliveryModeEnum, RequirementTypeEnum, YesNoEnum, BagTypeEnum, CustomerTypeEnum } from "../../../../../../../utils/enums";
import {CONSTANT_COMMON} from "../../../../../../../constants";
import {LocationTypeEnum} from "../../../../../../../utils/enums/LocationType.enum";
import {VehicleCapacityEnum} from "../../../../../../../utils/enums/VehicleCapacity.enum";
import {useProductContext} from "../../../../../../../hooks/context/useProductContext";
import {useWarehouseContext} from "../../../../../../../hooks/context/useWarehouseContext";
import { rules } from './SalesOrderItemCreateTradeBag.validate';
import {SalesOrderCalculationUtils} from "../../../Utils/SalesOrderCalculationUtils";

interface IProps {
    scheduleFormRef: any,
    customerInfo: any,
    [key: string]: any,
}

const SalesOrderItemCreateTradeBagForm: FC<IProps> = props => {
    const { Option } = Select;
    const { TextArea } = Input;

    const { scheduleFormRef, customerInfo, itemData, salesOrderData, salesOrderItemData, salesOrderBookingData, soCreationTime, retailerList, handleCustomerName } = props;

    const { productContext, activeProductList} = useProductContext();
    const { warehouseContext, activeWarehouseList} = useWarehouseContext();
    const [selectedCustomerType, setSelectedCustomerType] = useState('');
    const [selectedRetailer, setSelectedRetailer] = useState<any>({});

    useEffect(() => {
        if (itemData.customer_type) {
            setSelectedCustomerType(itemData.customer_type);
        }
    }, [itemData?.customer_type])

    const handleCustomerTypeSelect = (value: any, option: any) => {
        if (value) {
            setSelectedCustomerType(value);

            scheduleFormRef.setFieldsValue({
                contact_number: null,
                location_type: null,
                delivery_location_contact_number: null,
                delivery_address: null,
            });

            if (value === 'DEALER') {
                scheduleFormRef.setFieldsValue({
                    customer_name: customerInfo?.name,
                    contact_number: customerInfo?.contact_number,
                })
                handleCustomerName(customerInfo?.org_name);
            }
            if (value === 'RETAILER') {
                handleRetailerSelect(itemData.retailer_id, null);
            }
        }
    }

    const handleRetailerSelect = (value: any, option: any) => {
        scheduleFormRef.setFieldsValue({
            contact_number: null,
            location_type: null,
            delivery_location_contact_number: null,
            delivery_address: null,
        });

        if (value) {
            const retailerInfo = retailerList.find((item) => item.id == value);
            setSelectedRetailer(retailerInfo)
            scheduleFormRef.setFieldsValue({
                contact_number: retailerInfo?.contact_number,
            })
            handleCustomerName(retailerInfo?.org_name);
        }
    }

    const handleLocationTypeSelect = (value: any, option: any) => {
        if (value) {
            if (selectedCustomerType === 'DEALER') {
                scheduleFormRef.setFieldsValue({
                    delivery_location_contact_number: customerInfo?.contact_number,
                    delivery_address: customerInfo?.address,
                })
            }
            if (selectedCustomerType === 'RETAILER') {
                scheduleFormRef.setFieldsValue({
                    delivery_location_contact_number: selectedRetailer?.contact_number,
                    delivery_address: selectedRetailer?.address,
                })
            }
            if (value === 'SITE') {
                scheduleFormRef.setFieldsValue({
                    delivery_address: null,
                })
            }
        }
    }

    const handleScheduleQtyChange = (value: any) => {
        const total = Number(value);
        const freeBagOffer = Number(customerInfo.free_bag_offer);
        if (freeBagOffer == 0) {
            scheduleFormRef.setFieldsValue({
                schedule_actual_qty: total,
            })
        }
        else {
            const freeBagOfferValue = customerInfo.free_bag_offer_value || 0;
            const soActualQty = total + MathUtils.getPercent(total, freeBagOfferValue);

            scheduleFormRef.setFieldsValue({
                schedule_actual_qty: soActualQty,
            })
        }
    }

    const validateScheduleBilledQty = (_: any, qty: any) => {
        const inputQty = Number(qty) || 0;
        const previousInputQty = Number(itemData?.schedule_billed_qty) || 0;
        const scheduleQty = SalesOrderCalculationUtils.calculateTotalScheduleBilledQty(salesOrderItemData);
        const previousScheduleQty = Number(salesOrderData.so_billed_qty) || 0;
        const totalScheduleQty = (scheduleQty + inputQty) - previousInputQty;

        let remainingSoQty = Number(salesOrderData.so_booking_remaining_qty) || 0;
        if (salesOrderData.base_sales_order_no || salesOrderData.parent_sales_order_id) {
            remainingSoQty = Number(remainingSoQty) + Number(previousScheduleQty);
        }
        else {
            remainingSoQty = Number(salesOrderData.so_total_qty) || 0;
        }

        const maximumSoQty = remainingSoQty - (totalScheduleQty - inputQty);

        if (inputQty == 0) {
            return Promise.reject('This field is required.');
        }
        else if (salesOrderData.base_sales_order_no || salesOrderData.parent_sales_order_id || salesOrderData.delivery_type === 'PARTIAL_DELIVERY') {
            if ( maximumSoQty >= inputQty ) {
                return Promise.resolve();
            }
            else {
                return Promise.reject('Maximum Schedule Qty (Billed): ' + maximumSoQty);
            }
        }
        else {
            return Promise.resolve();
        }
    };

    function disabledDate(current) {
        return current && current <= soCreationTime;
    }

    return (
        <Fragment>
            <div className="customer_info">
                <h2>Customer Information</h2>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item
                            label="Customer Type"
                            name="customer_type"
                            rules={rules.customer_type}
                        >
                            <Select
                                placeholder="-- Select --"
                                onChange={handleCustomerTypeSelect}
                            >
                                {EnumUtils.getEnumKeys(CustomerTypeEnum).map((key, index) => (
                                    <Option key={`customer-type-${index}`} value={key}>{CustomerTypeEnum[key]}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        { (!selectedCustomerType || selectedCustomerType == 'DEALER') && (
                            <Form.Item
                                label="Customer Name"
                                name="customer_name"
                            >
                                <Input
                                    disabled={true}
                                />
                            </Form.Item>
                        )}

                        { selectedCustomerType == 'RETAILER' && (
                            <Form.Item
                                label="Customer Name"
                                name="retailer_id"
                            >
                                <Select
                                    showSearch
                                    placeholder="-- Select --"
                                    onChange={handleRetailerSelect}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {retailerList && retailerList.map((item, index) => (
                                        <Option key={`retailer-${index}`} value={item.id}>{item.org_name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Contact"
                            name="contact_number"
                            rules={rules.contact_number}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Location Type"
                            name="location_type"
                            rules={rules.location_type}
                        >
                            <Select
                                placeholder="-- Select --"
                                onChange={handleLocationTypeSelect}
                            >
                                {EnumUtils.getEnumKeys(LocationTypeEnum).map((key, index) => (
                                    <Option key={`location-type-${index}`} value={key}>{LocationTypeEnum[key]}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Delivery Location Contact"
                            name="delivery_location_contact_number"
                            rules={rules.delivery_location_contact_number}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Address"
                            name="delivery_address"
                            rules={rules.delivery_address}
                        >
                            <TextArea
                                autoSize={{ minRows: 1, maxRows: 5 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <div className="product_info">
                <h2>Product Information</h2>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item
                            label="Product Type"
                            name="product_type"
                            rules={rules.product_type}
                        >
                            <Select
                                placeholder="-- Select --"
                                loading={productContext.isLoading}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {activeProductList && activeProductList.map((item, index) => (
                                    <Option key={`product-type-${index}`} value={item.name}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Requirement Type"
                            name="requirement_type"
                            rules={rules.requirement_type}
                        >
                            <Select placeholder="-- Select --">
                                {EnumUtils.getEnumKeys(RequirementTypeEnum).map((key, index) => (
                                    <Option key={`requirement-type-${index}`} value={key}>{RequirementTypeEnum[key]}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Bag Type"
                            name="bag_type"
                            rules={rules.bag_type}
                        >
                            <Select placeholder="-- Select --">
                                {EnumUtils.getEnumKeys(BagTypeEnum).map((key, index) => (
                                    <Option key={`bag-type-${index}`} value={key}>{BagTypeEnum[key]}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Free Bag Offer"
                            name="free_bag_offer"
                            rules={rules.free_bag_offer}
                        >
                            <Select disabled={true} placeholder="-- Select --">
                                {EnumUtils.getEnumKeys(YesNoEnum).map((key, index) => (
                                    <Option key={`free-bag-offer-${index}`} value={YesNoEnum[key]}>{key}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Schedule Qty (Billed)"
                            name="schedule_billed_qty"
                            rules={[{ required: true, validator: validateScheduleBilledQty}]}
                        >
                            <Input
                                onChange={(e) =>handleScheduleQtyChange(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Schedule Qty (Actual)"
                            name="schedule_actual_qty"
                            rules={rules.schedule_actual_qty}
                        >
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <div className="delivery_info">
                <h2>Delivery Information</h2>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Form.Item
                            label="Delivery Mode"
                            name="delivery_mode"
                            rules={rules.delivery_mode}
                        >
                            <Select placeholder="-- Select --">
                                {EnumUtils.getEnumKeys(DeliveryModeEnum).filter(item => customerInfo?.delivery_mode?.includes(item)).map((key, index) => (
                                    <Option key={`delivery-mode-${index}`} value={key}>{DeliveryModeEnum[key]}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Warehouse"
                            name="warehouse"
                            rules={rules.warehouse}
                        >
                            <Select
                                placeholder="-- Select --"
                                loading={warehouseContext.isLoading}
                            >
                                {activeWarehouseList && activeWarehouseList.filter(item => customerInfo?.warehouse?.includes(item.name)).map((item, index) => (
                                    <Option key={`warehouse-${index}`} value={item.name}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Vehicle Capacity"
                            name="vehicle_capacity"
                            rules={rules.vehicle_capacity}
                        >
                            <Select placeholder="-- Select --">
                                {EnumUtils.getEnumValues(VehicleCapacityEnum).map((key, index) => (
                                    <Option key={`vehicle-capacity-${index}`} value={key}>{key}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Customer Expected Delivery Time"
                            name="delivery_date_time"
                            rules={rules.delivery_date_time}
                        >
                            <DatePicker
                                showTime
                                disabledDate={disabledDate}
                                format={CONSTANT_COMMON.DATE_PICKER_DATE_TIME_FORMAT}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
}
export default React.memo(SalesOrderItemCreateTradeBagForm);