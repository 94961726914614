import React, {FC, useState} from 'react'
import SalesOrderMultiScheduleContentList from "../Content/SalesOrderMultiScheduleContent.list";

const SalesOrderViewTabMultiSchedule: FC<any> = props => {
    const {itemData} = props;
    const [reloadTab, setReloadTab] = useState(Date.now());

    const reloadCollectionTab = (res?: any) => {
        setReloadTab(Date.now());
    };

    return (
        <div className="dealer-collection-tabs-content">
            <div className="tab-record-list-container">
                <div className="record-list-header">

                </div>
                <div className="record-list-rows">
                    { itemData.sales_order_booking_no && (
                        <SalesOrderMultiScheduleContentList queryParams={{ sales_order_booking_no: itemData.sales_order_booking_no }} reloadTab={reloadTab} />
                    )}

                </div>
            </div>
        </div>
    );
}
export default React.memo(SalesOrderViewTabMultiSchedule);