import React, {useContext} from 'react';
import LazyLoader from "./../LazyLoader";
import './loader.scss';
import {AuthContext} from "../../../context/auth/auth.context";

const Loader = () => {
    const { loading } = useContext(AuthContext)
    return (
        <>
            {loading && ( <LazyLoader /> )}
        </>
    );
};

export default Loader;