import React, {FC, Fragment, useEffect, useState} from 'react'
import {Form, Input, Row, Col, Upload, Button} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {CONSTANT_CONFIG} from "../../../../../constants";
import {FileApi} from "../../../../../api";
import {WorkflowStepEnum} from "../../../../../utils/enums";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const AddOrEditForm: FC<any> = props => {
    const { TextArea } = Input;
    const { formRef, rules, confirmText, initialValues, itemData, workflowActionData, workflowName, fileList, setFileList, handleChange, handleSubmit, handleSubmitFailed } = props;
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);

    useEffect(() => {
        if (itemData.files) {
            FileApi.getFileById(itemData.files)
                .then(res => {
                    let fileInfo = {
                        uid: `${res.data.file_id}`,
                        file_id: res.data.file_id,
                        name: res.data.original_filename,
                        url: CONSTANT_CONFIG.MEDIA_SOURCE + res.data.file_id,
                    }
                    setFileList([fileInfo])
                    setUploadLoading(false)
                }).catch(err => {
                setUploadLoading(false)
            });

        }
    }, [itemData.files])

    const handleBeforeUploadFile = (file: File, FileList: File[]) => {
        return true
    }

    const handleChangeFile = (info: any) => {
        if (info.file.status === 'uploading') {
            setUploadLoading(true)
            return;
        }
        if (info.file.status === 'error') {
            setUploadLoading(false)
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoading(false)
            return;
        }
    };

    const handleRemoveUploadFile = (file: any) => {
        setFileList((prevFileList) => {
            return prevFileList.filter(item => item !== file)
        })
    };

    const handleFileUpload = (name: string, file: any) => {
        setUploadLoading(true)
        FileApi.upload(file)
            .then(res => {
                setFileList((prevFileList) => {
                    return [
                        ...prevFileList,
                        {
                            uid: `${res.data.file_id}`,
                            file_id: res.data.file_id,
                            name: res.data.original_filename,
                            url: CONSTANT_CONFIG.MEDIA_SOURCE + res.data.file_id,
                        }
                    ]
                })
                setUploadLoading(false)
            })
            .catch(err => {
                setUploadLoading(false)
            });
    };

    return (
        <Fragment>
            <div className="form-page-content form-page-content-workflow-transition">
                <Form
                    {...formItemLayout}
                    form={formRef}
                    name="workflowTransitionForm"
                    scrollToFirstError={true}
                    initialValues={initialValues}
                    onValuesChange={handleChange}
                    onFinish={handleSubmit}
                    onFinishFailed={handleSubmitFailed}
                >
                    <Row>
                        <Col span={24}>
                            <div className="form-item-row">
                                <h3>{ confirmText }</h3>
                            </div>

                            {(workflowActionData.action == 'FORWARD' || workflowActionData.action == 'RECOMMEND') && (
                                <div className="form-item-row">
                                    <span className="label">Forward To: </span>
                                    <span className="content">
                                        { workflowName == 'CHALLAN' && workflowActionData.action == 'FORWARD' ? WorkflowStepEnum[workflowActionData.next_workflow_step_name] || workflowActionData.next_workflow_step_name : 'Next Step' }
                                    </span>
                                </div>
                            )}
                            {( workflowName == 'CHALLAN' && workflowActionData.action == 'GATE_PASS') && (
                                <div className="form-item-row">
                                    <span className="label">Forward To: </span>
                                    <span className="content">
                                        Customer
                                    </span>
                                </div>
                            )}
                            { workflowActionData.action == 'APPROVE' && (
                                <div className="form-item-row">
                                    <span className="label">Forward To: </span>
                                    <span className="content">
                                        { workflowName == 'SALES_ORDER' ? 'Distribution' : workflowName == 'CHALLAN' ? 'Gateman' : 'Complete' }
                                    </span>
                                </div>
                            )}
                            {workflowActionData.action == 'REVIEW' && (
                                <div className="form-item-row">
                                    <span className="label">Forward To: </span>
                                    <span className="content">
                                        {WorkflowStepEnum[workflowActionData.next_workflow_step_name] || workflowActionData.next_workflow_step_name}
                                    </span>
                                </div>
                            )}
                            {workflowActionData.action == 'SEND_BACK' && (
                                <div className="form-item-row">
                                    <span className="label">Send Back To: </span>
                                    <span className="content">
                                        { workflowName == 'CHALLAN' ? WorkflowStepEnum[workflowActionData.next_workflow_step_name] || workflowActionData.next_workflow_step_name : 'Previous Step'}
                                    </span>
                                </div>
                            )}

                            <Form.Item
                                label="Comment"
                                name="comment"
                            >
                                <TextArea
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Attach Files"
                                name="files"
                            >
                                <Upload
                                    name="attach_file"
                                    className="file-uploader"
                                    multiple={true}
                                    fileList={fileList}
                                    onChange={handleChangeFile}
                                    beforeUpload={handleBeforeUploadFile}
                                    onRemove={handleRemoveUploadFile}
                                    customRequest={({file}) => handleFileUpload('file', file)}
                                >
                                    <Button
                                        className="btn-upload"
                                        icon={<UploadOutlined />}
                                        loading={uploadLoading}
                                    >
                                        Click to Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Fragment>
    );
}
export default React.memo(AddOrEditForm);