export enum ClientSegmentEnum {
    REAL_ESTATE = 'Real Estate',
    CONTRACTOR_AND_GOVT = 'Contractor/Govt.',
    INDUSTRY = 'Industry',
    TOLLING = 'Tolling',
    EXPORT = 'Export',
    Bulk_AND_RMC = 'Bulk/RMC',
    MES = 'MES',
    HEADQUARTER = 'Headquarter',
    INDIVIDUAL_USER = 'Individual User',
}