import React, { FC, Fragment} from 'react'
import {Button, Drawer, Spin} from 'antd';
import CollectionView from "./CollectionView.view";
import CustomScrollbar from "../../../../../components/Scrollbar/CustomScrollbar";

const CollectionViewDrawer: FC<any> = props => {
    const {isShowView, loading, modalTitle, itemData, viewTabIndex, setViewTabIndex, handleCallbackFunc} = props;
    return (
        <Fragment>
            <Drawer
                width={'80%'}
                zIndex={500}
                className="view-page-drawer view-page-drawer-collection"
                title={<b>{modalTitle}&nbsp;&nbsp;{loading && <Spin size="small" />}</b>}
                mask={false}
                visible={isShowView}
                onClose={(event) => handleCallbackFunc(null, 'hideView')}
                footer={[
                    <Button key='close' onClick={(event) => handleCallbackFunc(null, 'hideView')}>Close</Button>,
                ]}
            >
                <CustomScrollbar className="view-page-drawer-scrollbar">
                    <CollectionView itemData={itemData} loading={loading} viewTabIndex={viewTabIndex} setViewTabIndex={setViewTabIndex} handleCallbackFunc={handleCallbackFunc} />
                </CustomScrollbar>
            </Drawer>
        </Fragment>
    );
}
export default React.memo(CollectionViewDrawer);