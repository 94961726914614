import { useState, useEffect, useContext } from 'react'
import {DataContext} from "../../context/data/data.context";

export const useBankListContext = () => {
    const dataContext = useContext(DataContext)
    const [bankListContext, setBankListContext] = useState<any>([]);
    const [bankList, setBankList] = useState<any>([]);
    const [activeBankList, setActiveBankList] = useState<any>([]);

    useEffect(() => {
        setBankListContext(dataContext.bankListContext);

        if (!dataContext.bankListContext.isLoaded) {
            dataContext.loadBankList()
        }
        else {
            const items = dataContext.bankListContext.items
            loadBankList(items);
            loadActiveBankList(items);
        }
    }, [dataContext.bankListContext.isLoaded]);

    const loadBankList = (list: any) => {
        setBankList(list);
    }

    const loadActiveBankList = (list: any) => {
        const activeList = list.filter(item => item.status === 1)
        setActiveBankList(activeList);
    }

    const getBankListById = (id) => {
        if(!bankList) {
            return;
        }
        return bankList.find(item => item.key === id)
    }

    const setBankFieldValue = (formRef, key, value) => {
        if (activeBankList?.find(item => item.key ===  value )) {
            formRef.setFieldsValue({[key]: value})
        }
        else {
            formRef.setFieldsValue({[key]: null})
        }
    }

    return { bankListContext, bankList, activeBankList, setBankFieldValue, getBankListById }
}