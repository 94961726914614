import React, {FC, useEffect, useRef, useState} from 'react'
import {Table} from "antd";
import {Message, DateTimeUtils, NumberUtils, StringUtils} from "../../../../../utils";
import {useForm} from "../../../../../hooks/useForm";
import {DeliveryOrderApi} from "../../../../../api";
import '../../../styles/Order.module.listing.scss';
import '../List/DeliveryOrderList.style.scss';

const initialState  = {
    search: '',
    entity: {},
    entityId: null,
    listData: [],
    filters: {
        customer_id: '',
        employee_id: '',
        sales_order_id: '',
    },
    pagination: {
        currentPage: 1,
        pageSize: 20,
    },
    totalCount: 0,
    selectedRowKeys: [],
    sort: 'id desc',
    view: null,
    loading: false,
    isShowView: false,
    isShowForm: false,
    fields: {},
    bulkAction: {
        action: '',
        field: '',
        value: '',
        ids: [] as any,
    },
}

const DeliveryOrderContentList: FC<any> = props => {
    const { queryParams, reloadTab } = props;
    const payload = useRef<any>({});

    const queryState  = {
        search: queryParams?.q || initialState.search,
        filters: {
            customer_id: queryParams?.customer_id || initialState.filters.customer_id,
            employee_id: queryParams?.employee_id || initialState.filters.employee_id,
            sales_order_id: queryParams?.sales_order_id || initialState.filters.sales_order_id,
        },
        pagination: {
            currentPage: queryParams?.page || initialState.pagination.currentPage,
            pageSize: queryParams?.pageSize || initialState.pagination.pageSize,
        },
        sort: queryParams?.sort || initialState.sort,
        view: queryParams?.view || initialState.view,
        isShowView: queryParams?.isShowView || initialState.isShowView,
        isShowForm: queryParams?.isShowForm || initialState.isShowForm,
        entity: queryParams?.entity || initialState.entity,
        entityId: queryParams?.entityId || initialState.entityId,
    }

    const { formRef, initialValues, resetForm } = useForm({
        search: queryState.search,
        customer_id:queryState.filters.customer_id,
        employee_id:queryState.filters.employee_id,
        sales_order_id:queryState.filters.sales_order_id,
    });
    const [listData, setListData] = useState<any[]>(initialState.listData);
    const [search, setSearch] = useState<string>(queryState.search);
    const [filters, setFilters] = useState<any>(queryState.filters);
    const [sort, setSort] = useState(queryState.sort);
    const [pagination, setPagination] = React.useState<any>(queryState.pagination);
    const [totalCount, setTotalCount] = useState(initialState.totalCount);
    const [loading, setLoading] = useState(initialState.loading);

    useEffect(() => {
        initData();
    }, [search, filters, sort, pagination, reloadTab])

    const initData = async () => {
        await handlePayload();
        await loadData();
    }

    const loadData = (): void => {
        setLoading(true);
        DeliveryOrderApi.list(payload.current)
            .then(res => {
                prepareListData(res.data.results)
                setTotalCount(res.data.meta.totalCount);
                setLoading(false);
            })
            .catch(err => {
                console.error("error", err);
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
            });
    }

    const prepareListData = (dataArray) => {
        setListData(dataArray);
    }

    const processFilters = (): string => {
        let filterString = "1=1";

        if(filters.customer_id) {
            filterString += " AND customer_id='" + filters.customer_id + "'";
        }
        if(filters.employee_id) {
            filterString += " AND employee_id='" + filters.employee_id + "'";
        }
        if(filters.sales_order_id) {
            filterString += " AND sales_order_id='" + filters.sales_order_id + "'";
        }

        return filterString
    }

    const processOrderBy = (): string => {
        let orderByString
        if (sort === 'date-desc') {
            orderByString = 'id desc';
        }
        else {
            orderByString = sort;
        }

        return orderByString
    }

    const handlePayload= (): void => {
        payload.current = {
            '$select': '',
            '$search': search,
            '$filter': processFilters(),
            '$expand': '',
            '$orderby': processOrderBy(),
            '$top': pagination.pageSize,
            '$skip': pagination.pageSize * (pagination.currentPage - 1),
        }
    }

    const tableSummary = (pageData) => {
        let totalQty = 0;
        pageData.forEach(({ delivery_billed_qty }) => {
            totalQty += NumberUtils.toNumber(delivery_billed_qty);
        });

        return (
            <>
                <tr>
                    <td colSpan={2}>Total</td>
                    <td colSpan={2}>{NumberUtils.formatNumber(totalQty)}</td>
                </tr>
            </>
        );
    }

    const columns = [
        {
            dataIndex: "delivery_order_no",
            key: 'delivery_order_no',
            title: "DO No",
        },
        {
            dataIndex: "delivery_billed_qty",
            key: 'delivery_billed_qty',
            title: "DO Qty",
            sorter: true,
        },
        {
            dataIndex: "created_at",
            key: 'created_at',
            title: "Created Time",
            sorter: true,
            render: (value) => DateTimeUtils.formatDateTimeA(value),
        },
        {
            dataIndex: "status",
            key: 'status',
            title: "Status",
            sorter: true,
            render: (text: string, record: any, index:number) => StringUtils.getWorkflowApprovalStatus(record),
        },
    ];

    return (
        <div className="listing-content listing-content-adjustment">
            <Table
                className="table-layout"
                rowKey={record => record.id.toString()}
                rowClassName={(record, index) => (index % 2 === 0 ? 'odd' : 'even')}
                dataSource={listData}
                columns={columns}
                pagination={false}
                loading={loading}
                bordered={false}
                summary={tableSummary}
            />
        </div>
    );
}

export default DeliveryOrderContentList;
