import React, {FC, useContext} from 'react'
import { Link } from 'react-router-dom';
import {Dropdown, Avatar, Layout} from 'antd';
import { UserOutlined, PlusCircleOutlined } from '@ant-design/icons';
import images from '../../../../assets/images';
import {AuthContext} from "../../../../context/auth/auth.context";
import ProfileMenu from "../../../../components/Menu/ProfileMenu/ProfileMenu";
import QuickAddLink from "../../../../components/Menu/QuickAddLink/QuickAddLink";
import Notification from "../../../../modules/Common/components/Notification/Notification.controller";
import {CONSTANT_CONFIG} from "../../../../constants";
import HeaderTab from "../HeaderTab/HeaderTab";
import {usePermissionContext} from "../../../../hooks/context/usePermissionContext";
import {useProfileContext} from "../../../../hooks/context/useProfileContext";
import './Header.style.scss';

const Header: FC<any> = props => {
    const { isPermissionLoaded, hasPermission } = usePermissionContext();
    const { profileImage, profileCode, profileDesignation, user } = useContext(AuthContext);
    const { profileInfo } = useProfileContext();
    return (
        <Layout.Header id="header" className="app-header">
            <div className="company-logo">
                <Link to={'#'}><img src={images.layout.companyLogo} alt="Company Logo" /></Link>
            </div>
            <HeaderTab />
            <div className="header-quick-links">
                <div className="quick-link-item quick-link-item-quick-actions">
                    <Dropdown overlayClassName="quick-link-drop-down" overlay={QuickAddLink()} trigger={['click']} placement="bottomRight">
                        <PlusCircleOutlined />
                    </Dropdown>
                </div>
                <div className="quick-link-item quick-link-item-notification">
                    <Notification />
                </div>
                <div className="quick-link-item quick-link-item-avatar-menu">
                    <Dropdown overlayClassName="avatar-menu-drop-down" overlay={ProfileMenu(user, profileCode, profileDesignation, isPermissionLoaded, hasPermission)} trigger={['click']} placement="bottomRight">
                        <span className="action account">
                            { profileImage ? (<Avatar shape="circle" src={CONSTANT_CONFIG.CDN_URL + 'image/' + profileImage} alt="Profile Pic" />) : (<Avatar  size={24}  icon={<UserOutlined />} />)}
                            <span className="name">{props.account ? props.account.name : null}</span>
                        </span>
                    </Dropdown>
                </div>
            </div>
        </Layout.Header>
    );
}

export default React.memo(Header);
