import React, {FC, useContext, useEffect, useState} from 'react'
import NotificationDropdown from './Notification.dropdown';
import {AuthContext} from "../../../../context/auth/auth.context";
import {NotificationApi} from "../../../../api";
import {DateTimeUtils} from "../../../../utils";
import {CountContext} from "../../../../context/count/count.context";
import '../../styles/Common.module.scss';
import './Notification.style.scss';

const initialState  = {
    search: '',
    visible: false,
    countBadge: 0,
    listData: [],
    filters: {
        type: '',
    },
    loading: false,
    fields: {},
}

const Notification: FC<any> = props => {
    const { userId } = useContext(AuthContext)
    const { countNotificationBadge } = useContext(CountContext);

    const [search, setSearch] = useState<string>(initialState.search);
    const [filters, setFilters] = useState<any>(initialState.filters);
    const [visible, setVisible] = useState(initialState.visible);
    const [countBadge, setCountBadge] = useState(initialState.countBadge);
    const [listData, setListData] = useState<any[]>(initialState.listData);
    const [loading, setLoading] = useState(initialState.loading);
    const [reloadNotification, setReloadNotification] = useState<number>(Date.now());

    useEffect(() => {
        if (countNotificationBadge) {
            setCountBadge(countNotificationBadge);
        }
    }, [countNotificationBadge])

    useEffect(() => {
        if (userId) {
            badgeNotificationCount();
            loadData();
        }
    }, [search, filters, userId, reloadNotification])

    const badgeNotificationCount = (): void => {
        NotificationApi.countBadge(userId)
            .then(res => {
                setCountBadge(res.data);
            });
    }

    const loadData = (): void => {
        setLoading(true);
        const payload = {
            '$search': search,
            '$filter': processFilters(),
            '$orderby': 'id desc',
            '$top': 10,
        }
        NotificationApi.list(payload)
            .then(res => {
                setListData(res.data.results);
                setLoading(false);
            });
    }

    const processFilters = (): string => {
        let filterString = "receiver_id eq " + userId;

        if(filters.type == 'Unread') {
            filterString += " AND read_at IS NULL";
        }

        return filterString
    }

    const handleReloadNotification = () => {
        setReloadNotification(Date.now());
    };

    const handleMarkAllAsRead = (e: any) => {
        setLoading(true);
        NotificationApi.markAllAsRead()
            .then(res => {
                setCountBadge(0);
                handleReloadNotification();
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            });
    };

    const handleMarkAsReadItem = (notificationId: any) => {
        setLoading(true);
        const payload = {
            'read_at': DateTimeUtils.currentDateTime('YYYY-MM-DD hh:mm:ss')
        }
        NotificationApi.updatePartial(notificationId, payload)
            .then(res => {
                setCountBadge(prevCountBadge =>  prevCountBadge - 1);
                handleReloadNotification();
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            });
    };

    const handleDeleteItem = (notificationId: any) => {
        setLoading(true);
        NotificationApi.delete(notificationId)
            .then(res => {
                handleReloadNotification();
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            });
    };

    const handleVisibleChange = () => {
        setVisible(!visible);
    };

    const handleScroll = e => {
        /*const element = e.srcElement;
        if (element.scrollTop === element.scrollHeight - 474) {
            if (!initLoading && !loading && hasMore)
                dispatch({
                    type: 'notifications/more'
                });
        }*/
    }

    const handleOnChanged = (fieldName: string, value: any, text?: any) => {
        if(fieldName === 'filter_type') {
            setFilters({
                ...filters,
                type: value,
            });
        }
        else if(fieldName === 'search') {
            setSearch(value)
        }
    }

    return (
        <NotificationDropdown
            filters={filters}
            visible={visible}
            listData={listData}
            loading={loading}
            countBadge={countBadge}
            handleMarkAllAsRead={handleMarkAllAsRead}
            handleMarkAsReadItem={handleMarkAsReadItem}
            handleDeleteItem={handleDeleteItem}
            handleVisibleChange ={handleVisibleChange}
            handleScroll ={handleScroll}
            handleOnChanged={handleOnChanged}
        />
    );
}

export default React.memo(Notification);
