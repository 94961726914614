import React, { ReactNode } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import SettingSidebar from "../../components/SettingSidebar/SettingSidebar";
import {Layout} from "antd";
import '../../../../assets/scss/style.scss';

interface Props {
    children: ReactNode;
    title?: string;
}

export const SettingLayout: React.FC<Props> = (props: Props) => {
    return (
        <Layout className="app-wrapper">
            <Header />
            <Layout className="setting-layout">
                <LeftSidebar />
                <SettingSidebar />
                <Layout.Content className="content-wrapper">
                    <div className="main-content">
                        {props.children}
                    </div>
                </Layout.Content>
            </Layout>
            <Footer />
        </Layout>
    )
}