import React, {FC, useContext, useState} from 'react'
import { Button } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import {usePermissionContext} from "../../../../../hooks/context/usePermissionContext";
import {useWorkflowContext} from "../../../../../hooks/context/useWorkflowContext";
import {AuthContext} from "../../../../../context/auth/auth.context";

interface IProps {
    entityId: any,
    children?: any,
    component?: any,
    actionItem?: any,
    workflowName: string,
    workflowStatus?: string,
    workflowStepName: string,
    manageUrl?: boolean,
    handleCallbackFunc?: (event:any, action: string, recordId?:any, data?: any) => void,
    [key: string]: any,
}

const EditWorkflowAction: FC<IProps> = props => {
    const { entityId, children, component: Component, actionItem, workflowStatus, workflowName, workflowStepName, manageUrl = true, handleCallbackFunc, ...restProps } = props;
    const { userWorkflowSteps } = useWorkflowContext();
    const { isPermissionLoaded, hasPermission } = usePermissionContext();
    const { userGroup } = useContext(AuthContext);
    const [isShowForm, setIsShowForm] = useState(false);
    const [reloadForm, setReloadForm] = useState<number>(Date.now());

    const showFormModal = (): void => {
        if (manageUrl && handleCallbackFunc) {
                handleCallbackFunc(null, 'edit', entityId);
        }
        else {
            setIsShowForm(true);
            handleReloadForm();
        }
    }

    const hideFormModal = () => {
        setIsShowForm(false);
    }

    const handleReloadForm = () => {
        setReloadForm(Date.now());
    };

    const handleCallback = (event:any, action: string, recordId?:any, data?: any) => {
        if (!manageUrl && action == 'hideForm') {
            hideFormModal();
        }
        else if (handleCallbackFunc) {
            handleCallbackFunc(event, action, recordId, data);
        }
    }

    if (isPermissionLoaded && hasPermission(actionItem.permission) && workflowStepName !== 'COMPLETED' && workflowStepName !== 'RECEIVED' && userWorkflowSteps[workflowName] === workflowStepName) {
        return (
            <>
                {children ? (<span onClick={() => showFormModal()}>{children}</span>) : (<Button type="primary" className="btn btn-edit" onClick={() => showFormModal()}><PlusOutlined /> {actionItem.title}</Button>)}
                {!manageUrl && <Component entityId={entityId} reloadForm={reloadForm} isShowForm={isShowForm} handleCallbackFunc={handleCallback}  {...restProps} />}
            </>
        );
    }
    // Super Admin
    else if (isPermissionLoaded && hasPermission(actionItem.permission) && userGroup === 'GROUP_ADMINISTRATOR' && (workflowName === 'CHALLAN' || workflowName === 'NONTRADE_CHALLAN') && workflowStatus === 'CALLED_BACK') {
        return (
            <>
                {children ? (<span onClick={() => showFormModal()}>{children}</span>) : (<Button type="primary" className="btn btn-edit" onClick={() => showFormModal()}><PlusOutlined /> {actionItem.title}</Button>)}
                {!manageUrl && <Component entityId={entityId} reloadForm={reloadForm} isShowForm={isShowForm} handleCallbackFunc={handleCallback}  {...restProps} />}
            </>
        );
    }

    return <></>
}

export default EditWorkflowAction;