import React, { FC, Fragment } from 'react'
import {Modal, Spin, Button} from 'antd';
import AddOrEditForm from "./SalesOrderItemCreate.form";
import CustomScrollbar from "../../../../../../components/Scrollbar/CustomScrollbar";

interface IProps {
    scheduleFormRef: any,
    modalTitle: any,
    loading: any,
    isShowForm: any,
    handleCallbackFunc: any,
    [key: string]: any,
}

const SalesOrderItemCreateModal: FC<IProps> = props => {
    const { scheduleFormRef, modalTitle, btnText, loading, isShowForm, handleCallbackFunc, ...restProps } = props;
    const onSubmit = () => {
        scheduleFormRef.submit();
    }
    return (
        <Fragment>
            <Modal
                width={1024}
                className="form-page-modal form-page-modal-sales-order-item"
                title={<b>{modalTitle}&nbsp;&nbsp;{loading && <Spin size="small" />}</b>}
                maskClosable={false}
                centered
                visible={isShowForm}
                onCancel={(event) => handleCallbackFunc(null, 'hideForm')}
                footer={[
                    <Button key='cancel' onClick={(event) => handleCallbackFunc(null, 'hideForm')}>Cancel</Button>,
                    <Button key='submit' type='primary' loading={loading} onClick={onSubmit}>{ btnText }</Button>
                ]}
            >
                <CustomScrollbar autoHeight autoHeightMax={500} className="form-page-modal-scrollbar">
                    <AddOrEditForm
                        {...restProps}
                        scheduleFormRef={scheduleFormRef}
                        handleCallbackFunc={handleCallbackFunc}
                    />
                </CustomScrollbar>
            </Modal>
        </Fragment>
    );
}
export default React.memo(SalesOrderItemCreateModal);