import React, { FC, Fragment } from 'react'
import {Drawer, Spin, Button} from 'antd';
import { RightOutlined } from '@ant-design/icons';
import AddOrEditForm from "./SalesOrderCreate.form";
import CustomScrollbar from "../../../../../components/Scrollbar/CustomScrollbar";

interface IProps {
    formRef: any,
    modalTitle: any,
    loading: any,
    isShowForm: any,
    activeStep: any,
    handleCallbackFunc: any,
    [key: string]: any,
}

const SalesOrderCreateDrawer: FC<IProps> = props => {
    const { formRef, modalTitle, loading, isShowForm, activeStep, setFormActionName, salesOrderData, salesOrderItemData, handleSubmitNextForm, handleCallbackFunc, handleSalesOrderItemCallbackFunc, ...restProps } = props;
    const onSubmitSave = () => {
        setFormActionName('Save');
        formRef.submit();
    }
    const onSubmitNext = () => {
        setFormActionName('Next');
        formRef.submit();
    }
    return (
        <Fragment>
            <Drawer
                width={'75%'}
                zIndex={600}
                className="form-page-drawer form-page-drawer-sales-order"
                title={<b>{modalTitle}&nbsp;&nbsp;{loading && <Spin size="small" />}</b>}
                visible={isShowForm}
                onClose={(event) => handleCallbackFunc(null, 'hideForm')}
                footer={[
                    <>
                        <Button key='cancel' onClick={(event) => handleCallbackFunc(null, 'hideForm')}>Cancel</Button>,
                        {activeStep == 1 && (
                            <>
                                <Button key='submit' type='primary' loading={loading} onClick={onSubmitNext}>{'Next'} <RightOutlined /></Button>,
                            </>
                        )}
                        {activeStep == 2 && salesOrderData.delivery_type === 'FULL_DELIVERY' && (
                            <>
                                <Button key='submit' type='primary' loading={loading} disabled={!salesOrderItemData.length} onClick={onSubmitSave}>{'Save'}</Button>,
                            </>
                        )}
                        {activeStep == 2 && salesOrderData.delivery_type === 'PARTIAL_DELIVERY' && (
                            <>
                                <Button key='submit' type='primary' loading={loading} disabled={!salesOrderItemData.length} onClick={onSubmitSave}>{'Save'}</Button>,
                            </>
                        )}
                        {activeStep == 2 && salesOrderData.delivery_type === 'WITHOUT_DELIVERY' && (
                            <>
                                <Button key='submit' type='primary' loading={loading} onClick={onSubmitSave}>{'Save'}</Button>,
                            </>
                        )}
                    </>
                ]}
            >
                <CustomScrollbar className="form-page-drawer-scrollbar">
                    <AddOrEditForm
                        {...restProps}
                        formRef={formRef}
                        loading={loading}
                        activeStep={activeStep}
                        salesOrderData={salesOrderData}
                        salesOrderItemData={salesOrderItemData}
                        handleSubmitNextForm={handleSubmitNextForm}
                        handleCallbackFunc={handleCallbackFunc}
                        handleSalesOrderItemCallbackFunc={handleSalesOrderItemCallbackFunc}
                    />
                </CustomScrollbar>
            </Drawer>
        </Fragment>
    );
}
export default React.memo(SalesOrderCreateDrawer);