import React, {FC} from 'react';
import { Button } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import {usePermissionContext} from "../../hooks/context/usePermissionContext";

interface IProps {
    children?: any,
    entityId: any,
    actionItem?: any,
    manageUrl?: boolean,
    handleCallbackFunc?: (event:any, action: string, recordId?:any, data?: any) => void
}

const DeleteAction: FC<IProps> = props => {
    const { children, entityId, actionItem, manageUrl = true, handleCallbackFunc } = props;
    const { isPermissionLoaded, hasPermission } = usePermissionContext();

    const handleConfirmDelete = (entityId: any): void => {
        if (manageUrl && handleCallbackFunc) {
            handleCallbackFunc(null, 'delete', entityId);
        }
    }

    if (isPermissionLoaded && hasPermission(actionItem.permission)) {
        return (
            <>
                {children ? (<span onClick={() => handleConfirmDelete(entityId)}>{children}</span>) : (<Button type="primary" className="btn btn-delete" onClick={() => handleConfirmDelete(entityId)}><CloseOutlined /> {actionItem.title}</Button>)}
            </>
        );
    }

    return <></>
}

export default DeleteAction;