import React, {FC, useEffect, useState} from 'react'
import CollectionFormDrawer from './CollectionForm.drawer';
import { useForm } from '../../../../../hooks/useForm';
import {DateTimeUtils, Message, NumberUtils} from "../../../../../utils";
import { rules } from './CollectionForm.validate';
import { CollectionApi } from "../../../../../api";
import {CONSTANT_COMMON} from "../../../../../constants";
import '../../../styles/Order.module.form.scss';
import './CollectionForm.style.scss';

const initialState  = {
    modalTitle: 'Create Collection',
    itemData: {},
    fields: {
        customer_group: 'TRADE',
        employee_id: null,
        customer_id: null,
        delivery_order_id: null,
        amount: null,
        date: null,
        payment_mode: null,
        bank_name: null,
        branch_name: null,
        file:null,
        comment: null,
    },
    isNewRecord: true,
    loading: false,
}

const CollectionFormController: FC<any> = props => {
    const { entityId, reloadForm, isShowForm, customerInfo, handleCallbackFunc } = props
    const { formRef, initialValues, formValues, isSubmitting, setIsSubmitting, handleChange, handleSubmitFailed, resetForm } = useForm(initialState.fields);
    const [modalTitle, setModalTitle] = useState(initialState.modalTitle);
    const [itemData, setItemData] = useState(initialState.itemData);
    const [fileList, setFileList] = useState<any[]>([]);
    const [isNewRecord, setIsNewRecord] = useState(initialState.isNewRecord);
    const [loading, setLoading] = useState(initialState.loading);

    useEffect(() => {
        if (entityId && isShowForm) {
            setIsNewRecord(false);
            setModalTitle('Edit Collection');
            resetForm();
            loadData();
        }
        else {
            resetForm();
            handleChange(initialState.fields);
            setModalTitle(initialState.modalTitle);
            setIsNewRecord(initialState.isNewRecord);
        }
    }, [entityId, customerInfo, reloadForm])

    const loadData = (): void => {
        setLoading(true);
        CollectionApi.getById(entityId)
            .then(res => {
                setItemData(res.data);
                const initFormDta = {
                    customer_group: res.data.customer_group,
                    delivery_order_id: res.data.delivery_order_id,
                    amount: NumberUtils.toFixed(res.data.amount),
                    date: res.data.date ? DateTimeUtils.convertStringToDate(res.data.date) : null,
                    payment_mode: res.data.payment_mode,
                    branch_name: res.data.branch_name,
                    file: res.data.file,
                    comment: res.data.comment,
                }
                handleChange(initFormDta)
                formRef.setFieldsValue(initFormDta)
                setLoading(false);
            })
            .catch(err => {
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
            });
    }

    const handleSubmit = (values: any): void => {
        if (entityId) {
            handleUpdate(values);
        }
        else {
            handleCreate(values);
        }
    }

    const handleCreate = (values: any): void => {
        setLoading(true);
        setIsSubmitting(true);
        const payload = {
            employee_id: values.employee_id || customerInfo?.employee_id,
            customer_id: values.customer_id || customerInfo?.id,
            delivery_order_id: values.delivery_order_id,
            amount: values.amount,
            date: DateTimeUtils.formatDate(values.date, CONSTANT_COMMON.DATE_FORMAT_DATABASE),
            payment_mode: values.payment_mode,
            bank_name: values.bank_name,
            branch_name: values.branch_name,
            file: fileList.map(item => item.file_id).join(','),
            comment: values.comment,
        }
        CollectionApi.create(payload)
            .then(res => {
                Message.success('Collection create successfully.');
                handleCallbackFunc(null, 'hideForm');
                handleCallbackFunc(null, 'reloadListing');
                setLoading(false);
                setIsSubmitting(false);
            })
            .catch(err => {
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
                setIsSubmitting(false);
            });
    }

    const handleUpdate = (values: any): void => {
        setLoading(true);
        setIsSubmitting(true);
        const payload = {
            employee_id: values.employee_id || customerInfo?.employee_id,
            customer_id: values.customer_id || customerInfo?.id,
            delivery_order_id: values.delivery_order_id,
            amount: values.amount,
            date: DateTimeUtils.formatDate(values.date, CONSTANT_COMMON.DATE_FORMAT_DATABASE),
            payment_mode: values.payment_mode,
            bank_name: values.bank_name,
            branch_name: values.branch_name,
            file: fileList.map(item => item.file_id).join(','),
            comment: values.comment,
            /*step_action: 'DRAFT',*/
        }
        CollectionApi.update(entityId, payload)
            .then(res => {
                Message.success('Collection update successfully.');
                handleCallbackFunc(null, 'hideForm');
                handleCallbackFunc(null, 'updateListItem', entityId, res.data);
                handleCallbackFunc(null, 'reloadView');
                setLoading(false);
                setIsSubmitting(false);
            })
            .catch(err => {
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
                setIsSubmitting(false);
            });
    }

    return (
        <div className="form-page-container form-page-container-collection">
            <CollectionFormDrawer
                loading={loading}
                rules={rules}
                isNewRecord={isNewRecord}
                itemData={itemData}
                customerInfo={customerInfo}
                modalTitle={modalTitle}
                isSubmitting={isSubmitting}
                isShowForm={isShowForm}
                formRef={formRef}
                initialValues={initialValues}
                formValues={formValues}
                fileList={fileList}
                setFileList={setFileList}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleSubmitFailed={handleSubmitFailed}
                handleCallbackFunc={handleCallbackFunc}
            />
        </div>
    );
}

export default React.memo(CollectionFormController);
