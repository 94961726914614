export const rules = {
    customer_type: [
        { required: true, message: 'This field is required.' }
    ],
    contact_number: [
        { required: true, message: 'This field is required.' }
    ],
    location_type: [
        { required: true, message: 'This field is required.' }
    ],
    delivery_location_contact_number: [
        { required: true, message: 'This field is required.' }
    ],
    delivery_address: [
        { required: true, message: 'This field is required.' }
    ],
    product_type: [
        { required: true, message: 'This field is required.' }
    ],
    requirement_type: [
        { required: true, message: 'This field is required.' }
    ],
    bag_type: [
        { required: true, message: 'This field is required.' }
    ],
    free_bag_offer: [
        { required: true, message: 'This field is required.' }
    ],
    schedule_billed_qty: [
        { required: true, message: 'This field is required.' }
    ],
    schedule_actual_qty: [
        { required: true, message: 'This field is required.' }
    ],
    delivery_mode: [
        { required: true, message: 'This field is required.' }
    ],
    warehouse: [
        { required: true, message: 'This field is required.' }
    ],
    vehicle_capacity: [
        { required: true, message: 'This field is required.' }
    ],
    delivery_date_time: [
        { required: true, message: 'This field is required.' }
    ],
};