import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.SCM_SERVER_PREFIX}/import`
const endpoints = {
    importDealer: () => `${RESOURCE_ENDPOINT}/dealer`,
    importClient: () => `${RESOURCE_ENDPOINT}/client`,
    importRetailer: () => `${RESOURCE_ENDPOINT}/retailer`,
    importProject: () => `${RESOURCE_ENDPOINT}/project`,
    importEmployee: () => `${RESOURCE_ENDPOINT}/employee`,
    importPreviousDataEntry: () => `${RESOURCE_ENDPOINT}/previous-data-entry`,
    importSecurity: () => `${RESOURCE_ENDPOINT}/security`,
    importPrice: () => `${RESOURCE_ENDPOINT}/price`,
    importTarget: () => `${RESOURCE_ENDPOINT}/target`,
}

export default class ImportScmApi {
    public importDealer = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importDealer();
        return HttpService.post(url, payload, params, headers);
    }
    public importClient = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importClient();
        return HttpService.post(url, payload, params, headers);
    }
    public importRetailer = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importRetailer();
        return HttpService.post(url, payload, params, headers);
    }
    public importProject = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importProject();
        return HttpService.post(url, payload, params, headers);
    }
    public importEmployee = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importEmployee();
        return HttpService.post(url, payload, params, headers);
    }
    public importPreviousDataEntry = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importPreviousDataEntry();
        return HttpService.post(url, payload, params, headers);
    }
    public importSecurity = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importSecurity();
        return HttpService.post(url, payload, params, headers);
    }
    public importTarget = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importTarget();
        return HttpService.post(url, payload, params, headers);
    }
    public importPrice = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importPrice();
        return HttpService.post(url, payload, params, headers);
    }
}