import React, {FC, useEffect, useState} from 'react'
import {Row, Col, Avatar, Menu, Steps} from 'antd';
import {CONSTANT_CONFIG} from "../../../../../constants";
import CollectionViewTabInfo from "../../Collection/Tabs/CollectionViewTab.info";
import WorkflowTransitionAction from "../../../../Workflow/components/WorkflowTransition/Actions/WorkflowTransitionAction";
import WorkflowTransitionTimelineList from "../../../../Workflow/components/WorkflowTransition/Content/WorkflowTransitionTimeline.list";
import {WorkflowStepApi} from "../../../../../api";
import {WorkflowApprovalStatusEnum, WorkflowStepEnum} from "../../../../../utils/enums";
import {CollectionAction} from "../Actions/Collection.actions";
import EditWorkflowAction from "../../../../Workflow/components/WorkflowTransition/Actions/EditWorkflowAction";
import DeleteWorkflowAction from "../../../../Workflow/components/WorkflowTransition/Actions/DeleteWorkflowAction";

const CollectionView: FC<any> = props => {
    const { Step } = Steps;
    const {itemData, loading, viewTabIndex, setViewTabIndex, handleCallbackFunc} = props;
    const [workflowName, setWorkflowName] = useState<any>(null);
    const [workflowStepData, setWorkflowStepData] = useState<any>([]);
    const [workflowCurrentStep, setWorkflowCurrentStep] = useState(0);

    useEffect(() => {
        if (workflowName) {
            loadData();
        }
        if (itemData.id) {
            loadWorkflowName();
        }
    }, [itemData.step_name, workflowName])

    const loadData = (): void => {
        const payload = {
            '$filter': "workflow_name='" + workflowName + "' AND status='1'",
            '$orderby': "sort_order asc",
        }
        WorkflowStepApi.list(payload)
            .then(res => {
                setWorkflowStepData(res.data.results);
                const currentStep = res.data.results.findIndex(item => item.name == itemData.step_name);
                setWorkflowCurrentStep(currentStep);
            });
    }

    const loadWorkflowName = (): void => {
        if (itemData.customer_group === 'TRADE') {
            setWorkflowName('COLLECTION');
        }
        else if (itemData.customer_group === 'NONTRADE') {
            setWorkflowName('NONTRADE_COLLECTION');
        }
    }

    const handleTabSelect = (tabIndex: any) => {
        setViewTabIndex(tabIndex)
    }

    const ViewTabList = [
        {
            tabIndex: 1,
            title: 'Info',
        },
    ];
    
    return (
        <div className="view-page-content view-page-content-collection">
            <Row>
                <Col span={19}>
                    <div className="view-page-content-left">
                        <div className="record-detail-info">
                            <div className="actions">
                                {!loading && (
                                    <>
                                        <WorkflowTransitionAction
                                            workflowRecordId={itemData.id}
                                            workflowActionName={"SEND_BACK"}
                                            workflowStepName={itemData.step_name}
                                            workflowName={workflowName}
                                            workflowStepAndPermissionList={{
                                                CONSENTER: 'scm:collection:consenter:sendBackAction',
                                                NONTRADE_CONSENTER: 'scm:collection:nonTradeConsenter:sendBackAction',
                                                MANAGER_F_A: 'scm:collection:manager_f_a:sendBackAction',
                                            }}
                                            titleText="Send Back"
                                            btnText="Send Back"
                                            submitBtnText="Yes, Send Back"
                                            confirmText="Are you sure you want to send back this collection to the previous step ?"
                                            handleCallbackFunc={handleCallbackFunc}
                                        />
                                        <WorkflowTransitionAction
                                            workflowRecordId={itemData.id}
                                            workflowActionName={"FORWARD"}
                                            workflowStepName={itemData.step_name}
                                            workflowName={workflowName}
                                            workflowStepAndPermissionList={{
                                                TSE: 'scm:collection:tse:forwardAction',
                                                NONTRADE_TSE: 'scm:collection:nonTradeTse:forwardAction',
                                                CONSENTER: 'scm:collection:consenter:forwardAction',
                                                NONTRADE_CONSENTER: 'scm:collection:nonTradeConsenter:forwardAction',
                                            }}
                                            titleText="Forward"
                                            btnText="Forward"
                                            submitBtnText="Yes, Forward"
                                            confirmText="Are you sure you want to forward this collection next step ?"
                                            handleCallbackFunc={handleCallbackFunc}
                                        />
                                        <WorkflowTransitionAction
                                            workflowRecordId={itemData.id}
                                            workflowActionName={"VERIFY"}
                                            workflowStepName={itemData.step_name}
                                            workflowName={workflowName}
                                            workflowStepAndPermissionList={{
                                                MANAGER_F_A: 'scm:collection:manager_f_a:verifyAction',
                                            }}
                                            titleText="Verify"
                                            btnText="Verify"
                                            submitBtnText="Yes, Verify"
                                            confirmText="Are you sure you want to verify and forward this collection next step ?"
                                            handleCallbackFunc={handleCallbackFunc}
                                        />
                                    </>
                                )}

                            </div>
                            <div className="photo">
                                <Avatar size={80} shape="circle" src={CONSTANT_CONFIG.CDN_URL + 'image/' + itemData?.dealer_image} alt="Profile Pic" />
                            </div>
                            <div className="info">
                                <h2>{itemData?.dealer_name}</h2>
                                <p>{itemData?.dealer_contact_person}, {itemData?.dealer_contact_number}</p>
                                <p>TSE Name - {itemData?.employee_name}</p>
                                <p>Status - {WorkflowApprovalStatusEnum[itemData.status]}</p>
                            </div>
                            <div className="record-detail-button-bar">
                                {!loading && (
                                  <>
                                      <EditWorkflowAction entityId={itemData.id} workflowName={workflowName} workflowStepName={itemData.step_name} actionItem={CollectionAction.COMMON_ACTION.EDIT} handleCallbackFunc={handleCallbackFunc} />
                                      <DeleteWorkflowAction entityId={itemData.id} workflowName={workflowName} workflowStepName={itemData.step_name} actionItem={CollectionAction.COMMON_ACTION.DELETE} handleCallbackFunc={handleCallbackFunc} />
                                  </>
                                )}
                            </div>
                            <div className="workflow-step">
                                <Steps current={workflowCurrentStep}>
                                    { workflowStepData?.map((item, index) => (
                                        <Step key={item.name} title={WorkflowStepEnum[item.name] || item.name} />
                                    ))}
                                </Steps>
                            </div>
                        </div>

                        <div className="record-detail-tabs">
                            <Menu className="record-detail-tab-menu" mode="horizontal">
                                {ViewTabList.map((tab: any, index: number) => (
                                    <Menu.Item
                                        key={`tab-${index}`}
                                        className={tab.tabIndex == viewTabIndex ? 'tab-item active' : 'tab-item'}
                                    >
                                        <a onClick={() => handleTabSelect(tab.tabIndex)} >{tab.title}</a>
                                    </Menu.Item>
                                ))}
                            </Menu>
                        </div>

                        <div className="record-detail-tabs-content">
                            {viewTabIndex == 1 && (
                                <CollectionViewTabInfo itemData={itemData} />
                            )}
                        </div>
                    </div>
                </Col>
                <Col span={5}>
                    <div className="view-page-content-right activity-timeline">
                        <h2>Activity Timeline</h2>
                        <WorkflowTransitionTimelineList
                            workflowRecordId={itemData.id}
                            workflowStepName={itemData.step_name}
                            workflowName={workflowName}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default React.memo(CollectionView);