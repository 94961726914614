import React, { FC, Fragment, useEffect, useState } from 'react'
import {Form, Input, Row, Col, Select, DatePicker, Upload, Button} from 'antd';
import {CONSTANT_COMMON, CONSTANT_CONFIG} from "../../../../../constants";
import {useEmployeeContext} from "../../../../../hooks/context/useEmployeeContext";
import {usePaymentModContext} from "../../../../../hooks/context/usePaymentModContext";
import {useBankListContext} from "../../../../../hooks/context/useBankListContext";
import { UploadOutlined } from '@ant-design/icons';
import { FileApi } from '../../../../../api';
import {useCustomerContext} from "../../../../../hooks/context/useCustomerContext";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const initialState  = {
    fileInputValidation: [
        { required: false, message: '' }
    ],
}

const AddOrEditForm: FC<any> = props => {
    const { Option } = Select;
    const { TextArea } = Input;
    const { employeeContext, setEmployeeFieldValue, activeEmployeeList} = useEmployeeContext();
    const { customerContext, filterCustomerListByEmployeeId } = useCustomerContext();
    const { paymentModContext, setPaymentModFieldValue, activePaymentModList} = usePaymentModContext();
    const { bankListContext, setBankFieldValue, activeBankList} = useBankListContext();
    const { formRef, rules, itemData, initialValues, customerInfo, handleChange, handleSubmit, handleSubmitFailed, fileList, setFileList } = props;
    const [customerList, setCustomerList] = useState<any>([]);
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);
    const [visibleBankInfo, setVisibleBankInfo] = useState(false);
    const [fileInputValidation, setFileInputValidation] = useState(initialState.fileInputValidation);

    useEffect(() => {
        if (itemData.employee_id && employeeContext.isLoading == false) {
            setEmployeeFieldValue(formRef, 'employee_id', itemData.employee_id);
        }
        if( itemData.employee_id && employeeContext.isLoading == false && customerContext.isLoading == false ){
            handleEmployeeSelect( itemData.employee_id, null );
        }
        if (itemData.payment_mode && paymentModContext.isLoading == false) {
            setPaymentModFieldValue(formRef, 'payment_mode', itemData.payment_mode);
        }
        if (itemData.bank_name && bankListContext.isLoading == false) {
            setBankFieldValue(formRef, 'bank_name', itemData.bank_name);
        }
    }, [itemData, employeeContext.isLoading, customerContext.isLoading, paymentModContext.isLoading, bankListContext.isLoading]);

    useEffect(() => {
        if (itemData.file) {
            FileApi.getFileById(itemData.file)
                .then(res => {
                    let fileInfo = {
                        uid: `${res.data.file_id}`,
                        file_id: res.data.file_id,
                        name: res.data.original_filename,
                        url: CONSTANT_CONFIG.MEDIA_SOURCE + res.data.file_id,
                    }
                    setFileList([fileInfo])
                    setUploadLoading(false)
                }).catch(err => {
                setUploadLoading(false)
            });

        }
    }, [itemData.file]);

    const handleEmployeeSelect = (employeeId: any, option: any) => {
        if (employeeId) {
            formRef.setFieldsValue({
                customer_id: null,
            })
            const filterCustomerList = filterCustomerListByEmployeeId(employeeId);
            if (filterCustomerList?.find(item => item.id === Number(itemData.customer_id))) {
                formRef.setFieldsValue({'customer_id': itemData.customer_id})
            }
            else {
                formRef.setFieldsValue({'customer_id': null})
            }
            setCustomerList(filterCustomerList);
        }
    }

    const handlePaymentSelect = (value: any, option: any) => {
        if( value == 'Cheque' ||  value == 'Fast Track' ||  value == 'Agent Banking'){
            setFileInputValidation( [{ required: true, message: 'This field is required.' }] );
        }
        else {
            setFileInputValidation( initialState.fileInputValidation );
        }
        if (value == 'Bank') {
            setVisibleBankInfo(true);
        }
        else {
            setVisibleBankInfo(false);
        }
    }

    const handleChangeFile = (info: any) => {
        if (info.file.status === 'uploading') {
            setUploadLoading(true)
            return;
        }
        if (info.file.status === 'error') {
            setUploadLoading(false)
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoading(false)
            return;
        }
    };

    const handleBeforeUploadFile = (file: File, FileList: File[]) => {
        return true
    }

    const handleRemoveUploadFile = (file: any) => {
        setFileList((prevFileList) => {
            return prevFileList.filter(item => item !== file)
        })
    };

    const handleFileUpload = (name: string, file: any) => {
        setUploadLoading(true)
        FileApi.upload(file)
            .then(res => {
                setFileList((prevFileList) => {
                    return [
                        {
                            uid: `${res.data.file_id}`,
                            file_id: res.data.file_id,
                            name: res.data.original_filename,
                            url: CONSTANT_CONFIG.MEDIA_SOURCE + res.data.file_id,
                        }
                    ]
                })
                setUploadLoading(false)
            })
            .catch(err => {
                setUploadLoading(false)
            });
    };

    return (
        <Fragment>
            <div className="form-page-content form-page-content-collection">
                <Form
                    {...formItemLayout}
                    form={formRef}
                    name="collectionForm"
                    scrollToFirstError={true}
                    initialValues={initialValues}
                    onValuesChange={handleChange}
                    onFinish={handleSubmit}
                    onFinishFailed={handleSubmitFailed}
                >
                    <Row>
                        <Col span={24}>
                            {!customerInfo?.id && (
                            <Form.Item
                                label="TSE Name"
                                name="employee_id"
                                rules={rules.employee_id}
                            >
                                <Select
                                    showSearch
                                    placeholder="-- Select --"
                                    onSelect={handleEmployeeSelect}
                                    loading={employeeContext.isLoading}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {activeEmployeeList && activeEmployeeList.map((item, index) => (
                                        <Option key={`employee-${index}`} value={item.id}>{`${item.name} [${item.code}]`}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            )}

                            {!customerInfo?.id && (
                            <Form.Item
                                label="Dealer/Client"
                                name="customer_id"
                                rules={rules.customer_id}
                            >
                                <Select
                                    placeholder="-- Select --"
                                    notFoundContent="No Dealer"
                                    loading={customerContext.isLoading}
                                >
                                    {customerList && customerList.map((item, index) => (
                                        <Option key={`customer-${index}`} value={item.id}>{`${item.name} [${item.code}, ${item.division_name}]`}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            )}

                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={rules.amount}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Collection Date"
                                name="date"
                                rules={rules.date}
                            >
                                <DatePicker
                                    format={CONSTANT_COMMON.DATE_PICKER_DATE_FORMAT}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Payment Mode"
                                name="payment_mode"
                                rules={rules.payment_mode}
                            >
                                <Select
                                    placeholder="-- Select --"
                                    onSelect={handlePaymentSelect}
                                    loading={paymentModContext.isLoading}
                                >
                                    {activePaymentModList && activePaymentModList.map((item, index) => (
                                        <Option key={`payment-mode-${index}`} value={item.value}>{item.value}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Bank Name"
                                name="bank_name"
                            >
                                <Select
                                    placeholder="-- Select --"
                                    loading={bankListContext.isLoading}
                                >
                                    {activeBankList && activeBankList.map((item, index) => (
                                        <Option key={`bank-list-${index}`} value={item.key}>{item.value}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            
                            <Form.Item
                                label="Branch Name"
                                name="branch_name"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="File"
                                name="file"
                                rules={fileInputValidation}
                            >
                                <Upload
                                name="file_uploader"
                                className="file-uploader"
                                multiple={false}
                                fileList={fileList}
                                onChange={handleChangeFile}
                                beforeUpload={handleBeforeUploadFile}
                                onRemove={handleRemoveUploadFile}
                                customRequest={({file}) => handleFileUpload('file', file)}
                            >
                                <Button
                                    className="btn-upload"
                                    icon={<UploadOutlined />}
                                    loading={uploadLoading}
                                >
                                    Click to Upload
                                </Button>
                            </Upload>
                            </Form.Item>

                            <Form.Item
                                label="Note"
                                name="comment"
                            >
                                <TextArea
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Fragment>
    );
}
export default React.memo(AddOrEditForm);