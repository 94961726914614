// CDN Server
import _FileApi from "./CdnServerApi/File.api";
import _CsvApi from "./CdnServerApi/Csv.api";
import _GatewayApi from "./GatewayServerApi/Gateway.api";

// Auth Server
import _GroupApi from "./AuthServerApi/Group.api";
import _OauthApi from "./AuthServerApi/Oauth.api";
import _PermissionApi from "./AuthServerApi/Permission.api";
import _ResourceApi from "./AuthServerApi/Resource.api";
import _RoleApi from "./AuthServerApi/Role.api";
import _ScopeApi from "./AuthServerApi/Scope.api";
import _UserApi from "./AuthServerApi/User.api";
import _SampleApi from "./AuthServerApi/Sample.api";
import _WorkspaceApi from "./AuthServerApi/Workspace.api";
import _OrganizationApi from "./AuthServerApi/Organization.api";

// Core Server
import _NotificationApi from "./CoreServerApi/Notification.api";
import _LookupApi from "./CoreServerApi/Lookup.api";
import _LocationApi from "./CoreServerApi/Location.api";
import _PostApi from "./CoreServerApi/Post.api";
import _OptionApi from "./CoreServerApi/Option.api";
import _TemplateApi from "./CoreServerApi/Template.api";
import _HelpApi from "./CoreServerApi/Help.api";
import _ActivityLogApi from "./CoreServerApi/ActivityLog.api";
import _AuditLogApi from "./CoreServerApi/AuditLog.api";
import _MessageApi from "./CoreServerApi/Message.api";
import _MessageLogApi from "./CoreServerApi/MessageLog.api";

// Scm Server
import _AdjustmentApi from "./ScmServerApi/Adjustment.api";
import _CreditLimitApi from "./ScmServerApi/CreditLimit.api";
import _DepartmentApi from "./ScmServerApi/Department.api";
import _DesignationApi from "./ScmServerApi/Designation.api";
import _DriverApi from "./ScmServerApi/Driver.api";
import _ProductApi from "./ScmServerApi/Product.api";
import _ReplacementApi from "./ScmServerApi/Replacement.api";
import _RetailerApi from "./ScmServerApi/Retailer.api";
import _SecurityApi from "./ScmServerApi/Security.api";
import _TargetApi from "./ScmServerApi/Target.api";
import _VehicleApi from "./ScmServerApi/Vehicle.api";
import _FobTransportApi from "./ScmServerApi/FobTransport.api";
import _WarehouseApi from "./ScmServerApi/Warehouse.api";
import _RequestAccessApi from "./AuthServerApi/RequestAccess.api";
import _PromotionApi from "./ScmServerApi/Promotion.api";
import _EmployeeApi from "./ScmServerApi/Employee.api";
import _DealerApi from "./ScmServerApi/Dealer.api";
import _CustomerApi from "./ScmServerApi/Customer.api";
import _ClientApi from "./ScmServerApi/Client.api";
import _CollectionApi from "./ScmServerApi/Collection.api";
import _PricingApi from "./ScmServerApi/Pricing.api";
import _DeliveryOrderApi from "./ScmServerApi/DeliveryOrder.api";
import _ChallanApi from "./ScmServerApi/Challan.api";
import _SalesOrderApi from "./ScmServerApi/SalesOrder.api";
import _SalesOrderItemApi from "./ScmServerApi/SalesOrderItem.api";
import _SalesOrderBookingApi from "./ScmServerApi/SalesOrderBooking.api";
import _MonetaryCommitmentApi from "./ScmServerApi/MonetaryCommitment.api";
import _OpeningApi from "./ScmServerApi/Opening.api";
import _WorkflowApi from "./ScmServerApi/Workflow.api";
import _WorkflowStepApi from "./ScmServerApi/WorkflowStep.api";
import _WorkflowActionApi from "./ScmServerApi/WorkflowAction.api";
import _WorkflowTransitionApi from "./ScmServerApi/WorkflowTransition.api";
import _ReportApi from "./ScmServerApi/Report.api";
import _ImportScmApi from "./ScmServerApi/ImportScm.api";
import _ExportScmApi from "./ScmServerApi/ExportScm.api";
import _CommissionOfferApi from "./ScmServerApi/CommissionOffer.api";
import _AddressApi from "./ScmServerApi/Address.api";
import _CommissionScheduleApi from "./ScmServerApi/CommissionSchedule.api";
import _TransferOrderApi from "./ScmServerApi/TransferOrder.api";
import _TransferOrderRequestApi from "./ScmServerApi/TransferOrderRequest.api";
import _DashboardApi from "./ScmServerApi/Dashboard.api";
import _ProjectApi from "./ScmServerApi/Project.api";

// CND Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();
export const GatewayApi = new _GatewayApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const SampleApi = new _SampleApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();

// Core Server
export const NotificationApi = new _NotificationApi();
export const LookupApi = new _LookupApi();
export const LocationApi = new _LocationApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const TemplateApi = new _TemplateApi();
export const HelpApi = new _HelpApi();
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const MessageApi = new _MessageApi();
export const MessageLogApi = new _MessageLogApi();

// Scm Server
export const AdjustmentApi = new _AdjustmentApi();
export const CreditLimitApi = new _CreditLimitApi();
export const DepartmentApi = new _DepartmentApi();
export const DesignationApi = new _DesignationApi();
export const DriverApi = new _DriverApi();
export const ProductApi = new _ProductApi();
export const ReplacementApi = new _ReplacementApi();
export const RetailerApi = new _RetailerApi();
export const ProjectApi = new _ProjectApi();
export const SecurityApi = new _SecurityApi();
export const TargetApi = new _TargetApi();
export const VehicleApi = new _VehicleApi();
export const FobTransportApi = new _FobTransportApi();
export const WarehouseApi = new _WarehouseApi();
export const RequestAccessApi = new _RequestAccessApi();
export const PromotionApi = new _PromotionApi();
export const EmployeeApi = new _EmployeeApi();
export const DealerApi = new _DealerApi();
export const CustomerApi = new _CustomerApi();
export const ClientApi = new _ClientApi();
export const CollectionApi = new _CollectionApi();
export const PricingApi = new _PricingApi();
export const DeliveryOrderApi = new _DeliveryOrderApi();
export const ChallanApi = new _ChallanApi();
export const SalesOrderApi = new _SalesOrderApi();
export const SalesOrderItemApi = new _SalesOrderItemApi();
export const SalesOrderBookingApi = new _SalesOrderBookingApi();
export const MonetaryCommitmentApi = new _MonetaryCommitmentApi();
export const OpeningApi = new _OpeningApi();
export const WorkflowApi = new _WorkflowApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowActionApi = new _WorkflowActionApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();
export const ReportApi = new _ReportApi();
export const ImportScmApi = new _ImportScmApi();
export const ExportScmApi = new _ExportScmApi();
export const CommissionOfferApi = new _CommissionOfferApi();
export const AddressApi = new _AddressApi();
export const CommissionScheduleApi = new _CommissionScheduleApi();
export const TransferOrderApi = new _TransferOrderApi();
export const TransferOrderRequestApi = new _TransferOrderRequestApi();
export const DashboardApi = new _DashboardApi();
