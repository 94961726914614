import React, {FC, useContext, useEffect, useState} from 'react'
import { NavLink } from 'react-router-dom';
import {Menu, Layout} from 'antd';
import {LeftSidebarMenu} from "./LeftSidebar.menu";
import { DashOutlined } from '@ant-design/icons';
import {useWindowSize} from '../../../../hooks/useWindowSize';
import {SettingContext} from "../../../../context/setting/setting.context";
import {usePermissionContext} from "../../../../hooks/context/usePermissionContext";
import './LeftSidebar.style.scss';

const LeftSidebar: FC<any> = props => {
    const { isPermissionLoaded, hasPermission } = usePermissionContext();
    const { innerHeight } = useWindowSize()
    const settingContext = useContext(SettingContext);
    const [collapsed, setCollapsed] = useState(true);
    const [navigationList, setNavigationList] = useState<any>([]);
    const [leftMenuVisible, setLeftMenuVisible] = useState<any>([]);
    const [leftMenuMore, setLeftMenuMore] = useState<any>([]);

    useEffect(() => {
        if (isPermissionLoaded) {
            const leftMenuList = LeftSidebarMenu.filter((item: any) => {
                return hasPermission(item?.permission)
            })
           setNavigationList(leftMenuList);
        }
    }, [isPermissionLoaded])

    useEffect(() => {
        if (innerHeight) {
            let arrayLength = 10
            arrayLength = (innerHeight / 65);
            arrayLength = parseInt(arrayLength.toString());

            let visibleArray: any = []
            let moreArray: any = []
            navigationList?.map((item, index) => {
                if (index > (arrayLength - 1)) {
                    moreArray.push(item);
                }
                else {
                    visibleArray.push(item)
                }
            })
            setLeftMenuVisible(visibleArray);
            setLeftMenuMore(moreArray);
        }
    }, [innerHeight, navigationList])

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const handleClick = ({ key }) => {
        settingContext.setActiveMenuKey(key)
    }

    return (
        <Layout.Sider width={300} className="app-left-nav" trigger={null} collapsible collapsed={collapsed}>
            <Menu
                onClick={handleClick}
                selectedKeys={[settingContext.activeMenuKey as any]}
                className="navigation-menu"
                mode={'vertical'}
            >
                {leftMenuVisible.map((item: any, index: number) => (
                    <Menu.Item key={`navigation-menu-${index}`} icon={item.icon}>
                        <NavLink to={item.link.to}>{item.title}</NavLink>
                    </Menu.Item>
                ))}

                { leftMenuMore.length && (
                    <Menu.SubMenu className="more-sub-menu" key="more-sub-menu" title={
                        <span>
                            <DashOutlined />
                            <span>More</span>
                        </span>
                    }
                    >
                        {leftMenuMore.map((item: any, index: number) => (
                            <Menu.Item className="more-sub-menu-item" key={`more-sub-menu:${index}`} icon={item.icon}>
                                <NavLink to={item.link.to}>{item.title}</NavLink>
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                )}

            </Menu>
        </Layout.Sider>
    );
}

export default React.memo(LeftSidebar);