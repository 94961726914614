import React, {FC, useContext} from 'react'
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { HeaderTabMenu } from "./HeaderTab.menu";
import {SettingContext} from "../../../../context/setting/setting.context";
import './HeaderTab.style.scss';

const HeaderTab: FC<any> = props => {
    const settingContext = useContext(SettingContext);

    if (settingContext.headerTabGroup) {
        return (
            <div className="header-tabs">
                <Menu className="header-tab-menu">
                    {HeaderTabMenu.filter(item => item.tabGroup == settingContext.headerTabGroup).map((item: any, index: number) => (
                        <Menu.Item key={index}>
                            <NavLink exact activeClassName="active" to={item.link.to}>{item.title}</NavLink>
                        </Menu.Item>
                    ))}
                </Menu>
            </div>
        );
    }

    return (
        <></>
    );
}

export default React.memo(HeaderTab);