import React, {FC, useContext, useEffect, useState} from "react"
import { NavLink } from 'react-router-dom';
import {Badge, Layout, Input} from 'antd';
import {ObjectUtils} from "../../../../utils";
import CustomScrollbar from "../../../../components/Scrollbar/CustomScrollbar";
import {usePermissionContext} from "../../../../hooks/context/usePermissionContext";
import {CountContext} from "../../../../context/count/count.context";
import {getToDoListSidebarMenu} from "./ToDoListSidebar.menu";
import './ToDoListSidebar.style.scss';

const ToDoListSidebar: FC<any> = props => {
    const { countSalesOrderBadge, countDeliveryOrderBadge, countChalanBadge, countCollectionBadge, countCreditLimitBadge, countRetailerBadge } = useContext(CountContext);
    const { isPermissionLoaded, hasPermission } = usePermissionContext();
    const [search, setSearch] = useState('');
    const [navigationList, setNavigationList] = useState<any>([]);

    const handleSearch = (value: any) => {
        setSearch(value)
    }

    useEffect(() => {
        const ToDoListSidebarMenu = getToDoListSidebarMenu(countSalesOrderBadge, countDeliveryOrderBadge, countChalanBadge, countCollectionBadge, countCreditLimitBadge, countRetailerBadge);
        let ToDoListMenuList = ObjectUtils.objectClone(ToDoListSidebarMenu)
        if (isPermissionLoaded) {
            ToDoListMenuList = ToDoListMenuList.filter((item: any) => {
                if (item.children) {
                    return item.children = item.children.filter((childItem) =>
                        hasPermission(childItem.permission)
                    )
                }
            })
            setNavigationList(ToDoListMenuList);
        }
        if (search) {
            let searchNavigationList = ToDoListMenuList.filter((item: any) => {
                 if (item.title.toLowerCase().includes(search.toLowerCase())) {
                     return true;
                 }

                if (item.children) {
                    return item.children = item.children.filter((childItem) =>
                        childItem.title.toLowerCase().includes(search.toLowerCase())
                    )
                }
            })

            setNavigationList(searchNavigationList);
        }
    }, [search, isPermissionLoaded, countSalesOrderBadge, countDeliveryOrderBadge, countChalanBadge, countCollectionBadge, countCreditLimitBadge, countRetailerBadge])

    return (
        <Layout.Sider width={200} className="app-to-do-list-left-nav">
            <div className="navigation-wrapper">
                <div className="navigation-search">
                    <Input
                        name="search"
                        placeholder="Search"
                        autoComplete='off'
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
                <CustomScrollbar autoHide={true} className="left-navigation-scrollbar left-navigation-scrollbar-setting">
                    <ul className="to-do-list-navigation-menu">
                        { navigationList.map((item: any, index: number) => {return (
                            <>
                                {item.children.length > 0 &&  (
                                    <li key={index} className="nav-link">
                                        <a href="javascript:void(0)">{item.title}</a>
                                        <ul className="sub-nav">
                                            {item.children.map((childItem: any, childIndex: number) => (
                                                <li key={`to-do-sub-nav-${index}-${childIndex}`} className="sub-nav-link">
                                                    <NavLink
                                                        to={childItem.link.to}
                                                    >
                                                        {childItem.title}
                                                        &nbsp;&nbsp;
                                                        <Badge
                                                            count={childItem.badge}
                                                            style={{ boxShadow: 'none' }}
                                                            className="badge badge-workspace"
                                                            overflowCount={9}
                                                        />
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                )}
                            </>
                        )})}
                    </ul>
                </CustomScrollbar>
            </div>
        </Layout.Sider>
    );
}

export default React.memo(ToDoListSidebar);