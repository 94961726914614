import React, { FC, Fragment } from 'react'
import {Drawer, Spin, Button} from 'antd';
import AddOrEditForm from "./CollectionForm.form";
import CustomScrollbar from "../../../../../components/Scrollbar/CustomScrollbar";

interface IProps {
    formRef: any,
    modalTitle: any,
    loading: any,
    isShowForm: any,
    handleCallbackFunc: any,
    [key: string]: any,
}

const CollectionFormDrawer: FC<IProps> = props => {
    const { formRef, modalTitle, loading, isShowForm, handleCallbackFunc, ...restProps } = props;
    const onSubmit = () => {
        formRef.submit();
    }
    return (
        <Fragment>
            <Drawer
                width={900}
                className="form-page-drawer form-page-drawer-collection"
                title={<b>{modalTitle}&nbsp;&nbsp;{loading && <Spin size="small" />}</b>}
                visible={isShowForm}
                onClose={(event) => handleCallbackFunc(null, 'hideForm')}
                footer={[
                    <Button key='cancel' onClick={(event) => handleCallbackFunc(null, 'hideForm')}>Cancel</Button>,
                    <Button key='submit' type='primary' loading={loading} onClick={onSubmit}>Submit</Button>
                ]}
            >
                <CustomScrollbar className="form-page-drawer-scrollbar">
                    <AddOrEditForm
                        formRef={formRef}
                        handleCallbackFunc={handleCallbackFunc}
                        {...restProps}
                    />
                </CustomScrollbar>
            </Drawer>
        </Fragment>
    );
}
export default React.memo(CollectionFormDrawer);