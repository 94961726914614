import { useState, useEffect, useContext } from 'react'
import {DataContext} from "../../context/data/data.context";
import {EmployeeApi} from "../../api";

export const useUserContext = () => {
    const dataContext = useContext(DataContext)
    const [userContext, setUserContext] = useState<any>([]);
    const [userList, setUserList] = useState<any>([]);
    const [activeUserList, setActiveUserList] = useState<any>([]);
    const [activeConsenterUserList, setActiveConsenterUserList] = useState<any>([]);
    const [selectedUser, setSelectedUser] = useState<any>({});
    const [loadingUser, setLoadingUser] = useState(false);

    useEffect(() => {
        setUserContext(dataContext.userContext);

        if (!dataContext.userContext.isLoaded) {
            dataContext.loadUser()
        }
        else {
            const items = dataContext.userContext.items
            loadUserList(items);
            loadActiveUserList(items);
            loadActiveConsenterUserList(items);
        }
    }, [dataContext.userContext.isLoaded]);

    const loadUserList = (list: any) => {
        setUserList(list);
    }

    const loadActiveUserList = (list: any) => {
        const activeList = list.filter(item => item.status === 1)
        setActiveUserList(activeList);
    }

    const loadActiveConsenterUserList = (list: any) => {
        const activeList = list.filter(item => item.status === 1 && ['Consenter'].includes(item.role_name))
        setActiveConsenterUserList(activeList);
    }

    const getUserById = (id) => {
        if(!userList) {
            return;
        }
        return userList.find(item => item.id === id)
    }

    const loadUserById = (id): Promise<any> => {
        return new Promise((resolve, reject) => {
            setLoadingUser(true);
            EmployeeApi.getById(id)
                .then(res => {
                    setSelectedUser(res.data);
                    setLoadingUser(false);
                    resolve(res.data);
                })
                .catch(err => {
                    setSelectedUser({});
                    setLoadingUser(false);
                    reject(err);
                });
        });
    }

    const setUserFieldValue = (formRef, key, value) => {
        if (activeUserList?.find(item => item.id === Number(value))) {
            formRef.setFieldsValue({[key]: value})
        }
        else {
            formRef.setFieldsValue({[key]: null})
        }
    }

    return { userContext, loadingUser, selectedUser, userList, activeUserList, activeConsenterUserList, setUserFieldValue, loadUserById, getUserById }
}