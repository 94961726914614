export enum WorkflowNameEnum {
    SALES_ORDER = 'Sales Order',
    SPECIAL_SALES_ORDER = 'Special Sales Order',
    NONTRADE_SALES_ORDER = 'Non-Trade Sales Order',
    NONTRADE_SPECIAL_SALES_ORDER = 'Non-Trade Special Sales Order',
    CHALLAN = 'Challan',
    NONTRADE_CHALLAN = 'Non-Trade Challan',
    COLLECTION = 'Collection',
    NONTRADE_COLLECTION = 'Non-Trade Collection',
    CREDIT_LIMIT = 'Credit Limit',
    NONTRADE_CREDIT_LIMIT = 'Non-Trade Credit Limit',
    TRANSFER_ORDER = 'Transfer Order',
    TRANSFER_ORDER_REQUEST = 'Transfer Order Request',
}

export enum WorkflowStepEnum {
    TSE = 'TSE',
    NONTRADE_TSE = 'Non-Trade TSE',
    CONSENTER = 'Consenter',
    NONTRADE_CONSENTER = 'Non-Trade Consenter',
    COORDINATOR = 'Coordinator',
    NONTRADE_COORDINATOR = 'Non-Trade Coordinator',
    MANAGER_F_A = 'Manager - F & A',
    CC = 'CC',
    HOT = 'HoT',
    HOC = 'HoC',
    HOM = 'HoM',
    HOF = 'HoF',
    CFO = 'CFO',
    CEO = 'CEO',
    BOD = 'BoD',
    MANAGER_DISTRIBUTION = 'Manager - Dist.',
    CHALLAN_CREATOR = 'Challan Creator',
    CHALLAN_APPROVER = 'Challan Approver',
    GATE_MAN = 'Gate Man',
    COMPLETED = 'Completed',
    TO_REQUESTER = 'TO Requester',
    TO_REQUEST_APPROVER = 'TO Request Approver',
    TO_CREATOR = 'TO Creator',
    TO_APPROVER = 'TO Approver',
    TO_RECEIVER = 'TO Receiver',
}

export enum WorkflowActionEnum {
    CREATED = 'Created',
    DRAFT = 'Save as Draft',
    SEND_BACK = 'Send Back',
    FORWARD = 'Forward',
    VERIFY = 'Verify',
    COMMENT = 'Comment',
    REVIEW = 'Review',
    RECOMMEND = 'Recommend',
    CALL_BACK = 'Call Back',
    GATE_PASS = 'Gate Pass',
    RECEIVE = 'Receive',
    APPROVE = 'Approve',
    CANCEL = 'Cancel',
}

export enum WorkflowApprovalStatusEnum {
    INITIATED = 'Initiated',
    REQUESTED = 'Requested',
    CONSENTED = 'Consented',
    COORDINATED = 'Coordinated',
    COMMENTED = 'Commented',
    REVIEWED = 'Reviewed',
    RECOMMEND_HOM = 'Recommended (HoM)',
    RECOMMEND_HOF = 'Recommended (HoF)',
    RECOMMEND_CFO = 'Recommended (CFO)',
    RECOMMEND_CEO = 'Recommended (CEO)',
    CALLED_BACK = 'Called Back',
    IN_TRANSIT = 'In Transit',
    RECEIVED = 'Received',
    VERIFIED = 'Verified',
    APPROVED = 'Approved',
    CANCELED = 'Cancelled',
}

export enum WorkflowSalesOrderStatusEnum {
    INITIATED = 'Initiated',
    REQUESTED = 'Requested',
    CONSENTED = 'Consented',
    COORDINATED = 'Coordinated',
    COMMENTED = 'Commented',
    REVIEWED = 'Reviewed',
    RECOMMEND_HOM = 'Recommended (HoM)',
    RECOMMEND_HOF = 'Recommended (HoF)',
    APPROVED = 'Approved',
    CANCELED = 'Cancelled',
}

export enum WorkflowDeliveryOrderStatusEnum {
    COMPLETE = 'Complete',
    PARTIAL = 'Partial',
}

export enum WorkflowChallanStatusEnum {
    INITIATED = 'Initiated',
    REQUESTED = 'Requested',
    APPROVED = 'Approved',
    CALLED_BACK = 'Called Back',
    IN_TRANSIT = 'In Transit',
    RECEIVED = 'Received',
}

export enum WorkflowCollectionStatusEnum {
    INITIATED = 'Initiated',
    REQUESTED = 'Requested',
    CONSENTED = 'Consented',
    VERIFIED = 'Verified',
}

export enum WorkflowCreditLimitStatusEnum {
    INITIATED = 'Initiated',
    REQUESTED = 'Requested',
    COORDINATED = 'Coordinated',
    COMMENTED = 'Commented',
    REVIEWED = 'Reviewed',
    RECOMMEND_HOM = 'Recommended (HoM)',
    RECOMMEND_HOF = 'Recommended (HoF)',
    APPROVED = 'Approved',
    CANCELED = 'Cancelled',
}

export enum WorkflowTransferOrderStatusEnum {
    INITIATED = 'Initiated',
    REQUESTED = 'Requested',
    COORDINATED = 'Coordinated',
    COMMENTED = 'Commented',
    REVIEWED = 'Reviewed',
    RECOMMEND_HOM = 'Recommended (HoM)',
    RECOMMEND_HOF = 'Recommended (HoF)',
    APPROVED = 'Approved',
    CANCELED = 'Cancelled',
}

export enum WorkflowTransferOrderRequestStatusEnum {
    INITIATED = 'Initiated',
    REQUESTED = 'Requested',
    COORDINATED = 'Coordinated',
    COMMENTED = 'Commented',
    REVIEWED = 'Reviewed',
    RECOMMEND_HOM = 'Recommended (HoM)',
    RECOMMEND_HOF = 'Recommended (HoF)',
    APPROVED = 'Approved',
    CANCELED = 'Cancelled',
}