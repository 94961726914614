export default class ObjectUtils {
    public isObject = (val: any): boolean => {
        return val != null && typeof val === 'object' && Array.isArray(val) === false;
    }

    public objectClone = (obj: object): any[] => {
        return JSON.parse(JSON.stringify(obj))
    }

    public arrayToObject = (array: Array<any>) => {
        array.reduce((obj, item) => {
                (obj as any)[item.id] = item;
                return obj;
            }, {}
        );
    }

    public removeNullOfObject = (obj: any) => {
        if (typeof obj === "object") {
            for (let [key, value] of Object.entries(obj)) {
                if (value === null) delete obj[key];
                else if (typeof value === "object") this.removeNullOfObject(value);
            }
        }
    }

    public removeProperties(properties: string[]) {
        return obj => {
            properties.forEach(property => {
                delete obj[property];
            });
            return obj;
        };
    }

    public deleteKeysFromObject(keysToDelete: string[], object: Object): Object {
        const clonedObject = Object.assign({}, object)
        keysToDelete.forEach(key => delete clonedObject[key])
        return clonedObject
    }
}

