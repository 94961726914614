import React, {FC, Fragment} from 'react'
import {Form, Input, Row, Col, Select, DatePicker} from 'antd';
import {EnumUtils, MathUtils} from "../../../../../../../utils";
import {DeliveryModeEnum, RequirementTypeEnum} from "../../../../../../../utils/enums";
import {CONSTANT_COMMON} from "../../../../../../../constants";
import {VehicleCapacityEnum} from "../../../../../../../utils/enums/VehicleCapacity.enum";
import {useProductContext} from "../../../../../../../hooks/context/useProductContext";
import {useWarehouseContext} from "../../../../../../../hooks/context/useWarehouseContext";
import { rules } from './SalesOrderItemCreateNonTradeTone.validate';
import {SalesOrderCalculationUtils} from "../../../Utils/SalesOrderCalculationUtils";

interface IProps {
    scheduleFormRef: any,
    customerInfo: any,
    [key: string]: any,
}

const SalesOrderItemCreateNonTradeToneForm: FC<IProps> = props => {
    const { Option } = Select;
    const { TextArea } = Input;

    const { scheduleFormRef, customerInfo, itemData, salesOrderData, salesOrderItemData, salesOrderBookingData, soCreationTime, projectList} = props;

    const { productContext, activeProductList} = useProductContext();
    const { warehouseContext, activeWarehouseList} = useWarehouseContext();

    const handleProjectSelect = (projectId: any, option: any) => {
        if (projectId) {
            const projectInfo = projectList.find((item) => item.id == projectId);
            scheduleFormRef.setFieldsValue({
                contact_number: projectInfo.contact_number,
                delivery_location_contact_number: projectInfo.contact_number,
                delivery_address: projectInfo.address,
            })
        }
        else {
            scheduleFormRef.setFieldsValue({
                contact_number: customerInfo.contact_number,
                delivery_location_contact_number: customerInfo.contact_number,
                delivery_address: customerInfo.address,
            })
        }
    }

    const handleScheduleQtyChange = (value: any) => {
        const total = Number(value);
        const freeBagOffer = Number(customerInfo.free_bag_offer);
        if (freeBagOffer == 0) {
            scheduleFormRef.setFieldsValue({
                schedule_actual_qty: total,
            })
        }
        else {
            const freeBagOfferValue = customerInfo.free_bag_offer_value || 0;
            const soActualQty = total + MathUtils.getPercent(total, freeBagOfferValue);

            scheduleFormRef.setFieldsValue({
                schedule_actual_qty: soActualQty,
            })
        }
    }

    const validateScheduleBilledQty = (_: any, qty: any) => {
        const inputQty = Number(qty) || 0;
        const previousInputQty = Number(itemData?.schedule_billed_qty) || 0;
        const scheduleQty = SalesOrderCalculationUtils.calculateTotalScheduleBilledQty(salesOrderItemData);
        const previousScheduleQty = Number(salesOrderData.so_billed_qty) || 0;
        const totalScheduleQty = (scheduleQty + inputQty) - previousInputQty;

        let remainingSoQty = Number(salesOrderData.so_booking_remaining_qty) || 0;
        if (salesOrderData.base_sales_order_no || salesOrderData.parent_sales_order_id) {
            remainingSoQty = Number(remainingSoQty) + Number(previousScheduleQty);
        }
        else {
            remainingSoQty = Number(salesOrderData.so_total_qty) || 0;
        }

        const maximumSoQty = remainingSoQty - (totalScheduleQty - inputQty);

        if (inputQty == 0) {
            return Promise.reject('This field is required.');
        }
        else if (salesOrderData.base_sales_order_no || salesOrderData.parent_sales_order_id || salesOrderData.delivery_type === 'PARTIAL_DELIVERY') {
            if ( maximumSoQty >= inputQty ) {
                return Promise.resolve();
            }
            else {
                return Promise.reject('Maximum Schedule Qty (Billed): ' + maximumSoQty);
            }
        }
        else {
            return Promise.resolve();
        }
    };

    function disabledDate(current) {
        return current && current <= soCreationTime;
    }

    return (
        <Fragment>
            <div className="customer_info">
                <h2>Customer Information</h2>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item
                            label="Customer Type"
                            name="customer_type"
                            hidden={true}

                        >
                            <Input type="text" value={"CLIENT"} />
                        </Form.Item>

                        <Form.Item
                            label="Client"
                            name="customer_name"
                        >
                            <Input
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Project"
                            name="project_id"
                        >
                            <Select
                                showSearch
                                allowClear={true}
                                placeholder="-- Select --"
                                optionFilterProp="children"
                                onChange={handleProjectSelect}
                                filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                            >
                                {projectList && projectList.map((item, index) => (
                                    <Option key={`project-${index}`} value={item.id}>{`${item.org_name}`}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Contact"
                            name="contact_number"
                            rules={rules.contact_number}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Delivery Location Contact"
                            name="delivery_location_contact_number"
                            rules={rules.delivery_location_contact_number}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Address"
                            name="delivery_address"
                            rules={rules.delivery_address}
                        >
                            <TextArea
                                autoSize={{ minRows: 1, maxRows: 5 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <div className="product_info">
                <h2>Product Information</h2>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item
                            label="Product Type"
                            name="product_type"
                            rules={rules.product_type}
                        >
                            <Select
                                placeholder="-- Select --"
                                loading={productContext.isLoading}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {activeProductList && activeProductList.map((item, index) => (
                                    <Option key={`product-type-${index}`} value={item.name}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Requirement Type"
                            name="requirement_type"
                            rules={rules.requirement_type}
                        >
                            <Select placeholder="-- Select --">
                                {EnumUtils.getEnumKeys(RequirementTypeEnum).map((key, index) => (
                                    <Option key={`requirement-type-${index}`} value={key}>{RequirementTypeEnum[key]}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Schedule Weight (Ton)"
                            name="schedule_billed_qty"
                             rules={[{ required: true, validator: validateScheduleBilledQty}]}
                        >
                            <Input
                                onChange={(e) =>handleScheduleQtyChange(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Unit Price (Per Ton)"
                            name="unit_price"
                            rules={rules.unit_price}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <div className="delivery_info">
                <h2>Delivery Information</h2>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Form.Item
                            label="Delivery Mode"
                            name="delivery_mode"
                            rules={rules.delivery_mode}
                        >
                            <Select placeholder="-- Select --">
                                {EnumUtils.getEnumKeys(DeliveryModeEnum).filter(item => customerInfo?.delivery_mode?.includes(item)).map((key, index) => (
                                    <Option key={`delivery-mode-${index}`} value={key}>{DeliveryModeEnum[key]}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Warehouse"
                            name="warehouse"
                            rules={rules.warehouse}
                        >
                            <Select
                                placeholder="-- Select --"
                                loading={warehouseContext.isLoading}
                            >
                                {activeWarehouseList && activeWarehouseList.filter(item => customerInfo?.warehouse?.includes(item.name)).map((item, index) => (
                                    <Option key={`warehouse-${index}`} value={item.name}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Vehicle Capacity"
                            name="vehicle_capacity"
                            rules={rules.vehicle_capacity}
                        >
                            <Select placeholder="-- Select --">
                                {EnumUtils.getEnumValues(VehicleCapacityEnum).map((key, index) => (
                                    <Option key={`vehicle-capacity-${index}`} value={key}>{key}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Customer Expected Delivery Time"
                            name="delivery_date_time"
                            rules={rules.delivery_date_time}
                        >
                            <DatePicker
                                showTime
                                disabledDate={disabledDate}
                                format={CONSTANT_COMMON.DATE_PICKER_DATE_TIME_FORMAT}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
}
export default React.memo(SalesOrderItemCreateNonTradeToneForm);