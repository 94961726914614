import React, {FC, Fragment, useState} from 'react'
import { Button } from 'antd';
import { EyeOutlined, CloseOutlined } from '@ant-design/icons';
import {DateTimeUtils} from "../../../../utils";

const NotificationDropdownItem: FC<any> = props => {
    const { notificationItem, handleMarkAsReadItem, handleDeleteItem } = props;
    const [hovering, setHovering] = useState(false);
    return (
        <Fragment>
            <div
                className={`notification-item ${hovering ? 'notification-item-hover' : ''} ${notificationItem.read_at ? 'notification-item-read' : ''}`}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
            >
                <div className="notification-item-top">
                    <div className="notification-item-content">
                        <span className="subject">{notificationItem.subject}</span>
                        <span className="message">{notificationItem.message}</span>
                    </div>
                </div>
                <div className="notification-item-bottom">
                    <div className="notification-item-action">
                        <span className="time">{DateTimeUtils.timeAgo(notificationItem.created_at)}</span>
                        { hovering && (
                            <span className="btn-group">
                                {!notificationItem.read_at && (
                                    <Button type="link" onClick={(event) =>handleMarkAsReadItem(notificationItem.id)} className="btn-mark-as-read" icon={<EyeOutlined />}>Mark as read</Button>
                                )}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default React.memo(NotificationDropdownItem);