import React, {FC, useEffect, useState} from 'react'
import SalesOrderCreateDrawer from './SalesOrderCreate.drawer';
import { useForm } from '../../../../../hooks/useForm';
import {AntModal, Message, DateTimeUtils, NumberUtils, StringUtils} from "../../../../../utils";
import {CustomerApi, EmployeeApi, SalesOrderApi, SalesOrderItemApi} from "../../../../../api";
import {CONSTANT_COMMON} from "../../../../../constants";
import {useCustomerContext} from "../../../../../hooks/context/useCustomerContext";
import {useRetailerContext} from "../../../../../hooks/context/useRetailerContext";
import {useEmployeeContext} from "../../../../../hooks/context/useEmployeeContext";
import {SalesOrderCalculationUtils} from "../Utils/SalesOrderCalculationUtils";
import {useProjectContext} from "../../../../../hooks/context/useProjectContext";
import '../../../styles/Order.module.form.scss';
import './SalesOrderCreate.style.scss';

const initialState  = {
    activeStep: 1,
    viewTabIndex: 1,
    modalTitle: 'Create Sales Order',
    salesOrderData: {
        id: null,
        employee_id: null,
        customer_id: null,
        base_sales_order_no: null,
        parent_sales_order_id: '',
        delivery_type: 'FULL_DELIVERY',
        selling_unit: 'BAG',
        so_creation_time: null,
        file: null,

        so_total_qty: null,
        so_billed_qty: null,
        so_booking_scheduled_qty: null,
        so_booking_remaining_qty: null,

        commitment_date: null,
        commitment_amount: null,
    },
    salesOrderBookingData: {
        id: null,
        base_sales_order_no: null,
        sales_order_no: null,

        so_total_qty: null,
        so_booking_remaining_qty: null,
    },
    salesOrderItemData: [],
    employeeList: [],
    customerList: [],
    retailerList: [],
    projectList: [],
    employeeInfo: {
        id: null,
        employee_group: null,
        first_name: null,
        last_name: null,
        code: null,
        reporting_manager_id: null,
        official_contact_number: null,
        official_email_address: null,
    },
    customerInfo: {
        id: null,
        parent_customer_id: null,
        employee_id: null,
        customer_group: 'TRADE',
        customer_type: null,
        credit_type: null,
        client_segment: null,
        org_name: null,
        code: null,
        first_name: null,
        last_name: null,
        email: null,
        contact_number: null,
        delivery_mode: null,
        default_delivery_mode: null,
        warehouse: null,
        default_warehouse: null,
        address: null,

        default_customer_price: null,
        closing_balance: null,
        amount_credit_limit: null,
        days_credit_limit: null,
        dso: null,
        block_amount: null,
    },
    scheduleFormType: 'SingleSchedule',
    deliveryScheduleFormType: 'TradeBag',
    isNewRecord: true,
    isSpecialSalesOrder: false,
    loading: false,
    loadingEmployee: false,
    loadingCustomer: false,
}

const SalesOrderCreateController: FC<any> = props => {
    const { entityId, reloadForm, isShowForm, handleCallbackFunc } = props
    const { formRef, initialValues, isSubmitting, setIsSubmitting, handleChange, handleSubmitFailed, resetForm } = useForm(initialState.salesOrderData);

    const { employeeContext, employeeList: allEmployeeList } = useEmployeeContext();
    const { customerContext, activeCustomerList: allCustomerList } = useCustomerContext();
    const { retailerContext, retailerList: allRetailerList } = useRetailerContext();
    const { projectContext, activeProjectList: allProjectList } = useProjectContext();

    const [modalTitle, setModalTitle] = useState(initialState.modalTitle);
    const [activeStep, setActiveStep] = useState(initialState.activeStep);
    const [viewTabIndex, setViewTabIndex] = useState(initialState.viewTabIndex);

    const [salesOrderData, setSalesOrderData] = useState<any>(initialState.salesOrderData);
    const [salesOrderBookingData, setSalesOrderBookingData] = useState<any>(initialState.salesOrderBookingData);
    const [salesOrderItemData, setSalesOrderItemData] = useState<any[]>(initialState.salesOrderItemData);
    const [employeeInfo, setEmployeeInfo] = useState<any>(initialState.employeeInfo);
    const [customerInfo, setCustomerInfo] = useState<any>(initialState.customerInfo);

    const [employeeList, setEmployeeList] = useState<any>(initialState.employeeList);
    const [customerList, setCustomerList] = useState<any>(initialState.customerList);
    const [retailerList, setRetailerList] = useState<any>(initialState.retailerList);
    const [projectList, setProjectList] = useState<any>(initialState.projectList);
    const [remainingMultiScheduleList, setRemainingMultiScheduleList] = useState<any>([]);

    const [fileList, setFileList] = useState<any[]>([]);
    const [scheduleFormType, setScheduleFormType] = useState<any>(initialState.scheduleFormType);
    const [deliveryScheduleFormType, setDeliveryScheduleFormType] = useState<any>(initialState.deliveryScheduleFormType);
    const [isNewRecord, setIsNewRecord] = useState(initialState.isNewRecord);
    const [loading, setLoading] = useState(initialState.loading);
    const [loadingEmployee, setLoadingEmployee] = useState(initialState.loadingEmployee);
    const [loadingCustomer, setLoadingCustomer] = useState(initialState.loadingCustomer);
    const [loadingRemainingMultiSchedule, setLoadingRemainingMultiSchedule] = useState(false);
    const [formActionName, setFormActionName] = useState('Save');

    const [scheduleSoValue, setScheduleSoValue] = useState(0);
    const [isSpecialSalesOrder, setIsSpecialSalesOrder] = useState(initialState.isSpecialSalesOrder);
    const [specialSalesOrderAlertText, setSpecialSalesOrderAlertText] = useState('');

    const [scheduleSoQty, setScheduleSoQty] = useState(0);

    useEffect(() => {
        if (entityId && isShowForm) {
            setIsNewRecord(false);
            setViewTabIndex(2);
            setActiveStep(2);
            setModalTitle('Edit Sales Order');
            handleResetForm();
            loadSalesOrderData();
            loadSalesOrderItemData();
        }
        else {
            setActiveStep(initialState.activeStep);
            setViewTabIndex(initialState.viewTabIndex);
            setScheduleFormType(initialState.scheduleFormType);
            setDeliveryScheduleFormType(initialState.deliveryScheduleFormType);
            setFormActionName('Save');

            setModalTitle(initialState.modalTitle);
            setIsNewRecord(initialState.isNewRecord);
            handleResetForm();
        }
    }, [entityId, reloadForm])

    useEffect(() => {
        if (salesOrderItemData.length && salesOrderData?.so_qty) {
            const scheduleSoBilledQty = SalesOrderCalculationUtils.calculateTotalScheduleBilledQty(salesOrderItemData);
            setScheduleSoQty(scheduleSoBilledQty);
        }

    }, [salesOrderItemData, salesOrderData?.so_qty])

    useEffect(() => {
        if (employeeContext.isLoading === false) {
            setEmployeeList(allEmployeeList);
        }
        if (salesOrderData.employee_id && employeeContext.isLoading === false) {
            filterCustomerList(salesOrderData.employee_id);
        }
    }, [salesOrderData.employee_id, employeeContext.isLoading]);

    useEffect(() => {
        if (salesOrderData.customer_id && retailerContext.isLoading === false) {
            filterRetailerList(salesOrderData.customer_id);
        }
        if (salesOrderData.customer_id && projectContext.isLoading === false) {
            filterProjectList(salesOrderData.customer_id);
        }
    }, [salesOrderData.customer_id, retailerContext.isLoading, projectContext.isLoading]);

    useEffect(() => {
        if (salesOrderData.delivery_type) {
            handleScheduleFormType(salesOrderData.delivery_type);
        }
    }, [salesOrderData.delivery_type]);

    useEffect(() => {
        if (customerInfo.customer_group && salesOrderData.selling_unit) {
            handleDeliveryScheduleFormType(customerInfo.customer_group, salesOrderData.selling_unit);
        }
    }, [customerInfo.customer_group, salesOrderData.selling_unit]);

    useEffect(() => {
        if (salesOrderData.parent_sales_order_id && remainingMultiScheduleList.length) {
            loadSalesOrderBookingData(salesOrderData.parent_sales_order_id);
        }
    }, [salesOrderData.parent_sales_order_id, remainingMultiScheduleList]);

    useEffect(() => {
        if (salesOrderData.employee_id && salesOrderData.customer_id) {
            loadCustomerRelationData(salesOrderData.employee_id, salesOrderData.customer_id);
        }
    }, [salesOrderData.employee_id, salesOrderData.customer_id]);

    useEffect(() => {
        if (salesOrderData.customer_id) {
            salesOrderAmountCalculation();
        }
    }, [salesOrderData, salesOrderItemData]);

    useEffect(() => {
        if (customerInfo && scheduleSoValue) {
            specialSalesOrderCondition();
        }
    }, [scheduleSoValue, customerInfo]);

    const loadCustomerRelationData = (employeeId: any, customerId: any): Promise<any> => {
        setLoading(true);
        filterRetailerList(customerId);
        filterProjectList(customerId);

        return Promise.all([
            loadEmployeeInfo(employeeId),
            loadCustomerInfo(customerId),
            loadRemainingMultiScheduleOrder(customerId),
        ])
        .then(res => {
            setLoading(false);
            return res
        })
        .catch(err => {
            setLoading(false);
        });
    }

    const loadSalesOrderData = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            SalesOrderApi.getById(entityId)
                .then(res => {
                    setSalesOrderData(res.data);
                    const initFormDta = {
                        employee_id: res.data.employee_id,
                        customer_id: res.data.customer_id,
                        parent_sales_order_id: res.data.parent_sales_order_id || '',
                        delivery_type: res.data.delivery_type,
                        selling_unit: res.data.selling_unit,
                        so_creation_time: res.data.so_creation_time ? DateTimeUtils.convertStringToDate(res.data.so_creation_time) : null,
                        file: res.data.file,

                        so_total_qty: res.data.so_total_qty,
                        so_billed_qty: res.data.so_billed_qty,
                        so_booking_scheduled_qty: res.data.so_booking_scheduled_qty,
                        so_booking_remaining_qty: res.data.so_booking_remaining_qty,

                        commitment_date: res.data.commitment_date ? DateTimeUtils.convertStringToDate(res.data.commitment_date) : null,
                        commitment_amount: NumberUtils.toNumber(res.data.commitment_amount)
                    }
                    handleChange(initFormDta);
                    formRef.setFieldsValue(initFormDta);
                    resolve(res.data);
                })
                .catch(err => {
                    setLoading(false);
                    reject(err);
                });
        });
    }

    const loadSalesOrderItemData = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            const payload = {
                '$filter': "sales_order_id='" + entityId + "'",
                '$orderby': "id asc",
            }
            SalesOrderItemApi.list(payload)
                .then(res => {
                    setSalesOrderItemData(res.data.results);
                    resolve(res.data.results);
                })
                .catch(err => {
                    setLoading(false);
                    reject(err);
                });
        });
    }

    const loadSalesOrderBookingData = (parentSalesOrderId): void => {
        const salesOrderBooking = remainingMultiScheduleList.find((item) => item.id == parentSalesOrderId);
        setSalesOrderBookingData(salesOrderBooking);
    }

    const loadEmployeeInfo = (employeeId): Promise<any> => {
        filterCustomerList(employeeId);
        return new Promise((resolve, reject) => {
            setLoadingEmployee(true);
            EmployeeApi.getById(employeeId)
                .then(res => {
                    setEmployeeInfo(res.data);
                    setLoadingEmployee(false);
                    resolve(res.data);
                })
                .catch(err => {
                    setLoadingEmployee(false);
                    reject(err);
                });
        });
    }

    const loadCustomerInfo = (customerId): Promise<any> => {
        return new Promise((resolve, reject) => {
            setLoadingCustomer(true);
            CustomerApi.getById(customerId)
                .then(res => {
                    setCustomerInfo(res.data);
                    setLoadingCustomer(false);
                    resolve(res.data);
                })
                .catch(err => {
                    setLoadingCustomer(false);
                    reject(err);
                });
        });
    }

    const loadRemainingMultiScheduleOrder = (customerId: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            const payload = {
                '$select': 'id,uuid,base_sales_order_no,sales_order_no,delivery_type,selling_unit,so_total_qty,so_billed_qty,file,schedule_status',
                '$filter': `customer_id=${customerId} AND base_sales_order_no IS NOT NULL AND schedule_status='PROGRESS' AND status='APPROVED'`,
                '$orderby': 'id desc',
            }
            SalesOrderApi.list(payload)
                .then(res => {
                    setRemainingMultiScheduleList(res.data.results);
                    setLoadingRemainingMultiSchedule(false);
                    resolve(res.data.results);
                })
                .catch(err => {
                    setLoadingRemainingMultiSchedule(false);
                    reject(err);
                });
        });

    }

    const filterCustomerList = (employeeId: any): void => {
        const customerList = allCustomerList.filter((item) => item.employee_id == employeeId);
        setCustomerList(customerList);
    }

    const filterRetailerList = (customerId: any): void => {
        const retailerList = allRetailerList.filter((item) => item.parent_customer_id == customerId);
        setRetailerList(retailerList);
    }

    const filterProjectList = (customerId: any): void => {
        const projectList = allProjectList.filter((item) => item.parent_customer_id == customerId);
        setProjectList(projectList);
    }

    const handleOnChangedSalesOrderData = (field: string, value: any) => {
        if (field === 'parent_sales_order_id') {
            const parentSalesOrderId = value;
            const parentSalesOrderInfo = remainingMultiScheduleList.find((item) => item.id == parentSalesOrderId);

            if (StringUtils.isNotNull(value)) {
                setSalesOrderData({
                    ...salesOrderData,
                    parent_sales_order_id : value,
                    delivery_type : 'PARTIAL_DELIVERY',
                    selling_unit: parentSalesOrderInfo?.selling_unit,
                    so_total_qty : null,
                    file : parentSalesOrderInfo?.file,
                    so_booking_remaining_qty : parentSalesOrderInfo?.so_booking_remaining_qty || 0,
                });
            }
            else {
                setSalesOrderData({
                    ...salesOrderData,
                    parent_sales_order_id : '',
                    delivery_type : 'FULL_DELIVERY',
                    selling_unit: 'BAG',
                    so_total_qty : null,
                    file : null,
                    so_booking_remaining_qty : null,
                });
                formRef.setFieldsValue({
                    parent_sales_order_id: '',
                    delivery_type: 'FULL_DELIVERY',
                    selling_unit: 'BAG',
                    file: null,
                    so_total_qty: null,
                });
                setFileList([]);
            }
        }
        else {
            setSalesOrderData({
                ...salesOrderData,
                [field]: value,
            });
        }
    }

    const salesOrderAmountCalculation = () => {

        if (salesOrderData.delivery_type === 'FULL_DELIVERY') {
            const totalScheduleSoValue = SalesOrderCalculationUtils.calculateTotalScheduleSoValue(salesOrderItemData);
            setScheduleSoValue(totalScheduleSoValue);
        }
        else if (salesOrderData.delivery_type === 'PARTIAL_DELIVERY' || salesOrderData.delivery_type === 'WITHOUT_DELIVERY') {
            const defaultCustomerPrice = Number(customerInfo.default_customer_price) || 0;

            const totalSoQty = Number(salesOrderData.so_total_qty) || 0;
            const totalScheduleSoQty = SalesOrderCalculationUtils.calculateTotalScheduleBilledQty(salesOrderItemData);
            const totalScheduleSoValue = SalesOrderCalculationUtils.calculateTotalScheduleSoValue(salesOrderItemData);

            const totalRemainingSoQty = (totalSoQty - totalScheduleSoQty);
            const totalRemainingSoValue = totalRemainingSoQty * defaultCustomerPrice;

            const totalSoValue = totalScheduleSoValue + totalRemainingSoValue;
            setScheduleSoValue(totalSoValue);
        }

    }

    const specialSalesOrderCondition = () => {
        const closingBalance = Number(customerInfo.closing_balance) || 0;
        const amountCreditLimit = Number(customerInfo.amount_credit_limit) || 0;
        const daysCreditLimit  = Number(customerInfo.days_credit_limit) || 0;
        const dso = Number(customerInfo.dso) || 0;
        const blockAmount = Number(customerInfo.block_amount) || 0;
        const creditType = customerInfo.credit_type || 'CREDIT';

        let specialSalesOrderAlertText = '';
        // 1. (closing balance + delivery schedule value) > acl
        if ((closingBalance + scheduleSoValue) > amountCreditLimit && creditType === 'CREDIT') {
            const exceededCredit = (closingBalance + scheduleSoValue) - amountCreditLimit;
            specialSalesOrderAlertText += `<li>`;
            specialSalesOrderAlertText += `ACL - Total amount is exceeding from ACL.`;
            specialSalesOrderAlertText += ` Closing Balance: ${NumberUtils.formatNumber(closingBalance)},`;
            specialSalesOrderAlertText += ` Schedule Value: ${NumberUtils.formatNumber(scheduleSoValue)},`;
            specialSalesOrderAlertText += ` Amount Credit Limit: ${NumberUtils.formatNumber(amountCreditLimit)},`;
            specialSalesOrderAlertText += ` Exceeded Credit: ${NumberUtils.formatNumber(exceededCredit)}.`;
            specialSalesOrderAlertText += `</li>`;
            setIsSpecialSalesOrder(true);
        }

        // 2. dso > dcl
        if (dso > daysCreditLimit && creditType === 'CREDIT') {
            specialSalesOrderAlertText += `<li>`;
            specialSalesOrderAlertText += ` DCL - Current DSO is exceeding from DCL.`;
            specialSalesOrderAlertText += ` DSO is ${dso} Days &  DCL is ${NumberUtils.formatNumber(daysCreditLimit)} Days.`;
            specialSalesOrderAlertText += `</li>`;
            setIsSpecialSalesOrder(true);
        }
        // 3. block amount  > 0
        if (blockAmount > 0 && creditType === 'CREDIT') {
            specialSalesOrderAlertText += `<li>`;
            specialSalesOrderAlertText += ` Block Amount - BDT ${NumberUtils.formatNumber(blockAmount)} is overdue for at least 121 days.`;
            specialSalesOrderAlertText += `</li>`;
            setIsSpecialSalesOrder(true);
        }

        if (customerInfo?.id && specialSalesOrderAlertText) {
            specialSalesOrderAlertText = '<ul>' + specialSalesOrderAlertText + '</ul>';
            setSpecialSalesOrderAlertText(specialSalesOrderAlertText);
        }
        else {
            setSpecialSalesOrderAlertText('');
        }
    }

    const handleScheduleFormType = (deliveryType: any): void => {
        if (deliveryType === 'FULL_DELIVERY') {
            setScheduleFormType('SingleSchedule');
        }
        else if (deliveryType === 'PARTIAL_DELIVERY') {
            setScheduleFormType('MultiSchedule');
        }
        else if (deliveryType === 'WITHOUT_DELIVERY') {
            setScheduleFormType('WithoutSchedule');
            setSalesOrderItemData(initialState.salesOrderItemData);
        }
    }

    const handleDeliveryScheduleFormType = (customerGroup: any, sellingUnit: any): void => {
        if (customerGroup === 'NONTRADE' && sellingUnit === 'TON') {
            setDeliveryScheduleFormType('NonTradeTone');
        }
        else if (customerGroup === 'NONTRADE') {
            setDeliveryScheduleFormType('NonTradeBag');
        }
        else if (customerGroup === 'TRADE') {
            setDeliveryScheduleFormType('TradeBag');
        }
    }

    const handleAddNewSalesOrderItem = (record: any) => {
        setSalesOrderItemData(
            prevState => {
                const orderItem =  {...record};
                return [...prevState, orderItem]
            }
        );
    }

    const handleSalesOrderItemEdit = (record: any, index:number) => {
        setSalesOrderItemData(salesOrderItemListData => {
            salesOrderItemListData[index] = record
            return [...salesOrderItemListData];
        });
    }

    const handleSalesOrderItemDelete = (itemIndex: any) => {
        AntModal.confirm('Delete sales order', 'Are you sure you want to delete this sales order?', itemIndex, handleSalesOrderItemDeleteConfirm, 'Delete');
    }

    const handleSalesOrderItemDeleteConfirm = (itemIndex: number) => {
        const filteredItems = salesOrderItemData.filter((item, index) => index !== itemIndex);
        setSalesOrderItemData(filteredItems)
    }

    const handleSalesOrderItemCallbackFunc = (event:any, action: string, index?:any, data?: any) => {
        if (action === 'addSalesOrderItem') {
            handleAddNewSalesOrderItem(data)
        }
        else if (action === 'updateSalesOrderItem') {
            handleSalesOrderItemEdit(data, index)
        }
        else if (action === 'deleteSalesOrderItem') {
            handleSalesOrderItemDelete(index)
        }
    }

    const handleResetForm = () => {
        setSalesOrderData(initialState.salesOrderData);
        setSalesOrderItemData(initialState.salesOrderItemData);
        setEmployeeInfo(initialState.employeeInfo);
        setCustomerInfo(initialState.customerInfo);
        setFileList([]);

        formRef.setFieldsValue({
            employee_id: null,
            customer_id: null,
        })

        resetForm();
    };

    const handleResetSalesOrderItem = (): void => {
        setSalesOrderItemData(initialState.salesOrderItemData);
    }

    const handleSubmitNextForm = (values: any): void => {
        setActiveStep(2);
        setViewTabIndex(2);
    }

    const handleTabIndex = (tabIndex: any) => {
        setViewTabIndex(tabIndex)
    }

    const handleSubmit = (values: any): void => {
        if (formActionName === 'Next') {
            handleSubmitNextForm(values);
        }
        else {
            if (entityId) {
                handleUpdate(values);
            }
            else {
                handleCreate(values);
            }
        }
    }

    const handleCreate = (values: any): void => {
        setLoading(true);
        setIsSubmitting(true);
        const payload = {
            salesOrder: {
                parent_sales_order_id: salesOrderData.parent_sales_order_id,
                employee_id: salesOrderData.employee_id,
                customer_id: salesOrderData.customer_id,
                delivery_type: salesOrderData.delivery_type,
                selling_unit: salesOrderData.selling_unit,
                free_bag_offer: customerInfo.free_bag_offer,
                free_bag_offer_value: customerInfo.free_bag_offer_value,
                so_total_qty: salesOrderData.so_total_qty,
                so_creation_time: DateTimeUtils.formatDateTime(salesOrderData.so_creation_time, 'YYYY-MM-DD hh:mm:ss'),
                file: fileList.map(item => item.file_id).join(','),
            },
            salesOrderItem: {
                ...salesOrderItemData
            },
            salesOrderCommitment: {
                commitment_date: DateTimeUtils.formatDate(values.commitment_date, CONSTANT_COMMON.DATE_FORMAT_DATABASE) || undefined,
                commitment_amount: values?.commitment_amount,
            }
        }
        SalesOrderApi.create(payload)
            .then(res => {
                Message.success('Sales order create successfully.');
                handleCallbackFunc(null, 'hideForm');
                handleCallbackFunc(null, 'reloadListing');
                setLoading(false);
                setIsSubmitting(false);
            })
            .catch(err => {
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
                setIsSubmitting(false);
            });
    }

    const handleUpdate = (values: any): void => {
        setLoading(true);
        setIsSubmitting(true);
        const payload = {
            salesOrder: {
                so_total_qty: salesOrderData.so_total_qty,
                so_creation_time: DateTimeUtils.formatDateTime(salesOrderData.so_creation_time, 'YYYY-MM-DD hh:mm:ss'),
                file: fileList.map(item => item.file_id).join(','),
            },
            salesOrderItem: {
                ...salesOrderItemData
            },
            salesOrderCommitment: {
                commitment_date: DateTimeUtils.formatDate(values.commitment_date, CONSTANT_COMMON.DATE_FORMAT_DATABASE),
                commitment_amount: values.commitment_amount,
            }
        }
        SalesOrderApi.update(entityId, payload)
            .then(res => {
                Message.success('Sales order update successfully.');
                handleCallbackFunc(null, 'hideForm');
                handleCallbackFunc(null, 'updateListItem', entityId, res.data);
                handleCallbackFunc(null, 'reloadView');
                setLoading(false);
                setIsSubmitting(false);
            })
            .catch(err => {
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
                setIsSubmitting(false);
            });
    }

    return (
        <div className="form-page-container form-page-container-sales-order">
            <SalesOrderCreateDrawer
                formRef={formRef}
                loading={loading}
                loadingEmployee={loadingEmployee}
                loadingCustomer={loadingCustomer}
                loadingRemainingMultiSchedule={loadingRemainingMultiSchedule}

                isNewRecord={isNewRecord}
                activeStep={activeStep}
                viewTabIndex={viewTabIndex}
                handleTabIndex={handleTabIndex}

                employeeInfo={employeeInfo}
                customerInfo={customerInfo}
                salesOrderData={salesOrderData}
                salesOrderBookingData={salesOrderBookingData}
                salesOrderItemData={salesOrderItemData}
                scheduleSoQty={scheduleSoQty}

                employeeContext={employeeContext}
                employeeList={employeeList}
                customerList={customerList}
                retailerList={retailerList}
                projectList={projectList}
                remainingMultiScheduleList={remainingMultiScheduleList}
                fileList={fileList}

                setFileList={setFileList}
                modalTitle={modalTitle}
                isSubmitting={isSubmitting}
                isShowForm={isShowForm}
                initialValues={initialValues}
                scheduleFormType={scheduleFormType}
                deliveryScheduleFormType={deliveryScheduleFormType}
                setFormActionName={setFormActionName}
                isSpecialSalesOrder={isSpecialSalesOrder}
                specialSalesOrderAlertText={specialSalesOrderAlertText}
                handleOnChangedSalesOrderData={handleOnChangedSalesOrderData}
                handleResetSalesOrderItem={handleResetSalesOrderItem}

                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleSubmitNextForm={handleSubmitNextForm}
                handleSubmitFailed={handleSubmitFailed}
                handleCallbackFunc={handleCallbackFunc}
                handleSalesOrderItemCallbackFunc={handleSalesOrderItemCallbackFunc}
            />
        </div>
    );
}

export default React.memo(SalesOrderCreateController);
