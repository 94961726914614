import React, { FC, useContext } from "react";
import { Redirect, Route } from 'react-router-dom';
import {AuthContext} from "../context/auth/auth.context";

const ProtectedRoute: FC<any> = ({ layout: Layout, component: Component, permission, ...rest }) => {
    const { isAuthenticated } = useContext(AuthContext)

    const hasPermission = false;

    if (isAuthenticated === false) {
        return (
            <Redirect to="/auth/login" />
        );
    }

    if (permission && hasPermission) {
        return (
            <Redirect to="/access/denied" />
        );
    }
    return (
        <Route
            {...rest}
            render={props =>
                Layout ? (
                    <Layout {...props}>{Component ? <Component {...props} /> : null}</Layout>
                ) : Component ? (
                    <Component {...props} />
                ) : null
            }
        />
    )
};

export default ProtectedRoute;

// <Route path="/accessdenied" component={Screen.AccessDenied} exact />
// import { ResultNotFound } from "../../pages/result/ResultNotFound";

// Good
// https://github.com/nauthna197/NewCaCRM/blob/6036ec2f58326c4a45c91c8740110ee60a79bc38/src/components/routes/PrivateRoute.tsx
// https://github.com/levinineiasi/fe-ide2www/blob/e9bff9d3463ba0c92df273a45d7d4ed68ee2db96/src/components/Router/ProtectedRoute.tsx
// https://github.com/kamsonpc/hackhathonMedic/blob/0bc18e0a07f965495a64a5ce64959c13635376c7/src/components/Router/ProtectedRoute.tsx
// https://github.com/thivi/intern-management-app/blob/7c1d5b68f1e13e724715ca996f6c02d4be328a52/src/helpers/protected-route.tsx
// https://github.com/rock007/backend-reactjs/blob/0ea3984c82534f13bd2cd228c89350a8061a818e/src/components/Router/ProtectedRoute.tsx
// https://github.com/phiathao/AV-ADMIN-UI-2/blob/905f108ff24cf5f4b0b0b9a2032848586609453a/src/components/_shared/component/protectedRoute/protectedRoute.component.tsx
// https://github.com/iamcest/hospitalrun-frontend/blob/689a8335d8d8449820a7e993caa654c9c4884dab/src/components/PrivateRoute.tsx

// https://github.com/robertsoniv/react-bachmans-admin/blob/eef89ffe44f46c0dfad79161a8a4b275044a4747/src/components/Layout/ProtectedRoute.tsx
