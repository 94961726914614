import React, { FC } from 'react'
import {Descriptions, Row, Col} from 'antd';
import {DateTimeUtils, NumberUtils} from "../../../../../utils";
import EditAction from "../../../../../components/Actions/EditAction";
import DeleteAction from "../../../../../components/Actions/DeleteAction";
import {AdjustmentAction} from "../Actions/Adjustment.actions";

const AdjustmentView: FC<any> = props => {
    const DescriptionsItem = Descriptions.Item;
    const {itemData, handleCallbackFunc} = props;

    return (
        <div className="view-page-content view-page-content-adjustment">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <EditAction entityId={itemData.id} actionItem={AdjustmentAction.COMMON_ACTION.EDIT} handleCallbackFunc={handleCallbackFunc} />
                    <DeleteAction entityId={itemData.id} actionItem={AdjustmentAction.COMMON_ACTION.DELETE} handleCallbackFunc={handleCallbackFunc} />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Descriptions bordered column={1}>
                        <DescriptionsItem label="Customer">{itemData?.dealer_name}</DescriptionsItem>
                        <DescriptionsItem label="Assigned Officer">{itemData?.employee_name}</DescriptionsItem>
                        <DescriptionsItem label="Adjustment Type">{itemData.adjustment_type_text}</DescriptionsItem>
                        <DescriptionsItem label="Amount">{NumberUtils.toFixed(itemData.amount)}</DescriptionsItem>
                        <DescriptionsItem label="Date">{DateTimeUtils.formatDate(itemData.date)}</DescriptionsItem>
                        <DescriptionsItem label="Note">{itemData.note}</DescriptionsItem>
                        <DescriptionsItem label="Created Time">{DateTimeUtils.formatDateTimeA(itemData.created_at)}</DescriptionsItem>
                        <DescriptionsItem label="Updated Time">{DateTimeUtils.formatDateTimeA(itemData.updated_at)}</DescriptionsItem>
                    </Descriptions>
                </Col>
            </Row>
        </div>
    );
}
export default React.memo(AdjustmentView);