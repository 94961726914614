import React, { FC } from 'react'
import {Button, Dropdown, Menu, Table} from "antd";
import { MoreOutlined } from '@ant-design/icons';
import {DateTimeUtils, NumberUtils} from "../../../../../../utils";
import EditAction from "../../../../../../components/Actions/EditAction";
import {SalesOrderItemAction} from "../Actions/SalesOrderItem.actions";
import {DeliveryModeEnum, RequirementTypeEnum, CustomerTypeEnum, BagTypeEnum} from '../../../../../../utils/enums';
import '../../../../styles/Order.module.listing.scss';
import '../List/SalesOrderItemList.style.scss';

interface IProps {
    loading: any,
    [key: string]: any,
}

const SalesOrderItemContentList: FC<IProps> = props => {
    const { loading, salesOrderItemData, handleDelete, handleEdit, ...restProps} = props;

    const tableSummary = (pageData) => {
        let totalBilledBagQty = 0;
        let totalActualBagQty = 0;
        pageData?.forEach(({ schedule_billed_qty,  schedule_actual_qty}) => {
            totalBilledBagQty += NumberUtils.toNumber(schedule_billed_qty);
            totalActualBagQty += NumberUtils.toNumber(schedule_actual_qty);
        });

        return (
            <>
                <tr>
                    <td colSpan={5}>Total</td>
                    <td colSpan={1}>{NumberUtils.formatNumberInteger(totalBilledBagQty)}</td>
                    <td colSpan={4}>{NumberUtils.formatNumberInteger(totalActualBagQty)}</td>
                </tr>
            </>
        );
    }

    const columns = [
        {
            dataIndex: "id",
            key: 'id',
            title: "SN",
            render: (text: string, record: any, index:number) => {
                return index + 1
            },
        },
        {
            dataIndex: "customer_type",
            key: 'customer_type',
            title: "Customer Type",
            render: (text: string, record: any, index:number) => CustomerTypeEnum[text] || text,
        },
        {
            dataIndex: "customer_name",
            key: 'customer_name',
            title: "Customer Name",
            render: (text: string, record: any, index:number) => {
                let resultText = '';
                resultText += text;
                resultText += '<br />';
                resultText += record.contact_number;
                return <div dangerouslySetInnerHTML={{ __html: resultText }} />;
            },
        },
        {
            dataIndex: "adjustment_type",
            key: 'adjustment_type',
            title: "Product Information",
            render: (text: string, record: any, index:number) => {
                let resultText = '';
                resultText += 'Product Type: ';
                resultText += record.product_type;
                resultText += '<br />';
                resultText += 'Requirement Type: ';
                resultText += RequirementTypeEnum[record.requirement_type] || record.requirement_type;
                resultText += '<br />';
                if (record.bag_type) {
                    resultText += 'Bag Type: ';
                    resultText += BagTypeEnum[record.bag_type] || record.bag_type;
                }
                return <div dangerouslySetInnerHTML={{ __html: resultText }} />;
            },
        },
        {
            dataIndex: "adjustment_type",
            key: 'adjustment_type',
            title: "Delivery Information",
            render: (text: string, record: any, index:number) => {
                let resultText = '';
                resultText += 'Mode: ';
                resultText += DeliveryModeEnum[record.delivery_mode] || record.delivery_mode;
                resultText += '<br />';
                resultText += 'Warehouse: ';
                resultText += record.warehouse;
                resultText += '<br />';
                resultText += record.location_type == 'SHOP' ?  'Shop: ' : 'Site: ';
                resultText += record.delivery_address;
                return <div dangerouslySetInnerHTML={{ __html: resultText }} />;
            },
        },
        {
            dataIndex: "selling_unit",
            key: 'selling_unit',
            title: "Selling Unit",
        },
        {
            dataIndex: "schedule_billed_qty",
            key: 'schedule_billed_qty',
            title: "Schedule Qty (Billed)",
        },
        {
            dataIndex: "schedule_actual_qty",
            key: 'schedule_actual_qty',
            title: "Schedule Qty (Actual)",
        },
        {
            dataIndex: "unit_price",
            key: 'unit_price',
            title: "Unit Price",
            render: (value) => NumberUtils.formatNumber(value),
        },
        {
            dataIndex: "vehicle_capacity",
            key: 'vehicle_capacity',
            title: "Vehicle Capacity",
        },
        {
            dataIndex: "delivery_date_time",
            key: 'delivery_date_time',
            title: "Delivery Time",
            render: (value) => DateTimeUtils.formatDateTimeA(value),
        },
        {
            dataIndex: 'action',
            key: 'action',
            title: 'Action',
            render: (text: string, record: any, index:number) => {
                const actionDropDownList = (
                    <Menu>
                        <Menu.Item key="1">
                            <EditAction
                                {...restProps}
                                entity={record}
                                entityId={record.id}
                                entityIndex={index}
                                salesOrderItemData={salesOrderItemData}
                                component={SalesOrderItemAction.COMMON_ACTION.EDIT.component}
                                actionItem={SalesOrderItemAction.COMMON_ACTION.EDIT}
                                manageUrl={false}
                                handleCallbackFunc={handleEdit}
                            >
                                <Button type="text">Edit</Button>
                            </EditAction>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Button type="text" onClick={() => handleDelete(null, 'deleteSalesOrderItem', index)}>Delete</Button>
                        </Menu.Item>
                    </Menu>
                );
                return (
                    <React.Fragment>
                        <Dropdown overlay={actionDropDownList} trigger={['click']} placement="bottomRight" className='table-row-action'>
                            <Button type="link"><MoreOutlined /></Button>
                        </Dropdown>
                    </React.Fragment>
                )
            },
        },
    ];

    return (
        <div className="listing-content listing-content-sales-order-item">
            <Table
                className="table-layout"
                rowKey={(record, index) => index === undefined ? Math.random().toString() : index.toString()}
                rowClassName={(record, index) => (index % 2 === 0 ? 'odd' : 'even')}
                dataSource={salesOrderItemData}
                columns={columns}
                pagination={false}
                loading={loading}
                bordered={false}
                summary={tableSummary}
            />
        </div>
    );
}

export default SalesOrderItemContentList;
