import React, {FC, useContext} from 'react';
import { Button } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import {usePermissionContext} from "../../../../../hooks/context/usePermissionContext";
import {useWorkflowContext} from "../../../../../hooks/context/useWorkflowContext";
import {AuthContext} from "../../../../../context/auth/auth.context";

interface IProps {
    children?: any,
    entityId: any,
    actionItem?: any,
    workflowName: string,
    workflowStatus?: string,
    workflowStepName: string,
    manageUrl?: boolean,
    handleCallbackFunc?: (event:any, action: string, recordId?:any, data?: any) => void,
    [key: string]: any,
}

const DeleteWorkflowAction: FC<IProps> = props => {
    const { children, entityId, actionItem, workflowStatus, workflowName, workflowStepName, manageUrl = true, handleCallbackFunc } = props;
    const { userWorkflowSteps } = useWorkflowContext();
    const { isPermissionLoaded, hasPermission } = usePermissionContext();
    const { userGroup } = useContext(AuthContext);

    const handleConfirmDelete = (entityId: any): void => {
        if (manageUrl && handleCallbackFunc) {
            handleCallbackFunc(null, 'delete', entityId);
        }
    }

    if (isPermissionLoaded && hasPermission(actionItem.permission) && workflowStepName !== 'COMPLETED' && workflowStepName !== 'RECEIVED' && userWorkflowSteps[workflowName] === workflowStepName) {
        return (
            <>
                {children ? (<span onClick={() => handleConfirmDelete(entityId)}>{children}</span>) : (<Button type="primary" className="btn btn-delete" onClick={() => handleConfirmDelete(entityId)}><CloseOutlined /> {actionItem.title}</Button>)}
            </>
        );
    }
    // Super Admin
    else if (isPermissionLoaded && hasPermission(actionItem.permission) && userGroup === 'GROUP_ADMINISTRATOR' && (workflowName === 'CHALLAN' || workflowName === 'NONTRADE_CHALLAN') && workflowStatus === 'CALLED_BACK') {
        return (
            <>
                {children ? (<span onClick={() => handleConfirmDelete(entityId)}>{children}</span>) : (<Button type="primary" className="btn btn-delete" onClick={() => handleConfirmDelete(entityId)}><CloseOutlined /> {actionItem.title}</Button>)}
            </>
        );
    }

    return <></>
}

export default DeleteWorkflowAction;