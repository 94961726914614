import React, { FC, Fragment } from 'react'
import {Modal, Spin, Button} from 'antd';
import AddOrEditForm from "./WorkflowTransitionForm.form";
import CustomScrollbar from "../../../../../components/Scrollbar/CustomScrollbar";

const WorkflowTransitionFormModal: FC<any> = props => {
    const { isNewRecord, loading, rules, itemData, workflowActionData, workflowName, titleText, confirmText, forwardTo, submitBtnText, fileList, setFileList, isShowFormModal, hideFormModal } = props;
    const { formRef, initialValues, editorText, handleCkEditorChange, handleChange, handleSubmit, handleSubmitFailed } = props;
    const onSubmit = () => {
        formRef.submit();
    }
    return (
        <Fragment>
            <Modal
                width={720}
                className="form-page-modal form-page-modal-workflow-transition"
                title={<b>{titleText}&nbsp;&nbsp;{loading && <Spin size="small" />}</b>}
                maskClosable={false}
                centered
                visible={isShowFormModal}
                onCancel={hideFormModal}
                footer={[
                    <Button key='cancel' onClick={hideFormModal}>Cancel</Button>,
                    <Button key='submit' type='primary' loading={loading} onClick={onSubmit}>{submitBtnText}</Button>
                ]}
            >
                <CustomScrollbar autoHeight autoHeightMax={480} className="form-page-modal-scrollbar">
                    <AddOrEditForm
                        formRef={formRef}
                        rules={rules}
                        isNewRecord={isNewRecord}
                        editorText={editorText}
                        confirmText={confirmText}
                        forwardTo={forwardTo}
                        initialValues={initialValues}
                        itemData={itemData}
                        workflowActionData={workflowActionData}
                        workflowName={workflowName}
                        fileList={fileList}
                        setFileList={setFileList}
                        handleCkEditorChange={handleCkEditorChange}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleSubmitFailed={handleSubmitFailed}
                    />
                </CustomScrollbar>
            </Modal>
        </Fragment>
    );
}
export default React.memo(WorkflowTransitionFormModal);