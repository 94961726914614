import React, {FC} from 'react'
import {Col, Collapse, Row} from 'antd';
import SalesOrderCreateCollapseOrderInfo from "../../Collapse/SalesOrderCreateCollapse.orderInfo";
import SalesOrderItemContentList from "../../Item/Content/SalesOrderItemContent.list";

interface IProps {
    formRef: any,
    loading: any,
    [key: string]: any,
}

const SalesOrderCreateWithoutScheduleForm: FC<IProps> = props => {
    const { Panel } = Collapse;
    const {formRef, loading, handleSalesOrderItemCallbackFunc, ...restProps} = props;

    return (
        <div className="form-page-content form-page-content-sales-order">
            <div className="sales-order-next-form">
                <div className="sales-order-form-info" style={{paddingBottom: 15}}>
                    <Collapse defaultActiveKey={[1]} expandIconPosition="left">
                        <Panel key="1" header="Sales Order Information">
                            <SalesOrderCreateCollapseOrderInfo
                                {...restProps}
                                formRef={formRef}
                                loading={loading}
                            />
                        </Panel>
                    </Collapse>
                </div>
            </div>

            <div className="sales-order-form-delivery-schedule">
                <Collapse defaultActiveKey={[1]} expandIconPosition="left">
                    <Panel key="1" header="Delivery Order Information">
                        <div className="collapse-panel-content">
                            <Row>
                                <Col span={24}>
                                    <SalesOrderItemContentList
                                        {...restProps}
                                        formRef={formRef}
                                        loading={loading}
                                        handleEdit={handleSalesOrderItemCallbackFunc}
                                        handleDelete={handleSalesOrderItemCallbackFunc}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Panel>
                </Collapse>
            </div>
        </div>
    );
}
export default React.memo(SalesOrderCreateWithoutScheduleForm);