import React, {FC, useEffect, useState} from 'react'
import AdjustmentFormDrawer from './AdjustmentForm.drawer';
import { useForm } from '../../../../../hooks/useForm';
import {DateTimeUtils, Message} from "../../../../../utils";
import { rules } from './AdjustmentForm.validate';
import { AdjustmentApi } from "../../../../../api";
import {CONSTANT_COMMON} from "../../../../../constants";
import '../../../styles/Scm.module.form.scss';
import './AdjustmentForm.style.scss';

const initialState  = {
    modalTitle: 'Create Adjustment',
    itemData: {},
    fields: {
        customer_group: 'TRADE',
        customer_id: null,
        adjustment_type: null,
        amount: null,
        date: null,
        note: null,
    },
    isNewRecord: true,
    loading: false,
}

const AdjustmentFormController: FC<any> = props => {
    const { entityId, reloadForm, isShowForm, customerInfo, handleCallbackFunc } = props
    const { formRef, initialValues, formValues, isSubmitting, setIsSubmitting, handleChange, handleSubmitFailed, resetForm } = useForm(initialState.fields);

    const [modalTitle, setModalTitle] = useState(initialState.modalTitle);
    const [itemData, setItemData] = useState(initialState.itemData);
    const [isNewRecord, setIsNewRecord] = useState(initialState.isNewRecord);
    const [loading, setLoading] = useState(initialState.loading);

    useEffect(() => {
        if (entityId && isShowForm) {
            resetForm();
            setIsNewRecord(false);
            setModalTitle('Edit Adjustment');
            loadData();
        }
        else {
            resetForm();
            handleChange(initialState.fields);
            setModalTitle(initialState.modalTitle);
            setIsNewRecord(initialState.isNewRecord);
        }
    }, [entityId, customerInfo, reloadForm])

    const loadData = (): void => {
        setLoading(true);
        AdjustmentApi.getById(entityId)
            .then(res => {
                setItemData(res.data);
                const initFormDta = {
                    customer_group: res.data.customer_group,
                    customer_id: res.data.customer_id,
                    adjustment_type: res.data.adjustment_type,
                    amount: res.data.amount,
                    date: res.data.date ? DateTimeUtils.convertStringToDate(res.data.date) : null,
                    description: res.data.description,
                    sales_order_no: res.data.sales_order_no,
                    challan_no: res.data.challan_no,
                    note: res.data.note,
                }
                handleChange(initFormDta)
                formRef.setFieldsValue(initFormDta)
                setLoading(false);
            })
            .catch(err => {
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
            });
    }

    const handleSubmit = (values: any): void => {
        if (entityId) {
            handleUpdate(values);
        }
        else {
            handleCreate(values);
        }
    }

    const handleCreate = (values: any): void => {
        setLoading(true);
        setIsSubmitting(true);
        const payload = {
            customer_id: values.customer_id  || customerInfo?.id,
            adjustment_type: values.adjustment_type,
            amount: values.amount,
            date: DateTimeUtils.formatDate(values.date, CONSTANT_COMMON.DATE_FORMAT_DATABASE),
            description: values.description,
            sales_order_no: values.sales_order_no,
            challan_no: values.challan_no,
            note: values.note,
        }
        AdjustmentApi.create(payload)
            .then(res => {
                Message.success('Adjustment create successfully.');
                handleCallbackFunc(null, 'hideForm');
                handleCallbackFunc(null, 'reloadListing');
                setLoading(false);
                setIsSubmitting(false);
            })
            .catch(err => {
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
                setIsSubmitting(false);
            });
    }

    const handleUpdate = (values: any): void => {
        setLoading(true);
        setIsSubmitting(true);
        const payload = {
            customer_id: values.customer_id  || customerInfo?.id,
            adjustment_type: values.adjustment_type,
            amount: values.amount,
            date: DateTimeUtils.formatDate(values.date, CONSTANT_COMMON.DATE_FORMAT_DATABASE),
            description: values.description,
            sales_order_no: values.sales_order_no,
            challan_no: values.challan_no,
            note: values.note,
        }
        AdjustmentApi.update(entityId, payload)
            .then(res => {
                Message.success('Adjustment update successfully.');
                handleCallbackFunc(null, 'hideForm');
                handleCallbackFunc(null, 'updateListItem', entityId, res.data);
                handleCallbackFunc(null, 'reloadView');
                setLoading(false);
                setIsSubmitting(false);
            })
            .catch(err => {
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
                setIsSubmitting(false);
            });
    }

    return (
        <div className="form-page-container form-page-container-adjustment">
            <AdjustmentFormDrawer
                loading={loading}
                rules={rules}
                isNewRecord={isNewRecord}
                itemData={itemData}
                customerInfo={customerInfo}
                modalTitle={modalTitle}
                isSubmitting={isSubmitting}
                isShowForm={isShowForm}
                formRef={formRef}
                initialValues={initialValues}
                formValues={formValues}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleSubmitFailed={handleSubmitFailed}
                handleCallbackFunc={handleCallbackFunc}
            />
        </div>
    );
}

export default React.memo(AdjustmentFormController);
