import {MathUtils} from "../../../../../utils";

class _SalesOrderCalculationUtils {

    public calculateTotalScheduleBilledQty = (salesOrderItemListData: any): number => {
        if (!salesOrderItemListData) {
            return 0;
        }

        let totalScheduleBilledQty = 0;

        salesOrderItemListData.forEach(({ schedule_billed_qty}) => {
            let scheduleBilledQty = Number(schedule_billed_qty) || 0;
            totalScheduleBilledQty += scheduleBilledQty;
        });

        return totalScheduleBilledQty;
    }

    public calculateTotalScheduleActualBilledQty = (salesOrderItemListData: any): number => {
        if (!salesOrderItemListData) {
            return 0;
        }

        let totalScheduleActualBilledQty = 0;

        salesOrderItemListData.forEach(({ schedule_actual_qty}) => {
            let scheduleActualQty = Number(schedule_actual_qty) || 0;
            totalScheduleActualBilledQty += scheduleActualQty;
        });

        return totalScheduleActualBilledQty;
    }

    public calculateTotalScheduleSoValue = (salesOrderItemListData: any): number => {
        if (!salesOrderItemListData) {
            return 0;
        }

        let totalScheduleSoValue = 0;

        salesOrderItemListData.forEach(({ schedule_billed_qty, unit_price }) => {
            let scheduleBilledQty = Number(schedule_billed_qty) || 0;
            let unitPrice = Number(unit_price) || 0;
            totalScheduleSoValue += (scheduleBilledQty * unitPrice);
        });

        return totalScheduleSoValue;
    }

    public calculateSoActualQty = (soQty: any, freeBagOfferValue: any): number => {
        let soActualQty = 0;

        if (freeBagOfferValue == 0) {
            soActualQty = soQty;
        }
        else {
            soActualQty = soQty + MathUtils.getPercent(soQty, freeBagOfferValue);
        }

        return Number(soActualQty);
    }

}

export const SalesOrderCalculationUtils = new _SalesOrderCalculationUtils();
