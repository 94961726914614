export enum LogModuleEnum {
    User = 'User',
    SalesOrder = 'Sales Order',
    DeliveryOrder = 'Delivery Order',
    Challan = 'Challan',
    Pricing = 'Pricing',
    Security = 'Security',
    Adjustment = 'Adjustment',
    Target = 'Target',
    Vehicle = 'Vehicle',
    Dealer = 'Dealer',
    Client = 'Client',
    Retailer = 'Retailer',
    Employee = 'Employee',
    Driver = 'Driver',
}
