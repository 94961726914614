export const ItemsPerPageDefault = 15

export const ItemsPerPageDefaultOptions:string[] = ['10', '20', '30', '50', '100', '250', '500'];

export const ItemsPerPageTwoColumn = 15

export const ItemsPerPageTwoColumnOptions:string[] = ['10', '20', '30', '50', '100', '250', '500'];

export const ItemsPerPageThreeColumn = 15

export const ItemsPerPageThreeColumnOptions:string[] = ['10', '20', '30', '50', '100', '250', '500'];
