import React, {useEffect, createContext, useState, useReducer} from 'react'
import io from 'socket.io-client';

import initialState from './socket.state';
import socketReducer from './socket.reducer';
import {CONSTANT_CONFIG} from "../../constants";

export const SocketContext = createContext<any>(initialState);

export const SocketProvider = ({ children }) => {
  const [state, dispatchSocket] = useReducer(socketReducer, initialState);
  const [socket, setSocket] = useState<any>(null);

  useEffect(() => {
    const ENDPOINT: string = `${CONSTANT_CONFIG.WEB_SOCKET_URL}`;
    const socketio = io(ENDPOINT);

    /* socketio.on("connect", () => {
       console.log('Socket Connected!');
     });

     socketio.on("disconnect", () => {
       setSocket(null);
       //  setTimeout(setupSocket, 3000);
       console.log('Socket Disconnected!');
     });

     socketio.on("reconnect", () => {
       console.log('Socket Reconnect');
     });

     socketio.on("error", () => {
       console.log('Socket Error');
     });*/

    setSocket(socketio);
  }, []);

  return (
    <SocketContext.Provider value={{
      ...state,
      dispatchSocket,
      socket,
    }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
