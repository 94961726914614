import { useState, useEffect, useContext } from 'react'
import {DataContext} from "../../context/data/data.context";
import {EmployeeApi} from "../../api";

export const useEmployeeContext = () => {
    const dataContext = useContext(DataContext)
    const [employeeContext, setEmployeeContext] = useState<any>([]);
    const [employeeList, setEmployeeList] = useState<any>([]);
    const [activeEmployeeList, setActiveEmployeeList] = useState<any>([]);
    const [activeTseEmployeeList, setActiveTseEmployeeList] = useState<any>([]);
    const [activeConsenterEmployeeList, setActiveConsenterEmployeeList] = useState<any>([]);
    const [activeTradeEmployeeList, setActiveTradeEmployeeList] = useState<any>([]);
    const [activeNonTradeEmployeeList, setActiveNonTradeEmployeeList] = useState<any>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<any>({});
    const [loadingEmployee, setLoadingEmployee] = useState(false);

    useEffect(() => {
        setEmployeeContext(dataContext.employeeContext);

        if (!dataContext.employeeContext.isLoaded) {
            dataContext.loadEmployee()
        }
        else {
            const items = dataContext.employeeContext.items
            loadEmployeeList(items);
            loadActiveEmployeeList(items);
            loadActiveTseEmployeeList(items);
            loadActiveConsenterEmployeeList(items);
            loadActiveTradeEmployeeList(items);
            loadActiveNonTradeEmployeeList(items);
        }

    }, [dataContext.employeeContext.isLoaded]);

    const loadEmployeeList = (list: any) => {
        setEmployeeList(list);
    }

    const loadActiveEmployeeList = (list: any) => {
        const activeList = list.filter(item => item.status === 1)
        setActiveEmployeeList(activeList);
    }

    const loadActiveTseEmployeeList = (list: any) => {
        const activeList = list.filter(item => item.status === 1 && ['Assistant Executive', 'Executive', 'Senior Executive'].includes(item.designation_name))
        setActiveTseEmployeeList(activeList);
    }

    const loadActiveConsenterEmployeeList = (list: any) => {
        const activeList = list.filter(item => item.status === 1 && ['ASM', 'RSM', 'DSM'].includes(item.designation_name))
        setActiveConsenterEmployeeList(activeList);
    }

    const loadActiveTradeEmployeeList = (list: any) => {
        const activeList = list.filter(item => item.status === 1 && item.employee_group == 'TRADE')
        setActiveTradeEmployeeList(activeList);
    }

    const loadActiveNonTradeEmployeeList = (list: any) => {
        const activeList = list.filter(item => item.status === 1 && item.employee_group == 'NONTRADE')
        setActiveNonTradeEmployeeList(activeList);
    }

    const getEmployeeById = (id) => {
        if(!employeeList) {
            return;
        }
        return employeeList.find(item => item.id === Number(id))
    }

    const loadEmployeeById = (id): Promise<any> => {
        return new Promise((resolve, reject) => {
            setLoadingEmployee(true);
            EmployeeApi.getById(id)
                .then(res => {
                    setSelectedEmployee(res.data);
                    setLoadingEmployee(false);
                    resolve(res.data);
                })
                .catch(err => {
                    setSelectedEmployee({});
                    setLoadingEmployee(false);
                    reject(err);
                });
        });
    }

    const setEmployeeFieldValue = (formRef, key, value) => {
        if (activeEmployeeList?.find(item => item.id === Number(value))) {
            formRef.setFieldsValue({[key]: value})
        }
        else {
            formRef.setFieldsValue({[key]: null})
        }
    }

    return { employeeContext, loadingEmployee, selectedEmployee, employeeList, activeEmployeeList, activeTseEmployeeList, activeConsenterEmployeeList, activeTradeEmployeeList, activeNonTradeEmployeeList, setEmployeeFieldValue, loadEmployeeById, getEmployeeById }
}