import { useState, useEffect, useContext } from 'react'
import {DataContext} from "../../context/data/data.context";
import {RetailerApi} from "../../api";

export const useRetailerContext = () => {
    const dataContext = useContext(DataContext)
    const [retailerContext, setRetailerContext] = useState<any>([]);
    const [retailerList, setRetailerList] = useState<any>([]);
    const [activeRetailerList, setActiveRetailerList] = useState<any>([]);

    const [selectedRetailer, setSelectedRetailer] = useState<any>({});
    const [loadingRetailer, setLoadingRetailer] = useState(false);

    const [fetchedRetailerInfo, setFetchedRetailerInfo] = useState<any>({});
    const [fetchedRetailerList, setFetchedRetailerList] = useState<any>([]);
    const [isRetailerLoading, setIsRetailerLoading] = useState(false);

    useEffect(() => {
        setRetailerContext(dataContext.retailerContext);

        if (!dataContext.retailerContext.isLoaded) {
            dataContext.loadRetailer()
        }
        else {
            const items = dataContext.retailerContext.items
            prepareRetailerList(items);
            prepareActiveRetailerList(items);
        }
    }, [dataContext.retailerContext.isLoaded]);

    const prepareRetailerList = (list: any) => {
        setRetailerList(list);
    }

    const prepareActiveRetailerList = (list: any) => {
        const activeList = list.filter(item => item.status === 1)
        setActiveRetailerList(activeList);
    }

    const getRetailerById = (id) => {
        if(!retailerList) {
            return;
        }
        return retailerList.find(item => item.id === id)
    }

    const loadRetailer = (payload = {}): Promise<any> => {
        return new Promise((resolve, reject) => {
            setIsRetailerLoading(true);
            RetailerApi.list(payload)
                .then(res => {
                    setFetchedRetailerList(res.data.results);
                    setIsRetailerLoading(false);
                    resolve(res);
                })
                .catch(err => {
                    setFetchedRetailerList([]);
                    setIsRetailerLoading(false);
                    reject(err);
                });
        });
    }

    const loadRetailerById = (id): Promise<any> => {
        return new Promise((resolve, reject) => {
            setIsRetailerLoading(true);
            RetailerApi.getById(id)
                .then(res => {
                    setFetchedRetailerInfo(res.data);
                    setIsRetailerLoading(false);
                    resolve(res);
                })
                .catch(err => {
                    setFetchedRetailerInfo({});
                    setIsRetailerLoading(false);
                    reject(err);
                });
        });
    }

    const getFetchedAddressById = (id) => {
        if(!fetchedRetailerList) {
            return;
        }
        return fetchedRetailerList.find(item => item.id === id)
    }

    const setRetailerFieldValue = (formRef, key, value) => {
        if (activeRetailerList?.find(item => item.id === Number(value))) {
            formRef.setFieldsValue({[key]: value})
        }
        else {
            formRef.setFieldsValue({[key]: null})
        }
    }

    return {
        retailerContext,
        isRetailerLoading,
        fetchedRetailerInfo,
        fetchedRetailerList,
        retailerList,
        activeRetailerList,

        setRetailerFieldValue,
        getRetailerById,
        getFetchedAddressById,
        loadRetailer,
        loadRetailerById,
    }
}