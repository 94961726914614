import initialState from './data.state';
import {
  UPDATE_USER_CONTEXT,
  UPDATE_ROLE_CONTEXT,
  UPDATE_EMPLOYEE_CONTEXT,
  UPDATE_CUSTOMER_CONTEXT,
  UPDATE_CLIENT_CONTEXT,
  UPDATE_DEALER_CONTEXT,
  UPDATE_RETAILER_CONTEXT,
  UPDATE_PROJECT_CONTEXT,
  UPDATE_DRIVER_CONTEXT,
  UPDATE_VEHICLE_CONTEXT,
  UPDATE_SECURITY_TYPE_CONTEXT,
  UPDATE_ADJUSTMENT_TYPE_CONTEXT,
  UPDATE_BAG_TYPE_CONTEXT,
  UPDATE_PAYMENT_MOD_CONTEXT,
  UPDATE_BANK_LIST_CONTEXT,
  UPDATE_DIVISION_CONTEXT,
  UPDATE_DISTRICT_CONTEXT,
  UPDATE_THANA_CONTEXT,
  UPDATE_DEPARTMENT_CONTEXT,
  UPDATE_DESIGNATION_CONTEXT,
  UPDATE_PRODUCT_CONTEXT,
  UPDATE_WAREHOUSE_CONTEXT,
  UPDATE_ADDRESS_CONTEXT,
  CLEAR_ALL_DATA_STATE,
} from './data.types';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_USER_CONTEXT:
      return {
        ...state,
        userContext: {
          ...state.userContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_ROLE_CONTEXT:
      return {
        ...state,
        roleContext: {
          ...state.roleContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_EMPLOYEE_CONTEXT:
      return {
        ...state,
        employeeContext: {
          ...state.employeeContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_CUSTOMER_CONTEXT:
      return {
        ...state,
        customerContext: {
          ...state.customerContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_CLIENT_CONTEXT:
      return {
        ...state,
        clientContext: {
          ...state.clientContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_DEALER_CONTEXT:
      return {
        ...state,
        dealerContext: {
          ...state.dealerContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_RETAILER_CONTEXT:
      return {
        ...state,
        retailerContext: {
          ...state.retailerContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_PROJECT_CONTEXT:
      return {
        ...state,
        projectContext: {
          ...state.projectContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_DRIVER_CONTEXT:
      return {
        ...state,
        driverContext: {
          ...state.driverContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_VEHICLE_CONTEXT:
      return {
        ...state,
        vehicleContext: {
          ...state.vehicleContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_SECURITY_TYPE_CONTEXT:
      return {
        ...state,
        securityTypeContext: {
          ...state.securityTypeContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_ADJUSTMENT_TYPE_CONTEXT:
      return {
        ...state,
        adjustmentTypeContext: {
          ...state.adjustmentTypeContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_BAG_TYPE_CONTEXT:
      return {
        ...state,
        bagTypeContext: {
          ...state.bagTypeContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_PAYMENT_MOD_CONTEXT:
      return {
        ...state,
        paymentModContext: {
          ...state.paymentModContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_BANK_LIST_CONTEXT:
      return {
        ...state,
        bankListContext: {
          ...state.bankListContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_DIVISION_CONTEXT:
      return {
        ...state,
        divisionContext: {
          ...state.divisionContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_DISTRICT_CONTEXT:
      return {
        ...state,
        districtContext: {
          ...state.districtContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_THANA_CONTEXT:
      return {
        ...state,
        thanaContext: {
          ...state.thanaContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_PRODUCT_CONTEXT:
      return {
        ...state,
        productContext: {
          ...state.productContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_DEPARTMENT_CONTEXT:
      return {
        ...state,
        departmentContext: {
          ...state.departmentContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_DESIGNATION_CONTEXT:
      return {
        ...state,
        designationContext: {
          ...state.designationContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_WAREHOUSE_CONTEXT:
      return {
        ...state,
        warehouseContext: {
          ...state.warehouseContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_ADDRESS_CONTEXT:
      return {
        ...state,
        addressContext: {
          ...state.addressContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case CLEAR_ALL_DATA_STATE:
      return {
        ...state,
        userContext: initialState.userContext,
        roleContext: initialState.roleContext,
        employeeContext: initialState.employeeContext,
        dealerContext: initialState.dealerContext,
        retailerContext: initialState.retailerContext,
        projectContext: initialState.projectContext,
        driverContext: initialState.driverContext,
        divisionContext: initialState.divisionContext,
        districtContext: initialState.districtContext,
        thanaContext: initialState.thanaContext,
        departmentContext: initialState.departmentContext,
        designationContext: initialState.designationContext,
        productContext: initialState.productContext,
        warehouseContext: initialState.warehouseContext,
        vehicleContext: initialState.vehicleContext,
        securityTypeContext: initialState.securityTypeContext,
        adjustmentTypeContext: initialState.adjustmentTypeContext,
        bagTypeContext: initialState.bagTypeContext,
        paymentModContext: initialState.paymentModContext,
        bankListContext: initialState.bankListContext,
      };

    default:
      return state;
  }
};