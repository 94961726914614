import React, {FC, Fragment} from 'react'
import {Form} from 'antd';
import SalesOrderItemCreateTradeBagForm from "./TradeBag/SalesOrderItemCreateTradeBag.form";
import SalesOrderItemCreateNonTradeBagForm from "./NonTradeBag/SalesOrderItemCreateNonTradeBag.form";
import SalesOrderItemCreateNonTradeToneForm from "./NonTradeTone/SalesOrderItemCreateNonTradeTone.form";

interface IProps {
    scheduleFormRef: any,
    [key: string]: any,
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const AddOrEditForm: FC<IProps> = props => {
    const { scheduleFormRef, deliveryScheduleFormType, customerInfo, initialValues, handleChange, handleSubmit, handleSubmitFailed,  ...restProps } = props;

    return (
        <Fragment>
            <div className="form-page-content form-page-content-sales-order-item">
                <Form
                    {...formItemLayout}
                    form={scheduleFormRef}
                    name="salesOrderItemForm"
                    scrollToFirstError={true}
                    initialValues={initialValues}
                    onValuesChange={handleChange}
                    onFinish={handleSubmit}
                    onFinishFailed={handleSubmitFailed}
                >
                    {deliveryScheduleFormType == 'TradeBag' && (
                        <SalesOrderItemCreateTradeBagForm
                            {...restProps}
                            scheduleFormRef={scheduleFormRef}
                            customerInfo={customerInfo}
                            deliveryScheduleFormType={deliveryScheduleFormType}
                            initialValues={initialValues}
                            handleChange={handleChange}
                        />
                    )}

                    {deliveryScheduleFormType == 'NonTradeBag' && (
                        <SalesOrderItemCreateNonTradeBagForm
                            {...restProps}
                            scheduleFormRef={scheduleFormRef}
                            customerInfo={customerInfo}
                            deliveryScheduleFormType={deliveryScheduleFormType}
                            initialValues={initialValues}
                            handleChange={handleChange}
                        />
                    )}

                    {deliveryScheduleFormType == 'NonTradeTone' && (
                        <SalesOrderItemCreateNonTradeToneForm
                            {...restProps}
                            scheduleFormRef={scheduleFormRef}
                            customerInfo={customerInfo}
                            deliveryScheduleFormType={deliveryScheduleFormType}
                            initialValues={initialValues}
                            handleChange={handleChange}
                        />
                    )}
                </Form>
            </div>
        </Fragment>
    );
}
export default React.memo(AddOrEditForm);