export const getToDoListSidebarMenu = (countSalesOrderBadge, countDeliveryOrderBadge, countChalanBadge, countCollectionBadge, countCreditLimitBadge, countRetailerBadge) => {
    return [
        {
            type: 'group',
            title: 'Sales Order',
            icon: 'calendar-outline',
            class: '',
            expanded: true,
            hidden: false,
            children: [
                {
                    type: 'item',
                    title: 'Sales Order',
                    permission: 'scm:salesOrder:list',
                    link: { to: '/workspace/sales-order', exactMatch: true, externalUrl: false, openInNewTab: false },
                    icon: '',
                    class: '',
                    badge: countSalesOrderBadge,
                    badgeClass: '',
                    hidden: false,
                },
            ],
        },
        {
            type: 'group',
            title: 'Delivery Order',
            icon: 'calendar-outline',
            class: '',
            expanded: true,
            hidden: false,
            children: [
                {
                    type: 'item',
                    title: 'Do List',
                    permission: 'scm:deliveryOrder:list',
                    link: { to: '/workspace/delivery-order', exactMatch: true, externalUrl: false, openInNewTab: false },
                    icon: '',
                    class: '',
                    badge: countDeliveryOrderBadge,
                    badgeClass: '',
                    hidden: false,
                },
            ],
        },
        {
            type: 'group',
            title: 'Chalan',
            icon: 'calendar-outline',
            class: '',
            expanded: true,
            hidden: false,
            children: [
                {
                    type: 'item',
                    title: 'Chalan List',
                    permission: 'scm:challan:list',
                    link: { to: '/workspace/challan', exactMatch: true, externalUrl: false, openInNewTab: false },
                    icon: '',
                    class: '',
                    badge: countChalanBadge,
                    badgeClass: '',
                    hidden: false,
                },
            ],
        },
        {
            type: 'group',
            title: 'Transfer Order',
            icon: 'calendar-outline',
            class: '',
            expanded: true,
            hidden: false,
            children: [
                {
                    type: 'item',
                    title: 'TO Request',
                    permission: 'scm:toRequest:list',
                    link: { to: '/workspace/to-request', exactMatch: true, externalUrl: false, openInNewTab: false },
                    icon: '',
                    class: '',
                    badge: '',
                    badgeClass: '',
                    hidden: false,
                },
                {
                    type: 'item',
                    title: 'TO List',
                    permission: 'scm:to:list',
                    link: { to: '/workspace/to', exactMatch: true, externalUrl: false, openInNewTab: false },
                    icon: '',
                    class: '',
                    badge: '',
                    badgeClass: '',
                    hidden: false,
                },
            ],
        },
        {
            type: 'group',
            title: 'Collection',
            icon: 'calendar-outline',
            class: '',
            expanded: true,
            hidden: false,
            children: [
                {
                    type: 'item',
                    title: 'Collection List',
                    permission: 'scm:collection:list',
                    link: { to: '/workspace/collection', exactMatch: true, externalUrl: false, openInNewTab: false },
                    icon: '',
                    class: '',
                    badge: countCollectionBadge,
                    badgeClass: '',
                    hidden: false,
                },
            ],
        },
        {
            type: 'group',
            title: 'Credit Limit',
            icon: 'calendar-outline',
            class: '',
            expanded: true,
            hidden: false,
            children: [
                {
                    type: 'item',
                    title: 'Credit Limit List',
                    permission: 'scm:creditLimit:list',
                    link: { to: '/workspace/credit-limit', exactMatch: true, externalUrl: false, openInNewTab: false },
                    icon: '',
                    class: '',
                    badge: countCreditLimitBadge,
                    badgeClass: '',
                    hidden: false,
                },
            ],
        },
        {
            type: 'group',
            title: 'Retailer',
            icon: 'calendar-outline',
            class: '',
            expanded: true,
            hidden: false,
            children: [
                {
                    type: 'item',
                    title: 'Retailer List',
                    permission: 'scm:retailer:list',
                    link: { to: '/workspace/retailer', exactMatch: true, externalUrl: false, openInNewTab: false },
                    icon: '',
                    class: '',
                    badge: countRetailerBadge,
                    badgeClass: '',
                    hidden: false,
                },
            ],
        },
    ];
}



