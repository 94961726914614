import React from "react";

export const HeaderTabMenu = [
    {
        tabGroup: 'sales-order',
        title: 'Sales Order',
        link: { to: '/sales-order', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        tabGroup: 'sales-order',
        title: 'Special Sales Order',
        link: { to: '/sales-order', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        tabGroup: 'send-message',
        title: 'Send Sms',
        link: { to: '/setting/communication/send-message/sms', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        tabGroup: 'send-message',
        title: 'Send Push Notification',
        link: { to: '/setting/communication/send-message/push', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        tabGroup: 'send-message',
        title: 'Send Email',
        link: { to: '/setting/communication/send-message/email', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        tabGroup: 'message-template',
        title: 'SMS Template',
        link: { to: '/setting/communication/template/sms', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        tabGroup: 'message-template',
        title: 'Push Template',
        link: { to: '/setting/communication/template/push', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        tabGroup: 'message-template',
        title: 'Email Template',
        link: { to: '/setting/communication/template/email', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        tabGroup: 'location',
        title: 'Division',
        link: { to: '/setting/location/division', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        tabGroup: 'location',
        title: 'District',
        link: { to: '/setting/location/district', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        tabGroup: 'location',
        title: 'Thana',
        link: { to: '/setting/location/thana', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
];
