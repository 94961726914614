import {
  SET_LOADING,
  SET_ERROR,
  LOADED_USER,
  LOADED_PROFILE,
  LOADED_GROUP,
  LOADED_ROLE,
  LOADED_WORKSPACE,
  LOADED_ORGANIZATION,
  LOADED_SCOPE,
  LOADED_WORKFLOW,
  CLEAR_ALL_AUTH_STATE,
  SHOW_ALERT,
  HIDE_ALERT,
  LOADED_AUTH_STATE,
  CLEAR_AUTH_STATE,
  LOADED_TOKEN,
  REMOVE_TOKEN,
} from './auth.types';
import {StorageService} from "../../services";

export default (state, action) => {
  switch (action.type) {
    case LOADED_AUTH_STATE:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        userId: action.payload.userId,
        profileId: action.payload.profileId,
        groupId: action.payload.groupId,
        roleId: action.payload.roleId,
        workspaceId: action.payload.workspaceId,
        organizationId: action.payload.organizationId,
        profileType: action.payload.profileType,
        profileImage: action.payload.profileImage,
        loading: false,
        error: false,
      };

    case CLEAR_AUTH_STATE:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        userId: null,
        scopes: null,
        loading: false,
        error: null,
      };

    case CLEAR_ALL_AUTH_STATE:
      return {
        ...state,
        user: null,
        userId: null,
        profile: null,
        profileType: null,
        profileId: null,
        profileImage: null,
        profileCode: null,
        profileDesignation: null,
        scopes: null,
        userGroup: null,
        group: null,
        groupId: null,
        userRole: null,
        role: null,
        roleId: null,
        workspace: null,
        workspaceId: null,
        organization: null,
        organizationId: null,
        loading: false,
        error: null,
      };

    case LOADED_USER:
      return {
        ...state,
        user: action.payload.user,
        userId: action.payload.userId,
        profileType: action.payload.profileType,
        profileId: action.payload.profileId,
        profileImage: action.payload.profileImage,
      };

    case LOADED_PROFILE:
      return {
        ...state,
        profile: action.payload.profile,
        profileCode: action.payload.profileCode,
        profileDesignation: action.payload.profileDesignation,
      };

    case LOADED_SCOPE:
      return {
        ...state,
        scopes: action.payload.scopes,
      };

    case LOADED_GROUP:
      return {
        ...state,
        userGroup: action.payload.userGroup,
        groupId: action.payload.groupId,
        group: action.payload.group,
      };

    case LOADED_ROLE:
      return {
        ...state,
        userRole: action.payload.userRole,
        roleId: action.payload.roleId,
        role: action.payload.role,
      };

    case LOADED_WORKSPACE:
      return {
        ...state,
        workspaceId: action.payload.workspaceId,
        workspace: action.payload.workspace,
      };

    case LOADED_ORGANIZATION:
      return {
        ...state,
        organizationId: action.payload.organizationId,
        organization: action.payload.organization,
        loading: false,
        error: null,
      };

    case LOADED_TOKEN:
      StorageService.setAccessToken(action.payload.accessToken);
      StorageService.setRefreshToken(action.payload.refreshToken);
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };

    case LOADED_WORKFLOW:
      return {
        ...state,
        userWorkflowSteps: action.payload.userWorkflowSteps,
      };

    case REMOVE_TOKEN:
      StorageService.removeAccessToken();
      StorageService.removeRefreshToken();
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case SHOW_ALERT:
      return {
        ...state,
        alertText: action.payload.alertText,
        alertType: action.payload.alertType,
        alertVisible: true,
      };

    case HIDE_ALERT:
      return {
        ...state,
        alertText: '',
        alertType: '',
        alertVisible: false,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    default:
      return state;
  }
};