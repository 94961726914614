import React from "react";
import { DesktopOutlined, DashboardOutlined, UserOutlined, BarChartOutlined, SettingOutlined, DollarOutlined, ShoppingCartOutlined, FileTextOutlined, CarOutlined, MoneyCollectOutlined } from '@ant-design/icons';

export const LeftSidebarMenu = [
    {
        type: 'item',
        title: 'Dashboard',
        permission: '',
        link: { to: '/dashboard', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <DashboardOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Workspace',
        permission: '',
        link: { to: '/workspace', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <DesktopOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Sales Order',
        permission: 'scm:salesOrder:list',
        link: { to: '/sales-order', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <ShoppingCartOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Delivery Order',
        permission: 'scm:deliveryOrder:list',
        link: { to: '/delivery-order', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <CarOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Challan',
        permission: 'scm:challan:list',
        link: { to: '/challan', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <FileTextOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Collection',
        permission: 'scm:collection:list',
        link: { to: '/collection', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <DollarOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Dealer',
        permission: 'scm:dealer:list',
        link: { to: '/dealer', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <UserOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Report',
        permission: '',
        link: { to: '/report', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <BarChartOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Setting',
        permission: 'core:option:setting:navItem',
        link: { to: '/setting', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <SettingOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
];
