import React from "react";
import { node } from "prop-types";

import SocketProvider from "./socket/socket.context";
import AuthProvider from "./auth/auth.context";
import SettingProvider from "./setting/setting.context";
import DataProvider from "./data/data.context";
import CountProvider from "./count/count.context";

import { I18nextProvider } from 'react-i18next'
import i18n from './../i18n/i18n'

const ContextProvider = ({ children }) => {
    return (
        <I18nextProvider i18n={i18n}>
            <SocketProvider>
                <AuthProvider>
                    <SettingProvider>
                        <DataProvider>
                            <CountProvider>
                                {children}
                            </CountProvider>
                        </DataProvider>
                    </SettingProvider>
                </AuthProvider>
            </SocketProvider>
        </I18nextProvider>
    );
};

ContextProvider.propTypes = {
    children: node.isRequired
};

export default ContextProvider;