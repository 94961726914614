import React, {FC, useState} from 'react'
import PricingContentCustomer from "../../../../Scm/components/Pricing/Content/PricingContent.customer";

const SalesOrderCreateTabPricing: FC<any> = props => {
    const {customerId, salesOrderId, parentSalesOrderId} = props;
    const [reloadTab, setReloadTab] = useState(Date.now());

    const reloadPricingTab = (res?: any) => {
        setReloadTab(Date.now());
    };

    return (
        <div className="dealer-info-tabs-content">
            <PricingContentCustomer customerId={customerId} salesOrderId={salesOrderId} parentSalesOrderId={parentSalesOrderId} reloadTab={reloadTab} />
        </div>
    );
}
export default React.memo(SalesOrderCreateTabPricing);