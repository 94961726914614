import React from 'react';
import { Link } from 'react-router-dom';
import { Divider} from 'antd';

const ProfileMenu = (user, profileCode, profileDesignation, isPermissionLoaded, hasPermission) => (
    <div className="profile-menu-list">
        <div className="profile-menu-item">
            <h3>{user?.name}</h3>
            <p>{user?.email}</p>
            {profileDesignation && (
                <p>Designation: {profileDesignation}</p>
            )}
            {profileCode && (
                <p>Employee ID: {profileCode}</p>
            )}
            <Divider />
        </div>

        {isPermissionLoaded && hasPermission('auth:user:myAccount') && (
            <div className="profile-menu-item">
                <Link to={'/setting/my-account/my-profile'}>My Account</Link>
            </div>
        )}

        {isPermissionLoaded && hasPermission('auth:oauth:changePassword') && (
            <div className="profile-menu-item">
                <Link to={'/setting/my-account/change-password'}>Change Password</Link>
            </div>
        )}

        {isPermissionLoaded && hasPermission('core:option:setting:navItem') && (
            <div className="profile-menu-item">
                <Link to={'/setting'}>Settings</Link>
            </div>
        )}

        <div className="profile-menu-item">
            <Link to={'/auth/logout'}>Logout</Link>
        </div>
    </div>
);

export default ProfileMenu;
