import React, { FC, Fragment } from 'react'
import { Popover, Badge } from 'antd';
import NotificationDropdownContent from "./Notification.dropdown.content";
import images from "../../../../assets/images";

interface IProps {
    loading: any,
    visible: any,
    countBadge: any,
    listData: any,
    handleScroll: any,
    handleVisibleChange: any,
    [key: string]: any,
}

const NotificationDropdown: FC<IProps> = props => {
    const { visible, loading, listData, countBadge, handleVisibleChange, handleScroll, ...restProps } = props;
    return (
        <Fragment>
            <Popover
                placement="bottomRight"
                content={<NotificationDropdownContent handleScroll={handleScroll} listData={listData} loading={loading} countBadge={countBadge} {...restProps} />}
                overlayClassName="top-bar-notification"
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
            >
                <span className="notification-icon">
                    <Badge
                        count={countBadge}
                        style={{ boxShadow: 'none' }}
                        className="notification-badge"
                        overflowCount={9}
                    >
                        <img  src={images.icons.notificationIcon} alt="notificationIcon" />
                    </Badge>
                </span>
            </Popover>
        </Fragment>
    );
}
export default React.memo(NotificationDropdown);