import React, {createContext, useReducer, useEffect, useContext} from 'react'

import initialState from './data.state';
import dataReducer from './data.reducer';
import {
  CustomerApi,
  DealerApi,
  DepartmentApi,
  DesignationApi,
  DriverApi,
  EmployeeApi,
  LocationApi,
  LookupApi, ClientApi,
  ProductApi,
  RetailerApi, RoleApi,
  UserApi,
  VehicleApi,
  WarehouseApi,
  AddressApi,
  ProjectApi
} from "../../api";
import {
  UPDATE_USER_CONTEXT,
  UPDATE_ROLE_CONTEXT,
  UPDATE_EMPLOYEE_CONTEXT,
  UPDATE_CUSTOMER_CONTEXT,
  UPDATE_CLIENT_CONTEXT,
  UPDATE_DEALER_CONTEXT,
  UPDATE_RETAILER_CONTEXT,
  UPDATE_PROJECT_CONTEXT,
  UPDATE_DRIVER_CONTEXT,
  UPDATE_VEHICLE_CONTEXT,
  UPDATE_SECURITY_TYPE_CONTEXT,
  UPDATE_ADJUSTMENT_TYPE_CONTEXT,
  UPDATE_BAG_TYPE_CONTEXT,
  UPDATE_PAYMENT_MOD_CONTEXT,
  UPDATE_BANK_LIST_CONTEXT,
  UPDATE_DIVISION_CONTEXT,
  UPDATE_DISTRICT_CONTEXT,
  UPDATE_THANA_CONTEXT,
  UPDATE_DEPARTMENT_CONTEXT,
  UPDATE_DESIGNATION_CONTEXT,
  UPDATE_PRODUCT_CONTEXT,
  UPDATE_WAREHOUSE_CONTEXT,
  UPDATE_ADDRESS_CONTEXT,
  CLEAR_ALL_DATA_STATE
} from './data.types';
import {LocationTypeEnum} from "../../utils/enums";
import {AuthContext} from "../auth/auth.context";

export const DataContext = createContext<any>(initialState);

export const DataProvider = ({ children }) => {
  const { scopes } = useContext(AuthContext);
  const [state, dispatchData] = useReducer(dataReducer, initialState);

  useEffect(() => {
    if (scopes == null) {
      clearDataState();
    }
    else if (scopes.length) {
      if (!state.employeeContext.isLoaded) {
        loadEmployee();
      }

      if (!state.customerContext.isLoaded) {
        loadCustomer();
      }

      if (!state.clientContext.isLoaded) {
        loadClient();
      }

      if (!state.dealerContext.isLoaded) {
        loadDealer();
      }

      if (!state.userContext.isLoaded) {
        loadUser();
      }

      if (!state.roleContext.isLoaded) {
        loadRole();
      }

      if (!state.retailerContext.isLoaded) {
        loadRetailer();
      }

      if (!state.projectContext.isLoaded) {
        loadProject();
      }

      if (!state.driverContext.isLoaded) {
        loadDriver();
      }

      if (!state.vehicleContext.isLoaded) {
        loadVehicle();
      }

      if (!state.securityTypeContext.isLoaded) {
        loadSecurityType();
      }

      if (!state.adjustmentTypeContext.isLoaded) {
        loadAdjustmentType();
      }

      if (!state.bagTypeContext.isLoaded) {
        loadBagType();
      }

      if (!state.paymentModContext.isLoaded) {
        loadPaymentMod();
      }

      if (!state.bankListContext.isLoaded) {
        loadBankList();
      }

      if (!state.divisionContext.isLoaded) {
        loadDivision();
      }

      if (!state.districtContext.isLoaded) {
        loadDistrict();
      }

      if (!state.thanaContext.isLoaded) {
        loadThana();
      }

      if (!state.productContext.isLoaded) {
        loadProduct();
      }

      if (!state.departmentContext.isLoaded) {
        loadDepartment();
      }

      if (!state.designationContext.isLoaded) {
        loadDesignation();
      }

      if (!state.warehouseContext.isLoaded) {
        loadWarehouse();
      }
    }
  }, [scopes]);

  const loadUser = async () => {
    const payload = {
      '$select': 'id,first_name,last_name,role_id,profile_id,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'first_name asc',
    }
    UserApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_USER_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadRole = async () => {
    const payload = {
      '$select': 'id,name,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'name asc',
    }
    RoleApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_ROLE_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadEmployee = async () => {
    const payload = {
      '$select': 'id,employee_group,designation_id,first_name,last_name,code,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'first_name asc',
    }
    EmployeeApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_EMPLOYEE_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadCustomer = async () => {
    const payload = {
      '$select': 'id,employee_id,customer_group,customer_type,credit_type,client_segment,org_name,code,division_id,district_id,thana_id,status',
      '$filter': "customer_type IN('CLIENT', 'DEALER')",
      '$orderby': 'org_name asc',
    }
    CustomerApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_CUSTOMER_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadClient = async () => {
    const payload = {
      '$select': 'id,employee_id,first_name,last_name,org_name,code,image,contact_number,address,division_id,district_id,thana_id,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'org_name asc',
    }
    ClientApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_CLIENT_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadDealer = async () => {
    const payload = {
      '$select': 'id,employee_id,first_name,last_name,org_name,code,image,contact_number,address,division_id,district_id,thana_id,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'org_name asc',
    }
    DealerApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_DEALER_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadRetailer = async () => {
    const payload = {
      '$select': 'id,customer_id,first_name,last_name,org_name,contact_number,address,division_id,district_id,thana_id,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'org_name asc',
    }
    RetailerApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_RETAILER_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadProject = async () => {
    const payload = {
      '$select': 'id,customer_id,first_name,last_name,org_name,contact_number,address,division_id,district_id,thana_id,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'org_name asc',
    }
    ProjectApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_PROJECT_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadDriver = async () => {
    const payload = {
      '$select': 'id,first_name,last_name,personal_contact_number,official_contact_number,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'first_name asc',
    }
    DriverApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_DRIVER_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadVehicle = async () => {
    const payload = {
      '$select': 'id,vehicle_number,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'vehicle_number asc',
    }
    VehicleApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_VEHICLE_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadSecurityType = async () => {
    const payload = {
      '$select': 'id,key,value,is_default,status',
      '$filter': "type='SECURITY_TYPE'",
      '$orderby': 'value asc',
    }
    LookupApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_SECURITY_TYPE_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadAdjustmentType = async () => {
    const payload = {
      '$select': 'id,key,value,is_default,status',
      '$filter': "type='ADJUSTMENT_TYPE'",
      '$orderby': 'value asc',
    }
    LookupApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_ADJUSTMENT_TYPE_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadBagType = async () => {
    const payload = {
      '$select': 'id,key,value,is_default,status',
      '$filter': "type='BAG_TYPE'",
      '$orderby': 'value asc',
    }
    LookupApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_BAG_TYPE_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadPaymentMod = async () => {
    const payload = {
      '$select': 'id,key,value,is_default,status',
      '$filter': "type='PAYMENT_MODE'",
      '$orderby': 'value asc',
    }
    LookupApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_PAYMENT_MOD_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadBankList = async () => {
    const payload = {
      '$select': 'id,key,value,is_default,status',
      '$filter': "type='BANK_LIST'",
      '$orderby': 'value asc',
    }
    LookupApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_BANK_LIST_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadDivision = async () => {
    const payload = {
      '$select': 'id,name,status',
      '$filter': "location_type_id='" + LocationTypeEnum.DIVISION + "'",
      '$orderby': 'name asc',
    }
    LocationApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_DIVISION_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadDistrict = async () => {
    const payload = {
      '$select': 'id,name,division_id,parent_location_id,location_type_id,status',
      '$filter': "location_type_id='" + LocationTypeEnum.DISTRICT + "'",
      '$orderby': 'name asc',
    }
    LocationApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_DISTRICT_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadThana = async () => {
    const payload = {
      '$select': 'id,name,division_id,district_id,parent_location_id,location_type_id,status',
      '$filter': "location_type_id='" + LocationTypeEnum.THANA + "'",
      '$orderby': 'name asc',
    }
    LocationApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_THANA_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadProduct = async () => {
    const payload = {
      '$select': 'id,name,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'name asc',
    }
    ProductApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_PRODUCT_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadDepartment = async () => {
    const payload = {
      '$select': 'id,parent_id,title,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'title asc',
    }
    DepartmentApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_DEPARTMENT_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadDesignation = async () => {
    const payload = {
      '$select': 'id,parent_id,name,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'name asc',
    }
    DesignationApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_DESIGNATION_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadWarehouse = async () => {
    const payload = {
      '$select': 'id,name,status',
     /* '$filter': 'status=1',*/
      '$orderby': 'name asc',
    }
    WarehouseApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_WAREHOUSE_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadAddress = async (payload = {}) => {
    const defaultPayload = {
      '$select': 'id,customer_id,address_type,contact_number,delivery_location_contact_number,address,status',
      '$filter': 'status=1',
      '$orderby': 'address asc',
    }
    AddressApi.list({...defaultPayload, ...payload})
        .then(res => {
          dispatchData({
            type: UPDATE_ADDRESS_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const clearDataState = () => {
    dispatchData({type: CLEAR_ALL_DATA_STATE});
  }

  return (
    <DataContext.Provider value={{
      ...state,
      loadUser,
      loadRole,
      loadEmployee,
      loadCustomer,
      loadClient,
      loadDealer,
      loadRetailer,
      loadProject,
      loadDriver,
      loadVehicle,
      loadSecurityType,
      loadAdjustmentType,
      loadBagType,
      loadPaymentMod,
      loadBankList,
      loadDivision,
      loadDistrict,
      loadThana,
      loadProduct,
      loadDepartment,
      loadDesignation,
      loadWarehouse,
      loadAddress,
    }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
