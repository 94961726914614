export default class FileService {

    public uploadImage(image: File) {

    }

    public uploadFile(formData) {

    }

    public getImage() {

    }

    public getFile() {

    }

    public downloadFile(url: string, filename: string = 'download.xlsx') {
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = filename
        a.click()
    }

    public deleteImage(formData) {

    }

    public deleteFile(formData) {

    }

    public getBaseName = (path: string) => {
        return path.substring(path.lastIndexOf('/') + 1, path.length);
    }

    public getUriBaseName = (uri: string) => {
        return this.getBaseName(decodeURIComponent(uri));
    }

    public findImageType(image): string {
        let type: string = ''
        if (image.uri.indexOf('.jpg') > -1) {
            type = '.jpg'
        } else if (image.uri.indexOf('.png') > -1) {
            type = '.png'
        } else if (image.uri.indexOf('.jpeg') > -1) {
            type = '.jpeg'
        }
        return type
    }

    public openNewTab(url: string) {
        window.open(url)
    }

    private getFileSuffix(file: File): string {
        if (file.name) {
            const lastDotIndex = file.name.lastIndexOf('.')
            return file.name.slice(lastDotIndex)
        } else {
            return '.' + file.type.split('/')[1]
        }
    }
    private getKey(file: File): string {
        const suffix = this.getFileSuffix(file)
        let fileName = ''
        if (file.name) {
            fileName = file.name.replace(/[?\s%<>&#\\:]/g, '').replace(suffix, '')
        }
        if (suffix === '.xlsx' || suffix === '.xls' || suffix === '.csv') {
            return `import__${fileName}___${Math.random()
                .toString(16)
                .slice(3)}___${suffix}`
        }
        return `${fileName}___${Math.random()
            .toString(16)
            .slice(3)}___${suffix}`
    }

}
