export const rules = {
    employee_id: [
        { required: true, message: 'This field is required.' }
    ],
    customer_id: [
        { required: true, message: 'This field is required.' }
    ],
    amount: [
        { required: true, message: 'This field is required.' }
    ],
    date: [
        { required: true, message: 'This field is required.' }
    ],
    bank_name: [
        { required: true, message: 'This field is required.' }
    ],
    branch_name: [
        { required: true, message: 'This field is required.' }
    ],
    payment_mode: [
        { required: true, message: 'This field is required.' }
    ],
};