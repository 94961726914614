import React from 'react';
import {Button} from 'antd';
import { PlusOutlined} from '@ant-design/icons';
import CreateAction from "../../Actions/CreateAction";
import {CollectionAction} from "../../../modules/Order/components/Collection/Actions/Collection.actions";
import {AdjustmentAction} from "../../../modules/Scm/components/Adjustment/Actions/Adjustment.actions";
import {SalesOrderAction} from "../../../modules/Order/components/SalesOrder/Actions/SalesOrder.actions";

const QuickAddLink = () => (
    <div className="quick-add-actions-list">
        <CreateAction manageUrl={false} component={SalesOrderAction.COMMON_ACTION.CREATE.component} actionItem={SalesOrderAction.COMMON_ACTION.CREATE}>
            <Button className='quick-add-action' type="text" icon={<PlusOutlined />} size="small">Sales Order</Button>
        </CreateAction>
        <CreateAction manageUrl={false} component={CollectionAction.COMMON_ACTION.CREATE.component} actionItem={CollectionAction.COMMON_ACTION.CREATE}>
            <Button className='quick-add-action' type="text" icon={<PlusOutlined />} size="small">Collection</Button>
        </CreateAction>
        <CreateAction manageUrl={false} component={AdjustmentAction.COMMON_ACTION.CREATE.component} actionItem={AdjustmentAction.COMMON_ACTION.CREATE}>
            <Button className='quick-add-action' type="text" icon={<PlusOutlined />} size="small">Adjustment</Button>
        </CreateAction>
    </div>
);

export default QuickAddLink;
