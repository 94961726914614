import React, {FC} from 'react'
import {Menu} from 'antd';
import SalesOrderCreateTabCustomer from "../Tabs/SalesOrderCreateTab.customer";
import SalesOrderCreateTabPricing from "../Tabs/SalesOrderCreateTab.pricing";
import SalesOrderCreateCommonForm from "../Create/Common/SalesOrderCreateCommon.form";

interface IProps {
    formRef: any,
    loading: any,
    [key: string]: any,
}

const SalesOrderCreateCollapseOrderInfo: FC<IProps> = props => {
    const { formRef, loading, activeStep, viewTabIndex, salesOrderData, deliveryScheduleFormType, handleTabIndex, handleChange, ...restProps } = props;

    const viewTabList = [
        {
            tabIndex: 1,
            title: 'General',
            deliverySchedule: ['TradeBag', 'NonTradeBag', 'NonTradeTone'],
        },
        {
            tabIndex: 2,
            title: 'Customer Overview',
            deliverySchedule: ['TradeBag', 'NonTradeBag', 'NonTradeTone'],
        },
        {
            tabIndex: 3,
            title: 'Pricing Info',
            deliverySchedule: ['TradeBag'],
        },
    ];

    return (
        <div className="collapse-panel-content">
            {activeStep != 1 && (
                <div className="record-detail-tabs">
                    <Menu className="record-detail-tab-menu" mode="horizontal">
                        {viewTabList.filter(item => item.deliverySchedule.includes(deliveryScheduleFormType)).map((tab: any, index: number) => (
                            <Menu.Item
                                key={`tab-${index}`}
                                className={tab.tabIndex == viewTabIndex ? 'tab-item active' : 'tab-item'}
                            >
                                <a onClick={() => handleTabIndex(tab.tabIndex)} >{tab.title}</a>
                            </Menu.Item>
                        ))}
                    </Menu>
                </div>
            )}

            <div className="record-detail-tabs-content">
                {viewTabIndex == 1 && (
                    <SalesOrderCreateCommonForm
                        {...restProps}
                        formRef={formRef}
                        loading={loading}
                        salesOrderData={salesOrderData}
                        deliveryScheduleFormType={deliveryScheduleFormType}
                        handleChange={handleChange}
                    />
                )}
                {viewTabIndex == 2 && (
                    <SalesOrderCreateTabCustomer
                        {...restProps}
                        formRef={formRef}
                        loading={loading}
                        salesOrderData={salesOrderData}
                        deliveryScheduleFormType={deliveryScheduleFormType}
                    />
                )}
                {viewTabIndex == 3 && (
                    <SalesOrderCreateTabPricing
                        customerId={salesOrderData.customer_id}
                        salesOrderId={salesOrderData.id}
                        parentSalesOrderId={salesOrderData.parent_sales_order_id}
                    />
                )}
            </div>

        </div>
    );
}
export default React.memo(SalesOrderCreateCollapseOrderInfo);