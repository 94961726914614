import React, {FC, useEffect, useState} from 'react'
import WorkflowTransitionFormModal from './WorkflowTransitionForm.modal';
import { useForm } from '../../../../../hooks/useForm';
import { WorkflowActionApi, WorkflowTransitionApi } from "../../../../../api";
import { rules } from './WorkflowTransitionForm.validate';
import {Message} from "../../../../../utils";
import './WorkflowTransitionForm.style.scss';

const initialState  = {
    itemData: {},
    workflowActionData: {},
    fields: {
        workflow_record_id: null,
        workflow_action_name: null,
        workflow_name: null,
        comment: null,
        files: null,
        status: '1',
    },
    isNewRecord: true,
    loading: false,
}

const WorkflowTransitionFormController: FC<any> = props => {
    const { workflowRecordId, workflowActionName, workflowStepName, workflowName, titleText, confirmText, submitBtnText, reloadForm, isShowFormModal, hideFormModal, handleCallbackFunc } = props
    const { formRef, initialValues, isSubmitting, setIsSubmitting, handleChange, handleSubmitFailed, resetForm } = useForm(initialState.fields);

    const [itemData, setItemData] = useState(initialState.itemData);
    const [ workflowActionData, setWorkflowActionData] = useState(initialState.workflowActionData);
    const [fileList, setFileList] = useState<any[]>([]);
    const [isNewRecord, setIsNewRecord] = useState(initialState.isNewRecord);
    const [loading, setLoading] = useState(initialState.loading);

    useEffect(() => {
        resetForm();
        setFileList([]);
        loadData();
    }, [reloadForm])

    const loadData = (): void => {
        setLoading(true);
        const payload = {
            workflowActionName: workflowActionName,
            workflowStepName: workflowStepName,
            workflowName: workflowName,
        }
        WorkflowActionApi.workflowStepAction(payload)
            .then(res => {
                setWorkflowActionData(res.data);
                setLoading(false);
            })
            .catch(err => {
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
            });
    }

    const handleSubmit = (values: any): void => {
        handleCreate(values);
    }

    const handleCreate = (values: any): void => {
        setLoading(true);
        setIsSubmitting(true);
        const payload = {
            workflow_record_id: workflowRecordId,
            workflow_action_name: workflowActionName,
            workflow_step_name: workflowStepName,
            workflow_name: workflowName,
            comment: values.comment,
            files: fileList.map(item => item.file_id).join(','),
            status: values.status,
        }
        WorkflowTransitionApi.create(payload)
            .then(res => {
                Message.success('The operation performed successfully.');
                handleCallbackFunc(null, 'hideForm');
                handleCallbackFunc(null, 'updateListItem', workflowRecordId, {
                    assigned_to_user_id: res.data.assigned_user_id,
                    assigned_to_role_id: res.data.assigned_role_id,
                    step_name: res.data.workflow_step_name,
                    step_action: res.data.workflow_action_name,
                    status: res.data.workflow_approval_status,
                });
                handleCallbackFunc(null, 'reloadView');
                setLoading(false);
                setIsSubmitting(false);
            })
            .catch(err => {
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
                setIsSubmitting(false);
            });
    }

    return (
        <div className="form-page-container form-page-container-workflow-transition">
            <WorkflowTransitionFormModal
                loading={loading}
                rules={rules}
                isNewRecord={isNewRecord}
                itemData={itemData}
                workflowActionData={workflowActionData}
                workflowName={workflowName}
                titleText={titleText}
                confirmText={confirmText}
                submitBtnText={submitBtnText}
                isSubmitting={isSubmitting}
                isShowFormModal={isShowFormModal}
                hideFormModal={hideFormModal}
                formRef={formRef}
                initialValues={initialValues}
                fileList={fileList}
                setFileList={setFileList}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleSubmitFailed={handleSubmitFailed}
            />
        </div>
    );
}

export default React.memo(WorkflowTransitionFormController);
