import React, { FC, Fragment, useRef } from 'react'
import { Link } from 'react-router-dom';
import {Button, Form, Input, Select, Spin} from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import NotificationDropdownItem from "./Notification.dropdown.item";

const NotificationDropdownContent: FC<any> = props => {
    const { Search } = Input;
    const { Option } = Select;
    const scrollEleRef = useRef(null);
    const { loading, filters, listData, handleMarkAsReadItem, handleMarkAllAsRead, handleDeleteItem, handleScroll, handleOnChanged } = props;
    return (
        <Fragment>
                <div className="notification-drop-down">
                    <div className="notification-header">
                        <h3>Notifications {loading === true && (<Spin size="small" spinning={loading} />)}</h3>
                        <div className={'notification-settings'}>
                            <Link to={'/setting/my-account/my-setting'}>Settings</Link>
                        </div>
                    </div>
                    <div className={'notification-filter'}>
                        <div className="notification-filter-search-box">
                            <Search
                                placeholder="Search"
                                onSearch={(value) => handleOnChanged('search', value, '')}
                            />
                        </div>
                        <div className="notification-filter-select-box">
                            <Select
                                dropdownMatchSelectWidth={100}
                                defaultValue={filters.type}
                                optionFilterProp="children"
                                onChange={(value) => handleOnChanged('filter_type', value)}
                            >
                                <Option value="">All</Option>
                                <Option value="Unread">Unread</Option>
                            </Select>
                        </div>
                    </div>
                    <div className="notification-content">
                        <Scrollbars ref={scrollEleRef} autoHeight autoHeightMax={474} onScroll={handleScroll} className="scrollEle">
                        { listData?.map((item, index) => (
                            <NotificationDropdownItem key={index} notificationItem={item} handleMarkAsReadItem={handleMarkAsReadItem} handleDeleteItem={handleDeleteItem} />
                        )) }
                        </Scrollbars>
                    </div>
                    <div className="notification-footer">
                        <Link onClick={handleMarkAllAsRead}>Mark all as read</Link>
                    </div>
                </div>
        </Fragment>
    );
}
export default React.memo(NotificationDropdownContent);