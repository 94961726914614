import React, {FC} from 'react'
import {Form, Row, Col, DatePicker, Input, Alert, Collapse} from 'antd';
import SalesOrderCreateSingleScheduleForm from "./SingleSchedule/SalesOrderCreateSingleSchedule.form";
import SalesOrderCreateMultiScheduleForm from "./MultiSchedule/SalesOrderCreateMultiSchedule.form";
import SalesOrderCreateWithoutScheduleForm from "./WithoutSchedule/SalesOrderCreateWithoutSchedule.form";
import SalesOrderCreateCollapseOrderInfo from "../Collapse/SalesOrderCreateCollapse.orderInfo";
import {CONSTANT_COMMON} from "../../../../../constants";
import { rules } from './Common/SalesOrderCreateCommon.validate';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

interface IProps {
    formRef: any,
    loading: any,
    [key: string]: any,
}

const AddOrEditForm: FC<IProps> = props => {
    const { Panel } = Collapse;
    const { formRef, loading, activeStep, scheduleFormType, initialValues, specialSalesOrderAlertText, handleChange, handleSubmit, handleSubmitFailed, handleSalesOrderItemCallbackFunc, ...restProps} = props;

    return (
        <div className="form-page-content form-page-content-sales-order">
            <Form
                {...formItemLayout}
                form={formRef}
                name="salesOrderForm"
                scrollToFirstError={true}
                initialValues={initialValues}
                onValuesChange={handleChange}
                onFinish={handleSubmit}
                onFinishFailed={handleSubmitFailed}
            >

                { activeStep == 2 && specialSalesOrderAlertText && (
                    <div className="special-sales-order-warning" style={{paddingBottom: 15}}>
                        <Collapse defaultActiveKey={[1]} expandIconPosition="left">
                            <Panel key="1" header="SSO Warning" showArrow={false}>
                                <div className="collapse-panel-content">
                                    <Row gutter={[16, 16]}>
                                        <Col span={16}>
                                            <Alert
                                                message="This is going to be an sSO due to following reason(s):"
                                                description={<div dangerouslySetInnerHTML={{__html: specialSalesOrderAlertText}} />}
                                                type="warning"
                                                showIcon
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <h3>Commitment</h3>
                                            <Row gutter={[16, 16]}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="commitment_date"
                                                        rules={rules.commitment_date}
                                                    >
                                                        <DatePicker
                                                            placeholder={"Date"}
                                                            format={CONSTANT_COMMON.DATE_PICKER_DATE_FORMAT}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="commitment_amount"
                                                        rules={rules.commitment_amount}
                                                    >
                                                        <Input placeholder={"Amount"} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                )}

                {activeStep == 1 && (
                    <SalesOrderCreateCollapseOrderInfo
                        formRef={formRef}
                        loading={loading}
                        activeStep={activeStep}
                        handleChange={handleChange}
                        {...restProps}
                    />
                )}

                {activeStep == 2 && scheduleFormType == 'SingleSchedule' && (
                    <SalesOrderCreateSingleScheduleForm
                        {...restProps}
                        formRef={formRef}
                        loading={loading}
                        activeStep={activeStep}
                        scheduleFormType={scheduleFormType}
                        initialValues={initialValues}
                        handleChange={handleChange}
                        handleSalesOrderItemCallbackFunc={handleSalesOrderItemCallbackFunc}
                    />
                )}

                {activeStep == 2 && scheduleFormType == 'MultiSchedule' && (
                    <SalesOrderCreateMultiScheduleForm
                        {...restProps}
                        formRef={formRef}
                        loading={loading}
                        activeStep={activeStep}
                        scheduleFormType={scheduleFormType}
                        initialValues={initialValues}
                        handleChange={handleChange}
                        handleSalesOrderItemCallbackFunc={handleSalesOrderItemCallbackFunc}
                    />
                )}

                {activeStep == 2 && scheduleFormType == 'WithoutSchedule' && (
                    <SalesOrderCreateWithoutScheduleForm
                        {...restProps}
                        formRef={formRef}
                        loading={loading}
                        activeStep={activeStep}
                        scheduleFormType={scheduleFormType}
                        initialValues={initialValues}
                        handleChange={handleChange}
                        handleSalesOrderItemCallbackFunc={handleSalesOrderItemCallbackFunc}
                    />
                )}
            </Form>
        </div>
    );
}
export default React.memo(AddOrEditForm);