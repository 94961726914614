import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/option`

const endpoints = {
    load: () => `${RESOURCE_ENDPOINT}/load`,
    save: () => `${RESOURCE_ENDPOINT}/save`,
}

export default class OptionApi {
    public load = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.load();
        return HttpService.get(url, params, headers);
    }

    public save = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.save();
        return HttpService.post(url, payload, params, headers);
    }

}