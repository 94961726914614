// export const DEV = process.env.NODE_ENV === 'development';
export const ENV_PROD = false;
export const ENV_DEV = true;
export const ENV_TEST = false;
export const LOG_ENABLED = true;

// Local Server
/*
export const BASE_URL = 'http://mis.xyz';
export const API_URL = 'http://api.mis.xyz/';
export const CDN_URL = 'http://cdn.mis.xyz/';
export const MEDIA_SOURCE = 'http://cdn.mis.xyz/image/';
export const WEB_SOCKET_URL = 'http://socket.ncloud.one/mis';
 */

// Uat Server
/*export const BASE_URL = 'https://uat.mis.nurcorp.xyz';
export const API_URL = 'https://uat.api.mis.nurcorp.xyz/';
export const CDN_URL = 'https://uat.cdn.mis.nurcorp.xyz/';
export const MEDIA_SOURCE = 'https://uat.cdn.mis.nurcorp.xyz/image/';
export const WEB_SOCKET_URL = 'https://socket.ncloud.one/mis';*/

// Staging Server
/*export const BASE_URL = 'https://staging.e-m.cloud';
export const API_URL = 'https://api.staging.e-m.cloud/';
export const CDN_URL = 'https://cdn.staging.e-m.cloud/';
export const MEDIA_SOURCE = 'https://cdn.staging.e-m.cloud/image/';
export const WEB_SOCKET_URL = 'https://socket.ncloud.one/mis';*/

// Production Server
/*export const BASE_URL = 'https://e-m.cloud';
export const API_URL = 'https://api.e-m.cloud/';
export const CDN_URL = 'https://cdn.e-m.cloud/';
export const MEDIA_SOURCE = 'https://cdn.e-m.cloud/image/';
export const WEB_SOCKET_URL = 'https://socket.ncloud.one/mis';*/

// Production Server
export const BASE_URL = 'https://app.crm2.best';
export const API_URL = 'https://api.crm2.best/';
export const CDN_URL = 'https://cdn.crm2.best/';
export const MEDIA_SOURCE = 'https://cdn.crm2.best/image/';
export const WEB_SOCKET_URL = 'https://socket.app.e-m.cloud/';

export const AUTH_SERVER_PREFIX = "auth";
export const SCM_SERVER_PREFIX = "scm";
export const CDN_SERVER_PREFIX = "cdn";
export const CORE_SERVER_PREFIX = "core";

