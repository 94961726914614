import {
  USER_ONLINE,
  USER_LOGOUT,
} from './socket.types';

export default (state, action) => {
  switch (action.type) {
    case USER_ONLINE:
      return {
        ...state,
        userStatus: action.payload.userStatus
      };
    case USER_LOGOUT:
      return {
        ...state,
        userStatus: action.payload.userStatus
      };

    default:
      return state;
  }
};