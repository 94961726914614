export const rules = {
    customer_group: [
        { required: true, message: 'Please select one of this options.' }
    ],
    employee_id: [
        { required: true, message: 'This field is required.' }
    ],
    customer_id: [
        { required: true, message: 'This field is required.' }
    ],
    so_total_qty: [
        { required: true, message: 'This field is required.' }
    ],
    delivery_type: [
        { required: true, message: 'This field is required.' }
    ],
    selling_unit: [
        { required: true, message: 'This field is required.' }
    ],
    fileInputValidation: [
        { required: true, message: 'This field is required.' }
    ],
    commitment_date: [
        { required: true, message: 'This field is required.' }
    ],
    commitment_amount: [
        { required: true, message: 'This field is required.' }
    ],
};