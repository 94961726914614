import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.SCM_SERVER_PREFIX}/dashboard`
const endpoints = {
    getSalesQtyChartData: () => `${RESOURCE_ENDPOINT}/sales-qty-chart-data`,
    getCollectionValueChartData: () => `${RESOURCE_ENDPOINT}/collection-value-chart-data`,
    getClosingBalanceChartData: () => `${RESOURCE_ENDPOINT}/closing-balance-chart-data`,
    getDeliveryQtyChartData: () => `${RESOURCE_ENDPOINT}/delivery-qty-chart-data`,
    getClientPieChartData: () => `${RESOURCE_ENDPOINT}/client-pie-chart-data`,
    getDealerPieChartData: () => `${RESOURCE_ENDPOINT}/dealer-pie-chart-data`,
    getRetailerPieChartData: () => `${RESOURCE_ENDPOINT}/retailer-pie-chart-data`,
}

export default class DashboardApi {
    public getSalesQtyChartData = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSalesQtyChartData();
        return HttpService.post(url, params, headers);
    }

    public getCollectionValueChartData = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getCollectionValueChartData();
        return HttpService.post(url, params, headers);
    }

    public getClosingBalanceChartData = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getClosingBalanceChartData();
        return HttpService.post(url, params, headers);
    }

    public getDeliveryQtyChartData = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDeliveryQtyChartData();
        return HttpService.post(url, params, headers);
    }

    public getClientPieChartData = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getClientPieChartData();
        return HttpService.post(url, params, headers);
    }

    public getDealerPieChartData = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDealerPieChartData();
        return HttpService.post(url, params, headers);
    }

    public getRetailerPieChartData = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRetailerPieChartData();
        return HttpService.post(url, params, headers);
    }

}