import jsCookie from 'js-cookie';

export default class CookieService {
    public setCookie = (name: string, value: any): void => {
        jsCookie.set(name, value);
    }

    public getCookie = (name: string): string => {
        return jsCookie.get(name);
    }

    public getAllCookies = (): any => {
        return jsCookie.get();
    }

    public removeCookie = (name: string) => {
        return jsCookie.remove(name);
    }
}
