export const exportMapFields = [
    {
        'field_type': 'virtual',
        'field_name': 'serial_number',
        'field_label': 'Ser No',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 1,
    },
    {
        'field_type': 'normal',
        'field_name': 'sales_order_no',
        'field_label': 'SO No',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 1,
    },
    {
        'field_type': 'virtual',
        'field_name': 'dealer_name',
        'field_label': 'Dealer',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 2,
    },
    {
        'field_type': 'virtual',
        'field_name': 'dealer_contact_number',
        'field_label': 'Contact',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 2,
    },
    {
        'field_type': 'virtual',
        'field_name': 'dealer_address',
        'field_label': 'Dealer Address',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 3,
    },
    {
        'field_type': 'virtual',
        'field_name': 'so_qty',
        'field_label': 'SO Qty',
        'default_value': '',
        'format': 'NUMBER',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 4,
    },
    {
        'field_type': 'normal',
        'field_name': 'so_billed_qty',
        'field_label': 'DO Qty',
        'default_value': '',
        'format': 'NUMBER',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 5,
    },
    {
        'field_type': 'virtual',
        'field_name': 'challan_approved_qty',
        'field_label': 'Challan Qty',
        'default_value': '',
        'format': 'NUMBER',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 6,
    },
    {
        'field_type': 'virtual',
        'field_name': 'received_qty',
        'field_label': 'Received Qty',
        'default_value': '',
        'format': 'NUMBER',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 7,
    },
    {
        'field_type': 'normal',
        'field_name': 'created_at',
        'field_label': 'Created Time',
        'default_value': '',
        'format': 'DATETIME',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 8,
    },
    {
        'field_type': 'virtual',
        'field_name': 'status_text',
        'field_label': 'Status',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 9,
    },
];