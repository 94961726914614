import { useState, useEffect, useContext } from 'react'
import {DataContext} from "../../context/data/data.context";
import {CustomerApi} from "../../api";

export const useCustomerContext = () => {
    const dataContext = useContext(DataContext)
    const [customerContext, setCustomerContext] = useState<any>([]);
    const [customerList, setCustomerList] = useState<any>([]);
    const [activeCustomerList, setActiveCustomerList] = useState<any>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<any>({});
    const [loadingCustomer, setLoadingCustomer] = useState(false);

    useEffect(() => {
        setCustomerContext(dataContext.customerContext);

        if (!dataContext.customerContext.isLoaded) {
            dataContext.loadCustomer()
        }
        else {
            const items = dataContext.customerContext.items
            loadCustomerList(items);
            loadActiveCustomerList(items);
        }
    }, [dataContext.customerContext.isLoaded]);

    const loadCustomerList = (list: any) => {
        setCustomerList(list);
    }

    const loadActiveCustomerList = (list: any) => {
        const activeList = list.filter(item => item.status === 1)
        setActiveCustomerList(activeList);
    }

    const getCustomerById = (id) => {
        if(!customerList) {
            return;
        }
        return customerList.find(item => item.id === Number(id))
    }

    const loadCustomerById = (id): Promise<any> => {
        return new Promise((resolve, reject) => {
            setLoadingCustomer(true);
            CustomerApi.getById(id)
                .then(res => {
                    setSelectedCustomer(res.data);
                    setLoadingCustomer(false);
                    resolve(res.data);
                })
                .catch(err => {
                    setSelectedCustomer({});
                    setLoadingCustomer(false);
                    reject(err);
                });
        });
    }

    const filterCustomerListByEmployeeId = (employeeId: any) => {
        return customerList.filter((item) => item.employee_id == employeeId);
    }

    const filterActiveCustomerListByEmployeeId = (employeeId: any) => {
        return activeCustomerList.filter((item) => item.employee_id == employeeId);
    }

    const setCustomerFormFieldValue = (formRef, key, value) => {
        if (customerList?.find(item => item.id === Number(value))) {
            formRef.setFieldsValue({[key]: value})
        }
        else {
            formRef.setFieldsValue({[key]: null})
        }
    }

    return { customerContext, loadingCustomer, selectedCustomer, customerList, activeCustomerList, setCustomerFormFieldValue, loadCustomerById, getCustomerById, filterCustomerListByEmployeeId, filterActiveCustomerListByEmployeeId }
}