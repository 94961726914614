import React, {FC, useEffect, useState} from 'react'
import CollectionViewDrawer from "./CollectionView.drawer";
import {Message} from "../../../../../utils";
import {CollectionApi} from "../../../../../api";
import '../../../styles/Order.module.view.scss';
import './CollectionView.style.scss';

const initialState  = {
    modalTitle: 'Collection Info',
    itemData: {},
    loading: false,
    fields: {},
}

const CollectionViewController: FC<any> = props => {
    const { entityId, reloadView, isShowView, viewTabIndex, setViewTabIndex, handleCallbackFunc } = props
    const modalTitle = initialState.modalTitle;
    const [itemData, setItemData] = useState(initialState.itemData);
    const [loading, setLoading] = useState(initialState.loading);

    useEffect(() => {
        setItemData(initialState.itemData);
        if (entityId && isShowView) {
            loadData();
        }
    }, [entityId, reloadView])

    const loadData = (): void => {
        setLoading(true);
        CollectionApi.getById(entityId)
            .then(res => {
                setItemData(res.data);
                setLoading(false);
            })
            .catch(err => {
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
            });
    }

    return (
        <CollectionViewDrawer
            isShowView={isShowView}
            loading={loading}
            modalTitle={modalTitle}
            itemData={itemData}
            viewTabIndex={viewTabIndex}
            setViewTabIndex={setViewTabIndex}
            reloadView={reloadView}
            handleCallbackFunc={handleCallbackFunc}
        />
    );
}

export default React.memo(CollectionViewController);
