import React, { FC, Fragment} from 'react'
import {Button, Drawer, Spin} from 'antd';
import SalesOrderView from "./SalesOrderView.view";
import CustomScrollbar from "../../../../../components/Scrollbar/CustomScrollbar";

interface IProps {
    reloadView: any,
    isShowView: any,
    [key: string]: any,
}

const SalesOrderViewDrawer: FC<IProps> = props => {
    const {isShowView, loading, reloadView, modalTitle, itemData, viewTabIndex, handleCallbackFunc, ...restProps} = props;
    return (
        <Fragment>
            <Drawer
                width={'80%'}
                zIndex={500}
                className="view-page-drawer view-page-drawer-sales-order"
                title={<b>{modalTitle}&nbsp;&nbsp;{loading && <Spin size="small" />}</b>}
                mask={false}
                visible={isShowView}
                onClose={(event) => handleCallbackFunc(null, 'hideView')}
                footer={[
                    <Button key='close' onClick={(event) => handleCallbackFunc(null, 'hideView')}>Close</Button>,
                ]}
            >
                <CustomScrollbar className="view-page-drawer-scrollbar">
                    <SalesOrderView
                        {...restProps}
                        itemData={itemData}
                        loading={loading}
                        reloadView={reloadView}
                        viewTabIndex={viewTabIndex}
                        handleCallbackFunc={handleCallbackFunc}
                    />
                </CustomScrollbar>
            </Drawer>
        </Fragment>
    );
}
export default React.memo(SalesOrderViewDrawer);