export enum SendAsEnum {
    SMS = 'SMS',
    PUSH_NOTIFICATION = 'Push Notification',
}

export enum SendToEnum {
    ALL_EMPLOYEE = 'All Employee',
    ALL_DEALER = 'All Dealer',
    ALL_CLIENT = 'All Client',
    ALL_RETAILER = 'All Retailer',
}
