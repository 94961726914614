import React, {FC, useEffect, useState} from 'react'
import {Form, Input, Row, Col, Select, Radio, Upload, Button, Spin, DatePicker} from 'antd';
import { UploadOutlined } from '@ant-design/icons'
import {FileApi} from "../../../../../../api";
import {CONSTANT_COMMON, CONSTANT_CONFIG} from "../../../../../../constants";
import { rules } from './SalesOrderCreateCommon.validate';
import {AntModal} from "../../../../../../utils";
import {SalesOrderCalculationUtils} from "../../Utils/SalesOrderCalculationUtils";
import FileName from "../../../../../../components/File/FileName";

const SalesOrderCreateCommonForm: FC<any> = props => {
    const { Option } = Select;

    const { loading, formRef, isNewRecord, employeeContext, customerInfo, employeeList, customerList, salesOrderData, salesOrderItemData, remainingMultiScheduleList, fileList, setFileList, handleOnChangedSalesOrderData, handleResetSalesOrderItem} = props;

    const [uploadLoading, setUploadLoading] = useState<boolean>(false);

    useEffect(() => {
        if (salesOrderData.files) {
            FileApi.getFileById(salesOrderData.files)
                .then(res => {
                    let fileInfo = {
                        uid: `${res.data.file_id}`,
                        file_id: res.data.file_id,
                        name: res.data.original_filename,
                        url: CONSTANT_CONFIG.MEDIA_SOURCE + res.data.file_id,
                    }
                    setFileList([fileInfo])
                    setUploadLoading(false)
                }).catch(err => {
                setUploadLoading(false)
            });

        }
    }, [salesOrderData.files])

    const handleEmployeeSelect = (employeeId: any, option: any) => {
        if (employeeId) {
            formRef.setFieldsValue({
                customer_id: null,
            })
            handleOnChangedSalesOrderData('employee_id', employeeId);
        }
    }

    const handleCustomerSelect = (customerId: any, option: any) => {
        if (salesOrderData.customer_id) {
            AntModal.confirm('Confirmation', 'Clear all SO input and DO schedule item.', customerId, confirmHandleCustomerSelect, 'Confirm');
        }
        else if (customerId) {
            handleOnChangedSalesOrderData('customer_id', customerId);
        }
    }

    const confirmHandleCustomerSelect = (customerId: any, action: any) => {
        if (customerId && action === 'ok') {
            handleResetSalesOrderItem();
            handleOnChangedSalesOrderData('customer_id', customerId);
        }
        if (customerId && action === 'cancel') {
            formRef.setFieldsValue({
                customer_id: salesOrderData.customer_id,
            })
        }
    }

    const handleSellingUnitChange = (bagType: any) => {
        if (salesOrderItemData.length) {
            AntModal.confirm('Confirmation', 'Clear all DO schedule item.', bagType, confirmHandleSellingUnitChange, 'Confirm');
        }
        else {
            handleOnChangedSalesOrderData('selling_unit', bagType);
        }
    }

    const confirmHandleSellingUnitChange = (bagType: any, action: any) => {
        if (bagType && action === 'ok') {
            handleResetSalesOrderItem();
            handleOnChangedSalesOrderData('selling_unit', bagType);
        }
        if (bagType && action === 'cancel') {
            formRef.setFieldsValue({
                selling_unit: salesOrderData.selling_unit,
            })
        }
    }

    const handleDeliveryTypeChange = (deliveryType: any) => {
        if (salesOrderItemData.length && deliveryType === 'WITHOUT_DELIVERY') {
            AntModal.confirm('Confirmation', 'Clear all DO schedule item.', deliveryType, confirmHandleDeliveryTypeChange, 'Confirm');
        }
        else {
            handleOnChangedSalesOrderData('delivery_type', deliveryType);
        }
    }

    const confirmHandleDeliveryTypeChange = (deliveryType: any, action: any) => {
        if (deliveryType && action === 'ok') {
            handleResetSalesOrderItem();
            handleOnChangedSalesOrderData('delivery_type', deliveryType);
        }
        if (deliveryType && action === 'cancel') {
            formRef.setFieldsValue({
                delivery_type: salesOrderData.delivery_type,
            })
        }
    }

    const validateSoTotalQty = (_: any, qty: any) => {
        const soTotalQty = Number(qty) || 0;
        const totalSalesOrderItemQty = SalesOrderCalculationUtils.calculateTotalScheduleBilledQty(salesOrderItemData);

        if (soTotalQty == 0) {
            return Promise.reject('This field is required.');
        }
        else if (salesOrderData.delivery_type === 'FULL_DELIVERY' || salesOrderData.delivery_type === 'WITHOUT_DELIVERY') {
            return Promise.resolve();
        }
        else if ( soTotalQty >= totalSalesOrderItemQty ) {
            return Promise.resolve();
        }
        else {
            return Promise.reject('Maximum SO Qty: ' + totalSalesOrderItemQty);
        }
    };

    const handleChangeFile = (info: any) => {
        if (info.file.status === 'uploading') {
            setUploadLoading(true)
            return;
        }
        if (info.file.status === 'error') {
            setUploadLoading(false)
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoading(false)
            return;
        }
    };

    const handleBeforeUploadFile = (file: File, FileList: File[]) => {
        return true
    }

    const handleRemoveUploadFile = (file: any) => {
        setFileList((prevFileList) => {
            return prevFileList.filter(item => item !== file)
        })
    };

    const handleFileUpload = (name: string, file: any) => {
        setUploadLoading(true)
        FileApi.upload(file)
            .then(res => {
                setFileList((prevFileList) => {
                    return [
                        {
                            uid: `${res.data.file_id}`,
                            file_id: res.data.file_id,
                            name: res.data.original_filename,
                            url: CONSTANT_CONFIG.MEDIA_SOURCE + res.data.file_id,
                        }
                    ]
                })

                const fileListString = res.data.file_id;
                handleOnChangedSalesOrderData('file', fileListString);
                setUploadLoading(false)
            })
            .catch(err => {
                setUploadLoading(false)
            });
    };

    return (
        <div className="sales-order-form-select-dealer">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        label="TSE Name"
                        name="employee_id"
                        rules={rules.employee_id}
                    >
                        <Select
                            showSearch
                            placeholder="-- Select --"
                            onSelect={handleEmployeeSelect}
                            disabled={!isNewRecord}
                            optionFilterProp="children"
                            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                            loading={employeeContext.isLoading}
                        >
                            {employeeList && employeeList.map((item, index) => (
                                <Option key={`employee-${index}`} value={item.id}>{`${item.name} [${item.code}]`}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Dealer/Client"
                        name="customer_id"
                        rules={rules.customer_id}
                    >
                        <Select
                            showSearch
                            placeholder="-- Select --"
                            onSelect={handleCustomerSelect}
                            disabled={!isNewRecord}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {customerList && customerList.map((item, index) => (
                                <Option key={`customer-${index}`} value={item.id}>{`${item.org_name} [${item.code}, ${item.division_name}]`}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                {loading && <div style={{marginLeft: 15}}><Spin size="small" /></div>}

                {!loading && salesOrderData.customer_id && (
                    <>
                        {isNewRecord && (
                            <Col span={24}>
                                <Form.Item
                                    label="Sales Order No"
                                    name="parent_sales_order_id"
                                >
                                    <Radio.Group
                                        onChange={(e) => handleOnChangedSalesOrderData('parent_sales_order_id', e.target.value)}
                                    >
                                        <div className="row">
                                            <Radio value={''}>New Sales Order</Radio>
                                        </div>
                                        {remainingMultiScheduleList && remainingMultiScheduleList.map((item, index) => (
                                            <div className="row">
                                                <Radio value={item.id}>{`${item.base_sales_order_no} (Sales Order Qty: ${item.so_total_qty}, Remaining Qty: ${item.so_booking_remaining_qty})`}</Radio>
                                            </div>
                                        ))}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        )}
                    </>
                )}

                {!loading && salesOrderData.customer_id && !salesOrderData.parent_sales_order_id && (
                    <>
                        {isNewRecord && (
                            <Col span={24}>
                                <Form.Item
                                    label="Delivery Type"
                                    name="delivery_type"
                                    rules={rules.delivery_type}
                                >
                                    <Radio.Group
                                        onChange={(e) => handleDeliveryTypeChange(e.target.value)}
                                    >
                                        <div className="row">
                                            <Radio value={'FULL_DELIVERY'}>Full Qty Delivery</Radio>
                                        </div>
                                        <div className="row">
                                            <Radio value={'PARTIAL_DELIVERY'}>Multi-Schedule Delivery</Radio>
                                        </div>
                                        <div className="row">
                                            <Radio value={'WITHOUT_DELIVERY'}>Without Delivery</Radio>
                                        </div>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        )}
                    </>

                )}

                {(!loading && (salesOrderData.delivery_type == 'PARTIAL_DELIVERY' || salesOrderData.delivery_type == 'WITHOUT_DELIVERY')) && !salesOrderData.parent_sales_order_id && (
                    <Col span={24}>
                        <Form.Item
                            label="Sales Order Qty"
                            name="so_total_qty"
                            rules={[{ required: true, validator: validateSoTotalQty}]}
                        >
                            <Input
                                onChange={(e) => handleOnChangedSalesOrderData('so_total_qty', e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                )}

                {!loading && customerInfo.customer_group == 'NONTRADE' && !salesOrderData.parent_sales_order_id && (
                    <>
                        {isNewRecord && (
                            <Col span={24}>
                                <Form.Item
                                    label="Product Selling Unit"
                                    name="selling_unit"
                                    rules={rules.selling_unit}
                                >
                                    <Radio.Group
                                        disabled={!isNewRecord}
                                        onChange={(e) => handleSellingUnitChange(e.target.value)}
                                    >
                                        <Radio value={'BAG'}>Bag</Radio>
                                        <Radio value={'TON'}>Ton</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        )}
                    </>
                )}

                {!loading && customerInfo.customer_group == 'NONTRADE' && !salesOrderData.parent_sales_order_id &&  (
                    <Col span={24}>
                        <Form.Item
                            label="Work Order (Attachment)"
                            name="file"
                            rules={rules.fileInputValidation}
                        >
                            <Upload
                                name="file_uploader"
                                className="file-uploader"
                                multiple={false}
                                fileList={fileList}
                                onChange={handleChangeFile}
                                beforeUpload={handleBeforeUploadFile}
                                onRemove={handleRemoveUploadFile}
                                customRequest={({file}) => handleFileUpload('file', file)}
                            >
                                <Button
                                    className="btn-upload"
                                    icon={<UploadOutlined />}
                                    loading={uploadLoading}
                                >
                                    Click to Upload
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                )}

                {!loading && customerInfo.customer_group == 'NONTRADE' && salesOrderData.parent_sales_order_id &&  (
                    <Col span={24}>
                        <div style={{paddingBottom: 10}}>
                            <label>Work Order (Attachment)</label>
                        </div>
                        { salesOrderData?.file != "" && salesOrderData?.file != null && (
                            <div>
                                <span className="attachment-item">
                                    <a target={"_blank"} href={CONSTANT_CONFIG.MEDIA_SOURCE + salesOrderData?.file}><FileName fileId={salesOrderData?.file} /></a>
                               </span>
                            </div>
                        )}
                    </Col>
                )}

                {!loading && salesOrderData.customer_id && (
                    <Col span={24}>
                        <Form.Item
                            label="SO Creation Time"
                            name="so_creation_time"
                        >
                            <DatePicker
                                showTime
                                onChange={(date) => handleOnChangedSalesOrderData('so_creation_time', date)}
                                format={CONSTANT_COMMON.DATE_PICKER_DATE_TIME_FORMAT}
                            />
                        </Form.Item>
                    </Col>
                )}


            </Row>
        </div>
    );
}
export default React.memo(SalesOrderCreateCommonForm);