import React, { FC, Fragment} from 'react'
import {Button, Drawer, Spin} from 'antd';
import SalesOrderItemView from "./SalesOrderItemView.view";
import CustomScrollbar from "../../../../../../components/Scrollbar/CustomScrollbar";

interface IProps {
    loading: any,
    isShowView: any,
    [key: string]: any,
}

const SalesOrderItemViewDrawer: FC<IProps> = props => {
    const {isShowView, loading, reloadView, modalTitle, itemData, handleCallbackFunc, ...restProps} = props;
    return (
        <Fragment>
            <Drawer
                width={720}
                zIndex={500}
                className="view-page-drawer view-page-drawer-salesOrderItem"
                title={<b>{modalTitle}&nbsp;&nbsp;{loading && <Spin size="small" />}</b>}
                mask={false}
                visible={isShowView}
                onClose={(event) => handleCallbackFunc(null, 'hideView')}
                footer={[
                    <Button key='close' onClick={(event) => handleCallbackFunc(null, 'hideView')}>Close</Button>,
                ]}
            >
                <CustomScrollbar className="view-page-drawer-scrollbar">
                    <SalesOrderItemView
                        {...restProps}
                        itemData={itemData}
                        reloadView={reloadView}
                        handleCallbackFunc={handleCallbackFunc}
                    />
                </CustomScrollbar>
            </Drawer>
        </Fragment>
    );
}
export default React.memo(SalesOrderItemViewDrawer);