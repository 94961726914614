import React, { FC } from 'react'
import {Layout} from 'antd';
import './Footer.style.scss';
import nurtech  from  './../../../../assets/images/icon/nurpower.png';


const Footer: FC<any> = props => {
    return (
        <Layout.Footer id="footer" className="app-footer">
            <div className="copyright-text">
            </div>
            <div>

            </div>
        </Layout.Footer>
    );
}

export default React.memo(Footer);