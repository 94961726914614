export const SettingMenu = [
    {
        type: 'group',
        title: 'My Settings',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'My Profile',
                permission: 'auth:user:myAccount',
                link: { to: '/setting/my-account/my-profile', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'My Settings',
                permission: 'auth:user:mySetting',
                link: { to: '/setting/my-account/my-setting', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Change Password',
                permission: 'auth:oauth:changePassword',
                link: { to: '/setting/my-account/change-password', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Site Settings',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'General Configuration',
                permission: 'core:option:setting:generalConfiguration',
                link: { to: '/setting/general-setting/general-configuration', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Notifications',
                permission: 'core:option:setting:notification',
                link: { to: '/setting/general-setting/notification', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Profile',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Employee',
                permission: 'scm:employee:list',
                link: { to: '/setting/profile/employee', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Client',
                permission: 'scm:client:list',
                link: { to: '/setting/profile/client', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Dealer',
                permission: 'scm:dealer:list',
                link: { to: '/setting/profile/dealer', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Retailer',
                permission: 'scm:retailer:list',
                link: { to: '/setting/profile/retailer', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Project',
                permission: 'scm:project:list',
                link: { to: '/setting/profile/project', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Driver',
                permission: 'scm:driver:list',
                link: { to: '/setting/profile/driver', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Data Entry',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Departments',
                permission: 'scm:department:list',
                link: { to: '/setting/data-entry/department', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Designations',
                permission: 'scm:designation:list',
                link: { to: '/setting/data-entry/designation', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Address Book',
                permission: 'scm:address:list',
                link: { to: '/setting/data-entry/address', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Product',
                permission: 'scm:product:list',
                link: { to: '/setting/data-entry/product', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Pricing',
                permission: 'scm:pricing:list',
                link: { to: '/setting/data-entry/pricing', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Security',
                permission: 'scm:security:list',
                link: { to: '/setting/data-entry/security', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Adjustment',
                permission: 'scm:adjustment:list',
                link: { to: '/setting/data-entry/adjustment', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Trade Target',
                permission: 'scm:target:list',
                link: { to: '/setting/data-entry/trade-target', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Non-Trade Target',
                permission: 'scm:target:list',
                link: { to: '/setting/data-entry/non-trade-target', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Credit Limit',
                permission: 'scm:creditLimit:list',
                link: { to: '/credit-limit', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Vehicle',
                permission: 'scm:vehicle:list',
                link: { to: '/setting/data-entry/vehicle', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'FOB Transport',
                permission: 'scm:fobTransport:list',
                link: { to: '/setting/data-entry/fob-transport', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Warehouse',
                permission: 'scm:warehouse:list',
                link: { to: '/setting/data-entry/warehouse', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Previous Data Entry',
                permission: 'scm:opening:list',
                link: { to: '/setting/data-entry/opening', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Transfer Order',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'TO Request',
                permission: 'scm:toRequest:list',
                link: { to: '/setting/transfer-order/to-request', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'TO List',
                permission: 'scm:to:list',
                link: { to: '/setting/transfer-order/to', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Commissions',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Commission Offer',
                permission: 'scm:commissionOffer:list',
                link: { to: '/setting/commission/offer', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Apply Commission Manually',
                permission: 'scm:commissionSchedule:list',
                link: { to: '/setting/commission/apply-commission-manually', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Promotions',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Multimedia',
                permission: 'scm:promotion:multimedia:list',
                link: { to: '/setting/promotion/multimedia', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Surprise Offer',
                permission: 'scm:promotion:surpriseOffer:list',
                link: { to: '/setting/promotion/surprise-offer', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Communication',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Send Message',
                permission: 'core:template:sendMessage:navItem',
                link: { to: '/setting/communication/send-message', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'User Management',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Users',
                permission: 'auth:user:list',
                link: { to: '/setting/user-management/user', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Request Access',
                permission: 'scm:device:list',
                link: { to: '/setting/user-management/request-access', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
           /* {
                type: 'item',
                title: 'Groups',
                permission: 'auth:group:list',
                link: { to: '/setting/user-management/group', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },*/
            {
                type: 'item',
                title: 'Roles',
                permission: 'auth:role:list',
                link: { to: '/setting/user-management/role', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Permissions',
                permission: 'auth:permission:rolePermission',
                link: { to: '/setting/permission/role-permission', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Permissions',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'User Additional Permissions',
                permission: 'auth:permission:userAdditionalPermission',
                link: { to: '/setting/permission/user-additional-permission', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Resources',
                permission: 'auth:resource:list',
                link: { to: '/setting/permission/resource', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Scopes',
                permission: 'auth:scope:list',
                link: { to: '/setting/permission/scope', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Workflows',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Workflows',
                permission: 'scm:workflow:workflowName:list',
                link: { to: '/setting/workflow/workflow-name', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Workflow Steps',
                permission: 'scm:workflow:workflowStep:list',
                link: { to: '/setting/workflow/workflow-steps', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Workflow Actions',
                permission: 'scm:workflow:workflowAction:list',
                link: { to: '/setting/workflow/workflow-actions', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Location',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Division',
                permission: 'core:location:division:list',
                link: { to: '/setting/location/division', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'District',
                permission: 'core:location:district:list',
                link: { to: '/setting/location/district', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Thana',
                permission: 'core:location:thana:list',
                link: { to: '/setting/location/thana', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'CMS',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Page',
                permission: 'core:post:list',
                link: { to: '/setting/cms/page', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Dropdown Fields',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Adjustment Type',
                permission: 'core:lookup:adjustmentType:list',
                link: { to: '/setting/lookup/adjustment-type', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Payment Mod',
                permission: 'core:lookup:paymentMod:list',
                link: { to: '/setting/lookup/payment-mode', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Security Type',
                permission: 'core:lookup:securityType:list',
                link: { to: '/setting/lookup/security-type', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'Bank List',
                permission: 'core:lookup:bankList:list',
                link: { to: '/setting/lookup/bank-list', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Integrations',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Import',
                permission: 'scm:integration:import:list',
                link: { to: '/setting/integration/import/import-list', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
            {
                type: 'item',
                title: 'SMS',
                permission: 'scm:integration:sms:list',
                link: { to: '/setting/integration/sms/sms-list', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
        type: 'group',
        title: 'Troubleshooting',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Test Send',
                permission: 'core:troubleshooting:testSend:list',
                link: { to: '/setting/troubleshooting/test-send', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
    {
       type: 'group',
       title: 'Logs',
       icon: 'calendar-outline',
       class: '',
       expanded: true,
       hidden: false,
       children: [
           {
               type: 'item',
               title: 'Audit Logs',
               permission: 'core:log:audit:list',
               link: { to: '/setting/log/audit-log', exactMatch: true, externalUrl: false, openInNewTab: false },
               icon: '',
               class: '',
               badge: '',
               badgeClass: '',
               hidden: false,
           },
           {
               type: "item",
               title: "Clear Cache",
               permission: "core:log:audit:list",
               link: { to: "/setting/general-setting/clear-cache", exactMatch: true, externalUrl: false, openInNewTab: false },
               icon: "",
               class: "",
               badge: "",
               badgeClass: "",
               hidden: false,
           }
       ],
   },
    {
        type: 'group',
        title: 'Help',
        icon: 'calendar-outline',
        class: '',
        expanded: true,
        hidden: false,
        children: [
            {
                type: 'item',
                title: 'Help',
                permission: 'core:help:list',
                link: { to: '/setting/help/content', exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                hidden: false,
            },
        ],
    },
];
