import { useState, useEffect, useContext } from 'react'
import {DataContext} from "../../context/data/data.context";

export const useProductContext = () => {
    const dataContext = useContext(DataContext)
    const [productContext, setProductContext] = useState<any>([]);
    const [productList, setProductList] = useState<any>([]);
    const [activeProductList, setActiveProductList] = useState<any>([]);

    useEffect(() => {
        setProductContext(dataContext.productContext);

        if (!dataContext.productContext.isLoaded) {
            dataContext.loadProduct()
        }
        else {
            const items = dataContext.productContext.items
            loadProductList(items);
            loadActiveProductList(items);
        }
    }, [dataContext.productContext.isLoaded]);

    const loadProductList = (list: any) => {
        setProductList(list);
    }

    const loadActiveProductList = (list: any) => {
        const activeList = list.filter(item => item.status === 1)
        setActiveProductList(activeList);
    }

    const getProductById = (id) => {
        if(!productList) {
            return;
        }
        return productList.find(item => item.id === id)
    }

    const setProductFieldValue = (formRef, key, value) => {
        if (activeProductList?.find(item => item.id === Number(value))) {
            formRef.setFieldsValue({[key]: value})
        }
        else {
            formRef.setFieldsValue({[key]: null})
        }
    }

    return { productContext, productList, activeProductList, setProductFieldValue, getProductById }
}