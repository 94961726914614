export default class TreeUtils {

    public navTreeIteration = (Arr, pid, idName, pidName, name) => {
        let result:any = [];
        let temp = [];
        for (let i = 0; i < Arr.length; i++) {
            if (parseInt(Arr[i][pidName]) === parseInt(pid)) {
                const obj = { 'label': Arr[i][name], 'value': Arr[i][idName], 'title': Arr[i][name],'key':Arr[i][idName], 'children': []}
                temp = this.navTreeIteration(Arr, Arr[i][idName],idName,pidName,name);
                if (temp.length > 0) {
                    obj.children = temp
                }
                result.push(obj)
            }
        }
        return result;
    }

    public navTreeStrIteration = (Arr, pid, idName, pidName, name) => {
        let result:any = [];
        let temp = [];
        for (let i = 0; i < Arr.length; i++) {
            if (Arr[i][pidName] === pid) {
                const obj = { 'label': Arr[i][name], 'value': Arr[i][idName], 'title': Arr[i][name],'key':Arr[i][idName], 'children': []}
                temp = this.navTreeStrIteration(Arr, Arr[i][idName],idName,pidName,name);
                if (temp.length > 0) {
                    obj.children = temp
                }
                result.push(obj)
            }
        }
        return result;
    }

    public tableTreeIteration = (Arr, options) => {
        let result:any = [];
        let temp = [];
        for (let i = 0; i < Arr.length; i++) {
            let itemId = Arr[i][options.parentName];
            if (parseInt(itemId) === parseInt(options.parent_id)) {
                const obj = Arr[i];
                temp = this.tableTreeIteration(Arr, {parent_id:Arr[i][options.idName], parentName:options.parentName, idName: options.idName});
                if (temp.length > 0) {
                    obj.children = temp
                }
                result.push(obj)
            }
        }
        return result;
    }

    public tableStrTreeIteration = (Arr, options) => {
        let result:any = [];
        let temp = [];
        for (let i = 0; i < Arr.length; i++) {
            let itemId = Arr[i][options.parentName];
            if (itemId === options.parentId) {
                const obj = Arr[i];
                temp = this.tableStrTreeIteration(Arr, {parentId:Arr[i][options.idName], parentName:options.parentName, idName: options.idName});
                if (temp.length > 0) {
                    obj.children = temp
                }
                result.push(obj)
            }
        }
        return result;
    }


    public getTree(id, result: any[]): any {
        const items:any = [];
        for (const obj of result) {
            if (obj !== undefined && obj.parentId === id) {
                items.push(obj);
                if (items.length !== 0) {
                    const tree = this.getTree(obj.id, result);
                    if (tree.length > 0) {
                        obj.children = tree;
                    }
                }
            }
        }
        return items;
    }

}
