import { useState, useEffect, useContext } from 'react'
import {AuthContext} from "../../context/auth/auth.context";
import {DealerApi, EmployeeApi} from "../../api";

export const useProfileContext = () => {
    const { profileType, profileId } = useContext(AuthContext)
    const [profileLoading, setProfileLoading] = useState<any>(false);
    const [profileInfo, setProfileInfo] = useState<any>({});

    useEffect(() => {
        if (profileType == 'EMPLOYEE' && profileId) {
            loadEmployeeByProfileId(profileId);
        }
        else if (profileType == 'DEALER' && profileId) {
            loadDealerByProfileId(profileId);
        }
    }, [profileType, profileId]);

    const loadEmployeeByProfileId = (profileId: any) => {
        setProfileLoading(true);
        EmployeeApi.getById(profileId)
            .then(res => {
                setProfileInfo(res.data);
                setProfileLoading(false);
            })
            .catch(err => {
                setProfileLoading(false);
            });
    }

    const loadDealerByProfileId = (profileId: any) => {
        setProfileLoading(true);
        DealerApi.getById(profileId)
            .then(res => {
                setProfileInfo(res.data);
                setProfileLoading(false);
            })
            .catch(err => {
                setProfileLoading(false);
            });
    }

    return { profileLoading, profileInfo }
}