import { useState, useEffect, useContext } from 'react'
import {DataContext} from "../../context/data/data.context";

export const useWarehouseContext = () => {
    const dataContext = useContext(DataContext)
    const [warehouseContext, setWarehouseContext] = useState<any>([]);
    const [warehouseList, setWarehouseList] = useState<any>([]);
    const [activeWarehouseList, setActiveWarehouseList] = useState<any>([]);

    useEffect(() => {
        setWarehouseContext(dataContext.warehouseContext);

        if (!dataContext.warehouseContext.isLoaded) {
            dataContext.loadWarehouse()
        }
        else {
            const items = dataContext.warehouseContext.items
            loadWarehouseList(items);
            loadActiveWarehouseList(items);
        }
    }, [dataContext.warehouseContext.isLoaded]);

    const loadWarehouseList = (list: any) => {
        setWarehouseList(list);
    }

    const loadActiveWarehouseList = (list: any) => {
        const activeList = list.filter(item => item.status === 1)
        setActiveWarehouseList(activeList);
    }

    const getWarehouseById = (id) => {
        if(!warehouseList) {
            return;
        }
        return warehouseList.find(item => item.id === id)
    }

    const setWarehouseFieldValue = (formRef, key, value) => {
        if (activeWarehouseList?.find(item => item.id === Number(value))) {
            formRef.setFieldsValue({[key]: value})
        }
        else {
            formRef.setFieldsValue({[key]: null})
        }
    }

    return { warehouseContext, warehouseList, activeWarehouseList, setWarehouseFieldValue, getWarehouseById }
}