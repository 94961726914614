import React, {FC, useEffect, useState} from 'react'
import {Timeline} from "antd";
import {DateTimeUtils, Message} from "../../../../../utils";
import {WorkflowTransitionApi} from "../../../../../api";
import {WorkflowApprovalStatusEnum, WorkflowStepEnum} from "../../../../../utils/enums";
import {useUserContext} from "../../../../../hooks/context/useUserContext";
import {CONSTANT_CONFIG} from "../../../../../constants";
import FileName from "../../../../../components/File/FileName";
import '../../../styles/Workflow.module.listing.scss';
import './WorkflowTransitionTimeline.style.scss';

const WorkflowTransitionTimelineList: FC<any> = props => {
    const { userContext, getUserById } = useUserContext();
    const { workflowRecordId, workflowStepName, workflowName } = props;

    const [listData, setListData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (workflowRecordId && workflowName) {
            loadData(workflowRecordId, workflowName);
        }
    }, [workflowRecordId, workflowStepName, workflowName])

    const loadData = (workflowRecordId: any, workflowName: any): void => {
        setLoading(true);
        let payload = {};
        if(workflowName === 'SALES_ORDER' || workflowName === 'NONTRADE_SALES_ORDER' || workflowName === 'SPECIAL_SALES_ORDER' || workflowName === 'NONTRADE_SPECIAL_SALES_ORDER'){
            payload = {
                '$filter': "workflow_record_id='"+workflowRecordId+"' AND (workflow_name='SALES_ORDER' OR workflow_name='NONTRADE_SALES_ORDER' OR workflow_name='SPECIAL_SALES_ORDER' OR workflow_name='NONTRADE_SPECIAL_SALES_ORDER') AND status='1'",
                '$orderby': 'id desc',
            }
        }
        else if(workflowName === 'CHALLAN' || workflowName === 'NONTRADE_CHALLAN'){
            payload = {
                '$filter': "workflow_record_id='"+workflowRecordId+"' AND (workflow_name='CHALLAN' OR workflow_name='NONTRADE_CHALLAN') AND status='1'",
                '$orderby': 'id desc',
            }
        }
        else if(workflowName === 'COLLECTION' || workflowName === 'NONTRADE_COLLECTION'){
            payload = {
                '$filter': "workflow_record_id='"+workflowRecordId+"' AND (workflow_name='COLLECTION' OR workflow_name='NONTRADE_COLLECTION') AND status='1'",
                '$orderby': 'id desc',
            }
        }
        else if(workflowName === 'CREDIT_LIMIT' || workflowName === 'NONTRADE_CREDIT_LIMIT'){
            payload = {
                '$filter': "workflow_record_id='"+workflowRecordId+"' AND (workflow_name='CREDIT_LIMIT' OR workflow_name='NONTRADE_CREDIT_LIMIT') AND status='1'",
                '$orderby': 'id desc',
            }
        }
        else {
            payload = {
                '$filter': "workflow_record_id='"+workflowRecordId+"' AND workflow_name='"+workflowName+"' AND status='1'",
                '$orderby': 'id desc',
            }
        }

        WorkflowTransitionApi.list(payload)
            .then(res => {
                setListData(res.data.results);
                setLoading(false);
            })
            .catch(err => {
                console.error("error", err);
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
            });
    }

    return (
        <div className="activity-timeline-content">
            { listData?.map((item, index) => (
                <Timeline mode="left">
                    <Timeline.Item color="red" className="timeline-item">
                        <span className="event">
                            {item.workflow_action_name == 'SEND_BACK' ? 'Sent Back (' + WorkflowStepEnum[item.prev_workflow_step_name] + ')' || item.prev_workflow_step_name + ')' : WorkflowApprovalStatusEnum[item.workflow_approval_status] || item.workflow_approval_status }</span>
                        <span className="author">
                            { item.workflow_approval_status == 'APPROVED' || item.workflow_approval_status == 'CANCELED' ? 'by ' : ''  }
                            {userContext.isLoaded && getUserById(item.created_by)?.name + ', '}
                            <strong>{ item.workflow_approval_status != 'APPROVED' && item.workflow_approval_status != 'CANCELED' ? WorkflowStepEnum[item.prev_workflow_step_name] || item.prev_workflow_step_name : '' }</strong>
                            { item.workflow_approval_status != 'APPROVED' && item.workflow_approval_status != 'CANCELED' ? ' to ' : ''  }
                            <strong>{ item.workflow_approval_status == 'APPROVED' || item.workflow_approval_status == 'CANCELED' ? WorkflowStepEnum[item.prev_workflow_step_name] || item.prev_workflow_step_name : WorkflowStepEnum[item.workflow_step_name] || item.workflow_step_name }</strong>
                        </span>
                        <span className="date-time">{ " " + DateTimeUtils.formatDateTimeA(item.created_at)}</span>
                        <blockquote className="comment">
                            {item.comment ? '"'+item.comment+'"' : ''}
                        </blockquote>
                        <span className="attachment">
                            { item?.files != "" && item?.files != null ? item?.files?.split(',')?.map(item => (
                                <span className="attachment-item">
                                    <a target={"_blank"} href={CONSTANT_CONFIG.MEDIA_SOURCE + item}><FileName fileId={item} /></a>
                                </span>
                            )) : ''
                        }
                        </span>
                    </Timeline.Item>
                </Timeline>
            )) }
        </div>
    );
}

export default WorkflowTransitionTimelineList;
