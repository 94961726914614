import {AxiosPromise} from "axios";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `gateway`
const endpoints = {
    getClientIp: () => `${RESOURCE_ENDPOINT}/client-ip`,
}

export default class GatewayApi {
    public getClientIp = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getClientIp();
        return HttpService.post(url, payload, params, headers);
    }
}