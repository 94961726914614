import React, {FC, useEffect, useState} from 'react'
import { exportMapFields } from './ExportCollection.fields';
import ExportCommonView from "../../common/ExportCommon.view";
import {ExportScmApi} from "../../../../../api";
import {MathUtils} from "../../../../../utils";
import '../../styles/Export.module.listing.scss';
import './ExportCollection.style.scss';

const initialState  = {
    activeStep: 0,
    stopExport: false,
    exportCompleted: false,

    currentStep: 1,
    stepLimit: 20,

    fileName: '',
    exportMode: 'EXPORT_ALL_PAGES',
    exportType: 'csv',
    exportFieldType: 'all',
    orientationPage: 'P',
    offset: null,
    limit: null,

    // Step Mapping
    mapFields: [],
    logData: [],

    // Step Exporting
    currentExportRecord:0,
    progressPercent: 0,
    totalRecords: 0,
    remainingRecords:0,

    exportedCount: 0,
    skippedCount: 0,
    failedCount: 0,

    loading: false,
    isSubmitting: false,
}

const ExportCollectionController: FC<any> = props => {
    const { params } = props;

    const [activeStep, setActiveStep] = useState(initialState.activeStep);
    const [stopExport, setStopExport] = useState(initialState.stopExport);
    const [exportCompleted, setExportCompleted] = useState(initialState.exportCompleted);

    const [currentStep, setCurrentStep] = useState(initialState.currentStep);
    const [stepLimit, setStepLimit] = useState(initialState.stepLimit);

    const [fileName, setFileName] = useState(initialState.fileName);
    const [exportMode, setExportMode] = useState(initialState.exportMode);
    const [exportType, setExportType] = useState(initialState.exportType);
    const [exportFieldType, setExportFieldType] = useState(initialState.exportFieldType);
    const [orientationPage, setOrientationPage] = useState(initialState.orientationPage);
    const [recordPerPage, setRecordPerPage] = useState(initialState.stepLimit);
    const [offset, setOffset] = useState<any>(initialState.offset);
    const [limit, setLimit] = useState<any>(initialState.limit);

    const [logData, setLogData] = useState<any[]>(initialState.logData);
    const [mapFields, setMapFields] = useState<any>(exportMapFields);

    const [currentExportRecord, setCurrentExportRecord] = useState(initialState.currentExportRecord);
    const [totalRecords, setTotalRecords] = useState(initialState.totalRecords);
    const [remainingRecords, setRemainingRecords] = useState(initialState.totalRecords);
    const [progressPercent, setProgressPercent] = useState(initialState.progressPercent);

    const [exportedCount, setExportedCount] = useState<any>(initialState.exportedCount);
    const [skippedCount, setSkippedCount] = useState<any>(initialState.skippedCount);
    const [failedCount, setFailedCount] = useState<any>(initialState.failedCount);

    const [loading, setLoading] = useState(initialState.loading);
    const [isSubmitting, setIsSubmitting] = useState(initialState.isSubmitting);
    const [reloadStep, setReloadStep] = useState<number>(Date.now());

    useEffect(() => {
        if (exportType == 'pdf') {
            setFileName(`collection_${Date.now()}.pdf`);
        }
        else {
            setFileName(`collection_${Date.now()}.csv`);
        }

    }, [exportType])

    useEffect(() => {
        if (recordPerPage) {
            setStepLimit(recordPerPage);
        }
    }, [recordPerPage])

    useEffect(() => {
        if (exportMode == 'EXPORT_CURRENT_PAGE') {
            setOffset(params?.$skip);
            setLimit(params?.$top);
        }
        else {
            setOffset(initialState.offset);
            setLimit(initialState.limit);
        }
    }, [exportMode])

    useEffect(() => {
        if (fileName && activeStep == 2 && !stopExport) {
            if (currentExportRecord == 0) {
                exportData();
            }
            else if (totalRecords > currentExportRecord && currentExportRecord > 0) {
                exportDataLoop();
            }
            else {
                setExportCompleted(true);
            }
        }
    }, [fileName, activeStep, stopExport, currentExportRecord])

    const handleExportOptionActions = () => {
        setActiveStep(1);
    };

    const calculateSkip = () => {
        const localSkip = stepLimit * (currentStep - 1);
        const localOffset = Number(offset) || 0;
        return Number(localSkip) + Number(localOffset);
    }

    const calculateTop = () => {
        const localTop = stepLimit;
        const localLimit = Number(limit) || totalRecords;

        if (localLimit > 0 && (localTop * currentStep) >= localLimit) {
            return localLimit - (localTop * (currentStep - 1));
        }
        else {
            return localTop;
        }
    }

    const countTotal = (total) => {
        const localLimit = limit || 0;
        if (localLimit == 0 || localLimit >= total) {
            return total;
        }
        else {
            return localLimit;
        }
    }

    const preparePayload = () => {
        if (exportMode == 'EXPORT_SELECTED_RECORDS' && params?.$selectedIds) {
            return {
                fileName: fileName,
                exportType: exportType,
                exportMode: exportMode,
                fields: mapFields,
                orientationPage: orientationPage,
                queryOptions: {
                    select: params?.$select || '',
                    search: '',
                    filter: `id IN(${params?.$selectedIds.join(',')})`,
                    queryParams: '',
                    expand: '',
                    orderBy: '',
                    top: '',
                    skip: '',
                    currentStep: currentStep,
                },
            }
        }
        else {
            return {
                fileName: fileName,
                exportType: exportType,
                exportMode: exportMode,
                fields: mapFields,
                orientationPage: orientationPage,
                queryOptions: {
                    select: params?.$select || '',
                    search: params?.$search || '',
                    filter: params?.$filter || '',
                    queryParams: params?.$queryParams || '',
                    expand: params?.$expand || '',
                    orderBy: params?.$orderby || '',
                    top: calculateTop(),
                    skip: calculateSkip(),
                    currentStep: currentStep,
                },
            }
        }
    }

    const exportData = (): void => {
        setLoading(true);
        const payload = preparePayload();
        ExportScmApi.exportCollection(payload)
            .then(res => {
                const updatedTotalCount = countTotal(res.data.totalCount);
                const updatedCurrentStep = currentStep + 1;
                let currentExportRecord = currentStep * stepLimit;
                if (currentExportRecord > updatedTotalCount) {
                    currentExportRecord =  updatedTotalCount;
                }
                const remainingExportRecord = updatedTotalCount - currentExportRecord;
                const updatedProgressPercent = MathUtils.progressPercent(updatedTotalCount, currentExportRecord);
                const updatedExportedCount = currentExportRecord;

                setTotalRecords(updatedTotalCount);
                setCurrentStep(updatedCurrentStep);

                setCurrentExportRecord(currentExportRecord);
                setRemainingRecords(remainingExportRecord);
                setProgressPercent(updatedProgressPercent);
                setExportedCount(updatedExportedCount);

                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                console.log(err)
            });
    }

    const exportDataLoop = (): void => {
        const payload = preparePayload();
        ExportScmApi.exportCollection(payload)
            .then(res => {
                const updatedCurrentStep = currentStep + 1;
                let currentExportRecord = currentStep * stepLimit;
                if (currentExportRecord > totalRecords) {
                    currentExportRecord =  totalRecords;
                }
                const remainingExportRecord = totalRecords - currentExportRecord;
                const updatedProgressPercent = MathUtils.progressPercent(totalRecords, currentExportRecord);
                const updatedExportedCount = currentExportRecord;

                setCurrentStep(updatedCurrentStep);
                setCurrentExportRecord(currentExportRecord);
                setRemainingRecords(remainingExportRecord);
                setProgressPercent(updatedProgressPercent);
                setExportedCount(updatedExportedCount);

            })
            .catch(err => {
                console.log(err)
            });
    }

    const handleMapFieldActions = () => {
        setActiveStep(2);
    };

    const handleOnChangedSelectedFields = (field: any, value: any, index: any) => {
        setMapFields(prevMapFields => {
            const data = {
                [field]: value,
            }

            prevMapFields[index] = {...prevMapFields[index], ...data};
            return [...prevMapFields];
        })
    };

    const handleOnChanged = (fieldName: string, value: any, text?: any) => {
        if(fieldName === 'exportMode') {
            setExportMode(value);
        }
        else if(fieldName === 'exportType') {
            setExportType(value);
        }
        else if(fieldName === 'exportFieldType') {
            setExportFieldType(value);
        }
        else if(fieldName === 'orientationPage') {
            setOrientationPage(value);
        }
        else if(fieldName === 'recordPerPage') {
            setRecordPerPage(value);
        }
        else if(fieldName === 'offset') {
            setOffset(value);
        }
        else if(fieldName === 'limit') {
            setLimit(value);
        }
    }

    const handleReloadStep = () => {
        setReloadStep(Date.now());
    };

    const handleStopExport = () => {
        setStopExport(!stopExport);
    };

    return (
        <div className="listing-page-container listing-page-container-export-collection">
            <ExportCommonView
                loading={loading}
                isSubmitting={isSubmitting}
                activeStep={activeStep}
                reloadStep={reloadStep}
                stopExport={stopExport}
                exportCompleted={exportCompleted}

                fileName={fileName}
                exportMode={exportMode}
                exportType={exportType}
                exportFieldType={exportFieldType}
                orientationPage={orientationPage}
                recordPerPage={recordPerPage}
                offset={offset}
                limit={limit}

                logData={logData}
                mapFields={mapFields}

                currentExportRecord={currentExportRecord}
                totalRecords={totalRecords}
                remainingRecords={remainingRecords}
                progressPercent={progressPercent}

                exportedCount={exportedCount}
                skippedCount={skippedCount}
                failedCount={failedCount}

                handleStopExport={handleStopExport}
                handleOnChanged={handleOnChanged}
                handleExportOptionActions={handleExportOptionActions}
                handleOnChangedSelectedFields={handleOnChangedSelectedFields}
                handleMapFieldActions={handleMapFieldActions}
            />
        </div>
    );
}

export default ExportCollectionController;
