import React, {createContext, useReducer, useEffect   } from 'react'

import initialState from './auth.state';
import authReducer from './auth.reducer';
import {
  CLEAR_ALL_AUTH_STATE,
  LOADED_PROFILE,
  LOADED_GROUP,
  LOADED_ROLE,
  LOADED_WORKSPACE,
  LOADED_ORGANIZATION,
  LOADED_AUTH_STATE,
  LOADED_SCOPE,
  LOADED_WORKFLOW,
  CLEAR_AUTH_STATE
} from './auth.types';
import { HttpService } from "../../services/http.services";
import { StorageService } from "../../services";
import {GroupApi, OauthApi, OrganizationApi, RoleApi, WorkflowStepApi, WorkspaceApi} from "../../api";

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, dispatchAuth] = useReducer(authReducer, initialState);

  useEffect(() => {
    loadAuthState();
  }, []);

  useEffect(() => {
    if (state.userId) {
      loadScopesInfo(state.userId)
    }
    if (state.userId) {
      loadProfileInfo()
    }
    if (state.groupId) {
      loadGroupInfo(state.groupId)
    }
    if (state.roleId) {
      loadRoleInfo(state.roleId)
    }
    if (state.workspaceId) {
      loadWorkspaceInfo(state.workspaceId)
    }
    if (state.organizationId) {
      loadOrganizationInfo(state.organizationId)
    }
    if (state.userId && state.roleId) {
      loadUserWorkflowStep(state.userId, state.roleId);
    }
  }, [state.userId, state.roleId]);

  const loadAuthState = async () => {
    try {
      const accessToken = await StorageService.getAccessToken();
      if (accessToken) {
        HttpService.setAccessToken(accessToken);
      }
      else {
        throw new Error("Error loading auth state");
      }

      OauthApi.loadAuthState()
          .then(res => {
            // User
            dispatchAuth({
              type: LOADED_AUTH_STATE,
              payload: {
                user: res.data,
                userId: res.data.id,
                profileId: res.data.profile_id,
                groupId: res.data.group_id,
                roleId: res.data.role_id,
                workspaceId: res.data.workspace_id,
                organizationId: res.data.organization_id,
                profileType: res.data.profile_type,
                profileImage: res.data.profile_image,
              }
            });
          })
          .catch(err => {
            dispatchAuth({type: CLEAR_ALL_AUTH_STATE });
            dispatchAuth({type: CLEAR_AUTH_STATE });
          });
    }
    catch (err) {
      dispatchAuth({type: CLEAR_ALL_AUTH_STATE});
      dispatchAuth({type: CLEAR_AUTH_STATE});
    }
  }

  const loadScopesInfo = (userId: any) => {
    const payload = {
      'user_id': userId,
    }
    OauthApi.loadUserScopes(payload)
        .then(res => {
          dispatchAuth({
            type: LOADED_SCOPE,
            payload: {
              scopes: res.data,
            }
          });
        });
  }

  const loadProfileInfo = () => {
    OauthApi.loadProfileState()
        .then(res => {
          dispatchAuth({
            type: LOADED_PROFILE,
            payload: {
              profile: res.data,
              profileCode: res.data?.code,
              profileDesignation: res.data?.designation_name,
            }
          });
        });
  }

  const loadGroupInfo = (groupId: any) => {
    GroupApi.getById(groupId)
        .then(res => {
          dispatchAuth({
            type: LOADED_GROUP,
            payload: {
              group: res.data.group,
              groupId: res.data.id,
              userGroup: res.data.code,
            }
          });
        });
  }

  const loadRoleInfo = (roleId: any) => {
    RoleApi.getById(roleId)
        .then(res => {
          dispatchAuth({
            type: LOADED_ROLE,
            payload: {
              role: res.data,
              roleId: res.data.id,
              userRole: res.data.code,
            }
          });
        });
  }

  const loadWorkspaceInfo = (workspaceId: any) => {
    WorkspaceApi.getById(workspaceId)
        .then(res => {
          dispatchAuth({
            type: LOADED_WORKSPACE,
            payload: {
              workspaceId: res.data.id,
              workspace: res.data,
            }
          });
        });
  }

  const loadOrganizationInfo = (organizationId: any) => {
    OrganizationApi.getById(organizationId)
        .then(res => {
          dispatchAuth({
            type: LOADED_ORGANIZATION,
            payload: {
              organizationId: res.data.id,
              organization: res.data,
            }
          });
        });
  }

  const loadUserWorkflowStep = (userId: any, roleId: any) => {
    const payload = {
      'user_id': userId,
      'role_id': roleId,
    }
    WorkflowStepApi.loadUserWorkflowStep(payload)
        .then(res => {
          dispatchAuth({
            type: LOADED_WORKFLOW,
            payload: {
              userWorkflowSteps: res.data,
            }
          });
        });
  }

  const clearAuthState = () => {
    dispatchAuth({type: CLEAR_ALL_AUTH_STATE});
    dispatchAuth({type: CLEAR_AUTH_STATE});
  }

  const setLoading = (isShow:boolean): void => {
    if(isShow){
      dispatchAuth({
        type: 'SET_LOADING',
        payload: {
          loading: true
        }
      });
    }
    else {
      dispatchAuth({
        type: 'SET_LOADING',
        payload: {
          loading: false
        }
      });
    }
  }

  return (
      <AuthContext.Provider value={{ ...state, dispatchAuth, loadAuthState, clearAuthState, setLoading}}>
        {children}
      </AuthContext.Provider>
  );
};

export default AuthProvider;
