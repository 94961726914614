import React, {FC} from 'react'
import {Descriptions, Row, Col, Collapse, Image} from 'antd';
import {DateTimeUtils, NumberUtils, StringUtils} from "../../../../../utils";
import { CONSTANT_CONFIG } from '../../../../../constants';

const CollectionViewTabInfo: FC<any> = props => {
    const DescriptionsItem = Descriptions.Item;
    const { Panel } = Collapse;
    const {itemData} = props;
    return (
        <div className="collection-info-tabs-content">
            <Collapse defaultActiveKey={[1]} expandIconPosition="left">
                <Panel key="1" header="Collection Information">
                    <div className="collapse-panel-content">
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Descriptions bordered column={1}>
                                    <DescriptionsItem label="Ref ID">{itemData.doc_id}</DescriptionsItem>
                                    <DescriptionsItem label="Employee">{itemData.employee_name}</DescriptionsItem>
                                    <DescriptionsItem label="Customer">{itemData.dealer_name}</DescriptionsItem>
                                    <DescriptionsItem label="Amount">{NumberUtils.formatNumber(itemData.amount)}</DescriptionsItem>
                                    <DescriptionsItem label="Collection Date">{DateTimeUtils.formatDate(itemData.date)}</DescriptionsItem>
                                    <DescriptionsItem label="Payment Mode">{itemData.payment_mode}</DescriptionsItem>
                                    <DescriptionsItem label="Bank Name">{itemData.bank_name}</DescriptionsItem>
                                    <DescriptionsItem label="Branch Name">{itemData.branch_name}</DescriptionsItem>
                                    <DescriptionsItem label="Attached File">
                                        {itemData.file && (
                                            <Image
                                                height={100}
                                                src={CONSTANT_CONFIG.CDN_URL + 'image/' + itemData?.file}
                                            />
                                        )}
                                    </DescriptionsItem>
                                    <DescriptionsItem label="Note">{itemData.comment}</DescriptionsItem>
                                    <DescriptionsItem label="Verified Date">{DateTimeUtils.formatDate(itemData.verified_at)}</DescriptionsItem>
                                    <DescriptionsItem label="Created Time">{DateTimeUtils.formatDateTimeA(itemData.created_at)}</DescriptionsItem>
                                    <DescriptionsItem label="Updated Time">{DateTimeUtils.formatDateTimeA(itemData.updated_at)}</DescriptionsItem>
                                    <DescriptionsItem label="Status">{StringUtils.getWorkflowApprovalStatus(itemData)}</DescriptionsItem>
                                </Descriptions>
                            </Col>
                        </Row>
                    </div>
                </Panel>
            </Collapse>
        </div>
    );
}
export default React.memo(CollectionViewTabInfo);