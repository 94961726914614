import React, {FC} from 'react'
import {Descriptions, Row, Col, Collapse} from 'antd';
import {DateTimeUtils, NumberUtils} from "../../../../../utils";
import {CreditTypeEnum, YesNoEnum} from "../../../../../utils/enums";
import SalesOrderItemContentListView from "../Item/Content/SalesOrderItemContentList.view";
import SalesOrderCreateTabPricing from "./SalesOrderCreateTab.pricing";
import {CONSTANT_CONFIG} from "../../../../../constants";
import FileName from "../../../../../components/File/FileName";

const SalesOrderViewTabInfo: FC<any> = props => {
    const { Panel } = Collapse;
    const DescriptionsItem = Descriptions.Item;
    const {itemData, employeeInfo, customerInfo, reloadView} = props;

    return (
        <div className="dealer-info-tabs-content">
            <Collapse defaultActiveKey={[2]} expandIconPosition="left">
                <Panel key="1" header={<span>{'Sales Order Information'}</span>}>
                    <div className="collapse-panel-content">
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <h3>Dealer/Client Info</h3>
                                <Descriptions bordered column={1}>
                                    <DescriptionsItem label="Dealer/Client">{customerInfo.org_name + ` (${customerInfo.code})`}</DescriptionsItem>
                                    <DescriptionsItem label="Credit Type">{CreditTypeEnum[itemData.credit_type] || itemData.credit_type}</DescriptionsItem>
                                    <DescriptionsItem label="Contact Person">{customerInfo.contact_person}</DescriptionsItem>
                                    <DescriptionsItem label="Address">{customerInfo.address}</DescriptionsItem>
                                    <DescriptionsItem label="Phone">{customerInfo.contact_number}</DescriptionsItem>
                                    <DescriptionsItem label="TSE Name">{employeeInfo?.name}</DescriptionsItem>
                                    <DescriptionsItem label="Work Order (Attachment)">
                                        { itemData?.file != "" && itemData?.file != null && (
                                            <span className="attachment-item">
                                                <a target={"_blank"} href={CONSTANT_CONFIG.MEDIA_SOURCE + itemData?.file}><FileName fileId={itemData?.file} /></a>
                                            </span>
                                        )}
                                    </DescriptionsItem>
                                </Descriptions>
                            </Col>
                            <Col span={8}>
                                <h3>SO Info</h3>
                                <Descriptions bordered column={1}>
                                    <DescriptionsItem label="SO Creation Time">{DateTimeUtils.formatDateTimeA(itemData.created_at)}</DescriptionsItem>
                                    <DescriptionsItem label="SO Number">{itemData.sales_order_no}</DescriptionsItem>
                                    <DescriptionsItem label="Selling Unit">{itemData.selling_unit}</DescriptionsItem>
                                    <DescriptionsItem label="SO Qty">{itemData.so_qty}</DescriptionsItem>
                                    <DescriptionsItem label="Free Bag Offer">{YesNoEnum[itemData.free_bag_offer]}</DescriptionsItem>
                                    <DescriptionsItem label="Bag Qty (Billed)">{NumberUtils.formatNumber(itemData.so_billed_qty, true)}</DescriptionsItem>
                                    <DescriptionsItem label="Bag Qty (Actual)">{NumberUtils.formatNumber(itemData.so_actual_qty, true)}</DescriptionsItem>
                                    {itemData.commitment_amount && (
                                        <>
                                            <DescriptionsItem label="Commitment Date">{DateTimeUtils.formatDate(itemData.commitment_date)}</DescriptionsItem>
                                            <DescriptionsItem label="Commitment Amount">{NumberUtils.formatNumber(itemData.commitment_amount)}</DescriptionsItem>
                                        </>
                                    )}
                                </Descriptions>
                            </Col>
                            <Col span={8}>
                                <h3>Dealer/Client Summary</h3>
                                <Descriptions bordered column={1}>
                                    <DescriptionsItem label="Amount Credit Limit (ACL)">{NumberUtils.formatNumber(itemData.amount_credit_limit)}</DescriptionsItem>
                                    <DescriptionsItem label="Closing Balance">{NumberUtils.formatNumber(itemData.closing_balance)}</DescriptionsItem>
                                    <DescriptionsItem label="Days Credit Limit (DCL)">{itemData.days_credit_limit} days</DescriptionsItem>
                                    <DescriptionsItem label="DSO">{NumberUtils.formatNumber(itemData.dso)} days</DescriptionsItem>
                                    {itemData.sales_order_type === 'SPECIAL_SALES_ORDER' && (
                                        <>
                                            <DescriptionsItem label="Exceeded (ACL)">{NumberUtils.formatNumber(customerInfo.acl_exceed_amount) + ` (${NumberUtils.formatNumber(customerInfo.acl_exceed_amount_percentage)}%)`}</DescriptionsItem>
                                            <DescriptionsItem label="Exceeded (DCL)">{NumberUtils.formatNumber(customerInfo.dcl_exceed_days) + ` (${NumberUtils.formatNumber(customerInfo.dcl_exceed_days_percentage)}%)`}</DescriptionsItem>
                                        </>
                                    )}
                                    <DescriptionsItem label="Rolling (%)">{NumberUtils.formatNumber(itemData.rolling)} %</DescriptionsItem>
                                    <DescriptionsItem label="Block Amount">{NumberUtils.formatNumber(itemData.block_amount)}</DescriptionsItem>
                                    <DescriptionsItem label="Today's Collection">{NumberUtils.formatNumber(itemData.today_collection)}</DescriptionsItem>
                                    <DescriptionsItem label="Pending Qty">{NumberUtils.formatNumber(itemData.pending_qty)}</DescriptionsItem>
                                </Descriptions>
                            </Col>
                        </Row>
                    </div>
                </Panel>
            </Collapse>

            <Collapse defaultActiveKey={[2]} expandIconPosition="left">
                <Panel key="2" header="Delivery Schedule">
                    <div className="collapse-panel-content">
                        <SalesOrderItemContentListView itemData={itemData} salesOrderId={itemData.id} reloadView={reloadView} />
                    </div>
                </Panel>
            </Collapse>

            {customerInfo.customer_group === 'TRADE' && (
                <Collapse defaultActiveKey={[2]} expandIconPosition="left">
                    <Panel key="3" header="Pricing">
                        <div className="collapse-panel-content">
                            <SalesOrderCreateTabPricing
                                customerId={itemData.customer_id}
                                salesOrderId={itemData.id}
                                parentSalesOrderId={itemData.parent_sales_order_id}
                            />
                        </div>
                    </Panel>
                </Collapse>
            )}
        </div>
    );
}
export default React.memo(SalesOrderViewTabInfo);