export enum ActionTypes {
    SET_RESET_PASSWORD_LINK      = 'SET_RESET_PASSWORD_LINK',
    SET_ACCOUNT_ACTIVATION_LINK  = 'SET_ACCOUNT_ACTIVATION_LINK',
}

export const ActionType = {
    ...ActionTypes,
}

export interface ResetPasswordLinkAction {
    type: typeof ActionTypes.SET_RESET_PASSWORD_LINK;
    payload: string;
}

export interface AccountActivationLinkAction {
    type: typeof ActionTypes.SET_ACCOUNT_ACTIVATION_LINK;
    payload: string;
}

export type Action =
    ResetPasswordLinkAction
    | AccountActivationLinkAction;

export interface IState {
    resetPasswordLink: string;
    accountActivationLink: string;
}