import React, {FC, useEffect, useState} from 'react'
import {Table} from "antd";
import {DateTimeUtils, Message, NumberUtils} from "../../../../../../utils";
import {SalesOrderItemApi} from "../../../../../../api";
import {DeliveryModeEnum, RequirementTypeEnum, BagTypeEnum, CustomerTypeEnum} from '../../../../../../utils/enums';
import '../../../../styles/Order.module.listing.scss';
import '../List/SalesOrderItemList.style.scss';

const SalesOrderItemContentListView: FC<any> = props => {
    const { itemData, salesOrderId, reloadView } = props;
    const [listData, setListData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (salesOrderId) {
            loadData();
        }
    }, [salesOrderId, reloadView])

    const loadData = (): void => {
        setLoading(true);
        const payload = {
            '$filter': processFilters(),
            '$orderby': processOrderBy(),
        }
        SalesOrderItemApi.list(payload)
            .then(res => {
                setListData(res.data.results);
                setLoading(false);
            })
            .catch(err => {
                console.error("error", err);
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
            });
    }

    const processFilters = (): string => {
        let filterString;
        filterString = "sales_order_id='" + salesOrderId + "'";
        return filterString
    }

    const processOrderBy = (): string => {
        let orderByString
        orderByString = 'id desc';
        return orderByString
    }

    const tableSummary = (pageData) => {
        let totalBilledBagQty = 0;
        let totalActualBagQty = 0;
        let totalPrice = 0;
        pageData.forEach(({ schedule_billed_qty,  schedule_actual_qty, unit_price}) => {
            totalBilledBagQty += NumberUtils.toNumber(schedule_billed_qty);
            totalActualBagQty += NumberUtils.toNumber(schedule_actual_qty);
            totalPrice += NumberUtils.toNumber(unit_price * schedule_actual_qty);
        });

        return (
            <>
                <tr>
                    <td colSpan={5}>Total</td>
                    <td colSpan={1}>{NumberUtils.formatNumberInteger(totalBilledBagQty)}</td>
                    <td colSpan={1}>{NumberUtils.formatNumberInteger(totalActualBagQty)}</td>
                    <td colSpan={3}>{NumberUtils.formatNumberInteger(totalPrice)}</td>
                </tr>
            </>
        );
    }

    const columns = [
        {
            dataIndex: "id",
            key: 'id',
            title: "SN",
            render: (text: string, record: any, index:number) => {
                return index + 1
            },
        },
        {
            dataIndex: "customer_name",
            key: 'customer_name',
            title: "Customer Info",
            render: (text: string, record: any, index:number) => {
                let resultText = '';
                if (record?.project_org_name) {
                    resultText += 'PROJECT -';
                    resultText += '<br />';
                    resultText += record.project_org_name;
                    resultText += ` (${record.project_code})`;
                }
                else {
                    resultText += CustomerTypeEnum[record.customer_type] || record.customer_type;
                    resultText += '<br />';
                    resultText += text;
                    resultText += ` (${record.customer_code})`;
                }
                resultText += '<br />';
                resultText += record.contact_number;
                return <div dangerouslySetInnerHTML={{ __html: resultText }} />;
            },
        },
        {
            dataIndex: "adjustment_type",
            key: 'adjustment_type',
            title: "Product Information",
            render: (text: string, record: any, index:number) => {
                let resultText = '';
                resultText += 'Product Type: ';
                resultText += record.product_type;
                resultText += '<br />';
                resultText += 'Requirement Type: ';
                resultText += RequirementTypeEnum[record.requirement_type] || record.requirement_type;
                if (record.bag_type) {
                    resultText += '<br />';
                    resultText += 'Bag Type: ';
                    resultText += BagTypeEnum[record.bag_type] || record.bag_type;
                }
                return <div dangerouslySetInnerHTML={{ __html: resultText }} />;
            },
        },
        {
            dataIndex: "adjustment_type",
            key: 'adjustment_type',
            title: "Delivery Information",
            render: (text: string, record: any, index:number) => {
                let resultText = '';
                resultText += 'Mode: ';
                resultText += DeliveryModeEnum[record.delivery_mode] || record.delivery_mode;
                resultText += '<br />';
                resultText += 'Warehouse: ';
                resultText += record.warehouse;
                resultText += '<br />';
                resultText += record.location_type == 'SHOP' ?  'Shop: ' : 'Site: ';
                resultText += record.delivery_address;
                return <div dangerouslySetInnerHTML={{ __html: resultText }} />;
            },
        },
        {
            dataIndex: "selling_unit",
            key: 'selling_unit',
            title: "Selling Unit",
        },
        {
            dataIndex: "schedule_billed_qty",
            key: 'schedule_billed_qty',
            title: "Schedule Qty (Billed)",
        },
        {
            dataIndex: "schedule_actual_qty",
            key: 'schedule_actual_qty',
            title: "Schedule Qty (Actual)",
        },
        {
            dataIndex: "unit_price",
            key: 'unit_price',
            title: "Unit Price",
            render: (value) => NumberUtils.formatNumber(value),
        },
        {
            dataIndex: "vehicle_capacity",
            key: 'vehicle_capacity',
            title: "Vehicle Capacity",
        },
        {
            dataIndex: "delivery_date_time",
            key: 'delivery_date_time',
            title: "Delivery Time",
            render: (value) => DateTimeUtils.formatDateTimeA(value),
        },
    ];

    return (
        <div className="listing-content listing-content-sales-order-item">
            <Table
                className="table-layout"
                rowKey={record => record.id.toString()}
                rowClassName={(record, index) => (index % 2 === 0 ? 'odd' : 'even')}
                dataSource={listData}
                columns={columns}
                pagination={false}
                loading={loading}
                bordered={false}
                summary={tableSummary}
            />
        </div>
    );
}

export default React.memo(SalesOrderItemContentListView);