import { useState, useEffect, useContext } from 'react'
import {DataContext} from "../../context/data/data.context";

export const useAdjustmentTypeContext = () => {
    const dataContext = useContext(DataContext)
    const [adjustmentTypeContext, setAdjustmentTypeContext] = useState<any>([]);
    const [adjustmentTypeList, setAdjustmentTypeList] = useState<any>([]);
    const [activeAdjustmentTypeList, setActiveAdjustmentTypeList] = useState<any>([]);

    useEffect(() => {
        setAdjustmentTypeContext(dataContext.adjustmentTypeContext);

        if (!dataContext.adjustmentTypeContext.isLoaded) {
            dataContext.loadAdjustmentType()
        }
        else {
            const items = dataContext.adjustmentTypeContext.items
            loadAdjustmentTypeList(items);
            loadActiveAdjustmentTypeList(items);
        }
    }, [dataContext.adjustmentTypeContext.isLoaded]);

    const loadAdjustmentTypeList = (list: any) => {
        setAdjustmentTypeList(list);
    }

    const loadActiveAdjustmentTypeList = (list: any) => {
        const activeList = list.filter(item => item.status === 1)
        setActiveAdjustmentTypeList(activeList);
    }

    const getAdjustmentTypeById = (id) => {
        if(!adjustmentTypeList) {
            return;
        }
        return adjustmentTypeList.find(item => item.key === id)
    }

    const setAdjustmentTypeFieldValue = (formRef, key, value) => {
        if (activeAdjustmentTypeList?.find(item => item.key ===  value )) {
            formRef.setFieldsValue({[key]: value})
        }
        else {
            formRef.setFieldsValue({[key]: null})
        }
    }

    return { adjustmentTypeContext, adjustmentTypeList, activeAdjustmentTypeList, setAdjustmentTypeFieldValue, getAdjustmentTypeById }
}