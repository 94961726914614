const initialState = {
  countNotificationBadge: 0,
  countSalesOrderBadge: 0,
  countDeliveryOrderBadge: 0,
  countChalanBadge: 0,
  countCollectionBadge: 0,
  countCreditLimitBadge: 0,
  countRetailerBadge: 0,
};

export default initialState;
