import React, {FC} from 'react'
import {Descriptions, Row, Col} from 'antd';
import {YesNoEnum} from "../../../../../utils/enums";
import {NumberUtils} from "../../../../../utils";

const SalesOrderCreateTabCustomer: FC<any> = props => {
    const {employeeInfo, customerInfo, salesOrderData, scheduleSoQty} = props;
    return (
        <div className="dealer-info-tabs-content">
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label="SO Number">{salesOrderData.sales_order_no || 'Auto #'}</Descriptions.Item>
                        <Descriptions.Item label="Customer Group">{customerInfo.customer_group}</Descriptions.Item>
                        <Descriptions.Item label="TSE Name">{employeeInfo.name}</Descriptions.Item>
                        <Descriptions.Item label="Dealer/Client">{customerInfo.org_name}</Descriptions.Item>
                        <Descriptions.Item label="Delivery Type">{salesOrderData.delivery_type}</Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={8}>
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label="DSO">{NumberUtils.formatNumber(customerInfo.dso)}</Descriptions.Item>
                        <Descriptions.Item label="Block Amount">{NumberUtils.formatNumber(customerInfo.block_amount)}</Descriptions.Item>
                        <Descriptions.Item label="Closing Balance">{NumberUtils.formatNumber(customerInfo.closing_balance)}</Descriptions.Item>
                        <Descriptions.Item label="Amount Credit Limit (ACL)">{NumberUtils.formatNumber(customerInfo.amount_credit_limit)}</Descriptions.Item>
                        <Descriptions.Item label="Days Credit Limit (DCL)">{NumberUtils.formatNumber(customerInfo.days_credit_limit)}</Descriptions.Item>
                    </Descriptions>

                </Col>
                <Col span={8}>
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label="Selling Unit">{salesOrderData.selling_unit}</Descriptions.Item>
                        <Descriptions.Item label="Free Bag Offer">{YesNoEnum[customerInfo.free_bag_offer]}</Descriptions.Item>
                        {(salesOrderData.parent_sales_order_id ||  salesOrderData.base_sales_order_no) ? (
                            <>
                                <Descriptions.Item label="SO Qty">{salesOrderData.so_qty}</Descriptions.Item>
                                <Descriptions.Item label="Remaining SO Qty">{salesOrderData.so_booking_remaining_qty}</Descriptions.Item>
                                <Descriptions.Item label="Scheduled Qty">{scheduleSoQty}</Descriptions.Item>
                            </>
                        ):
                        (
                            <>
                                <Descriptions.Item label="SO Qty (Billed)">{scheduleSoQty}</Descriptions.Item>
                                <Descriptions.Item label="SO Qty (Actual)">{scheduleSoQty}</Descriptions.Item>
                                <Descriptions.Item label="">&nbsp;</Descriptions.Item>
                            </>
                        )}
                    </Descriptions>
                </Col>
            </Row>
        </div>
    );
}
export default React.memo(SalesOrderCreateTabCustomer);