import React, {FC} from 'react'
import {Row, Col, Button, Collapse} from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import SalesOrderItemContentList from "../../Item/Content/SalesOrderItemContent.list";
import {SalesOrderItemAction} from "../../Item/Actions/SalesOrderItem.actions";
import CreateAction from "../../../../../../components/Actions/CreateAction";
import SalesOrderCreateCollapseOrderInfo from "../../Collapse/SalesOrderCreateCollapse.orderInfo";

interface IProps {
    formRef: any,
    loading: any,
    [key: string]: any,
}

const SalesOrderCreateMultiScheduleForm: FC<IProps> = props => {
    const { Panel } = Collapse;
    const {formRef, loading, handleSalesOrderItemCallbackFunc, ...restProps} = props;

    return (
        <div className="form-page-content form-page-content-sales-order">
            <div className="sales-order-next-form">
                <div className="sales-order-form-info" style={{paddingBottom: 15}}>
                    <Collapse defaultActiveKey={[1]} expandIconPosition="left">
                        <Panel key="1" header="Sales Order Information">
                            <SalesOrderCreateCollapseOrderInfo
                                {...restProps}
                                formRef={formRef}
                                loading={loading}
                            />
                        </Panel>
                    </Collapse>
                </div>

                <div className="sales-order-form-delivery-schedule">
                    <Collapse defaultActiveKey={[1]} expandIconPosition="left">
                        <Panel key="1" header="Delivery Order Information">
                            <div className="collapse-panel-content">
                                <Row>
                                    <Col span={24}>
                                        <SalesOrderItemContentList
                                            {...restProps}
                                            formRef={formRef}
                                            loading={loading}
                                            handleEdit={handleSalesOrderItemCallbackFunc}
                                            handleDelete={handleSalesOrderItemCallbackFunc}
                                        />

                                        <div className="delivery-schedule-action">
                                            <CreateAction
                                                {...restProps}
                                                manageUrl={false}
                                                loading={loading}
                                                component={SalesOrderItemAction.COMMON_ACTION.CREATE.component}
                                                actionItem={SalesOrderItemAction.COMMON_ACTION.CREATE}
                                                handleCallbackFunc={handleSalesOrderItemCallbackFunc}
                                            >
                                                <Button type="primary" className="btn btn-create" icon={<PlusOutlined />}>Add DO</Button>
                                            </CreateAction>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </div>
    );
}
export default React.memo(SalesOrderCreateMultiScheduleForm);