import React, { FC, Fragment} from 'react'
import {Button, Drawer, Spin} from 'antd';
import AdjustmentView from "./AdjustmentView.view";
import CustomScrollbar from "../../../../../components/Scrollbar/CustomScrollbar";

const AdjustmentViewDrawer: FC<any> = props => {
    const {isShowView, loading, reloadView, modalTitle, itemData, handleCallbackFunc} = props;
    return (
        <Fragment>
            <Drawer
                width={720}
                zIndex={500}
                className="view-page-drawer view-page-drawer-adjustment"
                title={<b>{modalTitle}&nbsp;&nbsp;{loading && <Spin size="small" />}</b>}
                mask={false}
                visible={isShowView}
                onClose={(event) => handleCallbackFunc(null, 'hideView')}
                footer={[
                    <Button key='close' onClick={(event) => handleCallbackFunc(null, 'hideView')}>Close</Button>,
                ]}
            >
                <CustomScrollbar className="view-page-drawer-scrollbar">
                    <AdjustmentView itemData={itemData} reloadView={reloadView} handleCallbackFunc={handleCallbackFunc} />
                </CustomScrollbar>
            </Drawer>
        </Fragment>
    );
}
export default React.memo(AdjustmentViewDrawer);