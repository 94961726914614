import React, {FC, useEffect, useState} from 'react'
import SalesOrderItemCreateModal from "./SalesOrderItemCreate.modal";
import { useForm } from '../../../../../../hooks/useForm';
import { rules } from './SalesOrderItemCreate.validate';
import {Message, DateTimeUtils, NumberUtils} from "../../../../../../utils";
import {PricingApi} from "../../../../../../api";
import '../../../../styles/Order.module.form.scss';
import './SalesOrderItemCreate.style.scss';

interface IProps {
    isShowForm: any,
    reloadForm: any,
    [key: string]: any,
}

const initialState  = {
    modalTitle: 'New Schedule',
    itemData: {
        id: null,
        sales_order_id: null,
        retailer_id: null,
        project_id: null,
        customer_type: null,
        location_type: null,
        contact_number: null,
        delivery_location_contact_number: null,
        delivery_address: null,
        product_type: null,
        requirement_type: null,
        bag_type: null,
        delivery_mode: null,
        warehouse: null,
        vehicle_capacity: null,
        free_bag_offer: 0,
        schedule_billed_qty: null,
        schedule_actual_qty: null,
        unit_price: null,
        delivery_date_time: null,
    },
    retailerInfo: {
        id: null,
        customer_id: null,
        first_name: null,
        last_name: null,
        org_name: null,
        contact_number: null,
        address: null,
        division_id: null,
        district_id: null,
        thana_id: null,
        status: null,
    },
    isNewRecord: true,
    loading: false,
    loadingRetailer: false,
}

const SalesOrderItemCreateController: FC<IProps> = props => {
    const { entity, entityIndex, isShowForm, deliveryScheduleFormType, salesOrderData, customerInfo, retailerList, itemIndex, reloadForm, handleCallbackFunc, ...restProps } = props
    const { formRef: scheduleFormRef, initialValues, isSubmitting, setIsSubmitting, handleChange, handleSubmitFailed, resetForm } = useForm(initialState.itemData);

    const [btnText, setBtnText] = useState('Add Schedule');
    const [modalTitle, setModalTitle] = useState(initialState.modalTitle);
    const [itemData, setItemData] = useState<any>(initialState.itemData);
    const [isNewRecord, setIsNewRecord] = useState(initialState.isNewRecord);
    const [loading, setLoading] = useState(initialState.loading);
    const [customerName, setCustomerName] = useState(null);
    const [retailerInfo, setRetailerInfo] = useState<any>(initialState.retailerInfo);

    useEffect(() => {
        if ( entity && isShowForm) {
            setModalTitle('Edit Schedule');
            setBtnText('Update Schedule');
            resetForm();
            setItemData(entity);
            setIsNewRecord(false);
            const initFormDta = {
                id: entity.id,
                sales_order_id: entity.sales_order_id,
                customer_name: entity.customer_name,
                retailer_id: entity.retailer_id,
                project_id: entity.project_id,
                customer_type: entity.customer_type,
                location_type: entity.location_type,
                contact_number: entity.contact_number,
                delivery_location_contact_number: entity.delivery_location_contact_number,
                delivery_address: entity.delivery_address,
                product_type: entity.product_type,
                requirement_type: entity.requirement_type,
                bag_type: entity.bag_type,
                delivery_mode: entity.delivery_mode,
                warehouse: entity.warehouse,
                vehicle_capacity: entity.vehicle_capacity,
                free_bag_offer: entity.free_bag_offer,
                schedule_billed_qty: entity.schedule_billed_qty,
                schedule_actual_qty: entity.schedule_actual_qty,
                unit_price: NumberUtils.formatNumber(entity.unit_price),
                delivery_date_time: entity.delivery_date_time ? DateTimeUtils.convertStringToDate(entity.delivery_date_time) : null,
            }
            scheduleFormRef.setFieldsValue(initFormDta)
        }
        else {
            resetForm();
            setModalTitle(initialState.modalTitle);
            setBtnText('Add Schedule');
            setIsNewRecord(initialState.isNewRecord);
            const initFormDta = {
                delivery_mode: customerInfo?.default_delivery_mode,
                customer_id: customerInfo?.id,
                customer_name: customerInfo?.org_name,
                customer_type: customerInfo.customer_type,
                contact_number: customerInfo.contact_number,
                delivery_location_contact_number: customerInfo.contact_number,
                delivery_address: customerInfo.address,
            }
            scheduleFormRef.setFieldsValue(initFormDta);
            setItemData(initFormDta);
            setCustomerName(customerInfo?.org_name);
        }
    }, [entity, itemIndex, customerInfo?.id, reloadForm])

    const loadRetailerInfo = (retailerId): void => {
        const retailerInfo = retailerList.find((item) => item.id == retailerId);
        setRetailerInfo(retailerInfo);
    }

    const handleSubmit = (values: any): void => {
        setLoading(true);
        if (deliveryScheduleFormType === "NonTradeBag" ||  deliveryScheduleFormType === "NonTradeTone") {
            const productUnitPrice = values.unit_price || 0;
            if (entity) {
                handleUpdate(values, productUnitPrice);
            }
            else {
                handleCreate(values, productUnitPrice);
            }
        }
        else {
            const payload = {
                customer_id: customerInfo?.id,
                sales_order_id: salesOrderData?.parent_sales_order_id || entity?.sales_order_id,
                product_type: values.product_type,
                delivery_mode: values.delivery_mode,
                warehouse: values.warehouse,
            }
            PricingApi.getCustomerProductPrice(payload)
                .then(res => {
                    const productUnitPrice = res.data;
                    if (productUnitPrice) {
                        if (entity) {
                            handleUpdate(values, productUnitPrice);
                        }
                        else {
                            handleCreate(values, productUnitPrice);
                        }
                    }
                    else {
                        setLoading(false);
                        Message.error('Price not found. Please select another "Warehouse", "Delivery Mode", "Product Type".', 5);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    Message.error('Price not found. Please select another "Warehouse", "Delivery Mode", "Product Type".', 5);
                });
        }
    }

    const handleCreate = (values: any, productUnitPrice:number): void => {
        setLoading(true);
        setIsSubmitting(true);
        const payload = {
            sales_order_id: values.sales_order_id,
            retailer_id: values.retailer_id,
            project_id: values.project_id,
            customer_type: values.customer_type,
            customer_name: customerName ?? '',
            location_type: values.location_type,
            contact_number: values.contact_number,
            delivery_location_contact_number: values.delivery_location_contact_number,
            delivery_address: values.delivery_address,
            product_type: values.product_type,
            requirement_type: values.requirement_type,
            bag_type: values.bag_type,
            delivery_mode: values.delivery_mode,
            warehouse: values.warehouse,
            vehicle_capacity: values.vehicle_capacity,
            free_bag_offer: values.free_bag_offer || 0,
            schedule_billed_qty: values.schedule_billed_qty,
            schedule_actual_qty: values.schedule_actual_qty || values.schedule_billed_qty,
            unit_price: productUnitPrice,
            delivery_date_time: DateTimeUtils.formatDateTime(values.delivery_date_time, 'YYYY-MM-DD hh:mm:ss'),
        }
        handleCallbackFunc(null, 'hideForm');
        handleCallbackFunc(null, 'addSalesOrderItem', null, payload);
        setLoading(false);
        setIsSubmitting(false);
    }

    const handleUpdate = (values: any, productUnitPrice:number): void => {
        setLoading(true);
        setIsSubmitting(true);
        const payload = {
            id: entity.id,
            sales_order_id: values.sales_order_id,
            retailer_id: values.retailer_id,
            project_id: values.project_id,
            customer_type: values.customer_type,
            customer_name: customerName ?? '',
            location_type: values.location_type,
            contact_number: values.contact_number,
            delivery_location_contact_number: values.delivery_location_contact_number,
            delivery_address: values.delivery_address,
            product_type: values.product_type,
            requirement_type: values.requirement_type,
            bag_type: values.bag_type,
            delivery_mode: values.delivery_mode,
            warehouse: values.warehouse,
            vehicle_capacity: values.vehicle_capacity,
            free_bag_offer: values.free_bag_offer || 0,
            schedule_billed_qty: values.schedule_billed_qty,
            schedule_actual_qty: values.schedule_actual_qty || values.schedule_billed_qty,
            unit_price: productUnitPrice,
            delivery_date_time: DateTimeUtils.formatDateTime(values.delivery_date_time, 'YYYY-MM-DD hh:mm:ss'),
        }

        handleCallbackFunc(null, 'hideForm');
        handleCallbackFunc(null, 'updateSalesOrderItem', entityIndex, payload);
        setLoading(false);
        setIsSubmitting(false);
    }

    const handleCustomerName = (value: any): void => {
        setCustomerName(value);
    }

    return (
        <div className="form-page-container form-page-container-salesOrderItem">
            <SalesOrderItemCreateModal
                {...restProps}
                scheduleFormRef={scheduleFormRef}
                loading={loading}
                rules={rules}
                isNewRecord={isNewRecord}
                itemData={itemData}
                deliveryScheduleFormType={deliveryScheduleFormType}
                customerInfo={customerInfo}
                salesOrderData={salesOrderData}
                retailerInfo={retailerInfo}
                retailerList={retailerList}
                loadRetailerInfo={loadRetailerInfo}
                modalTitle={modalTitle}
                btnText={btnText}
                isSubmitting={isSubmitting}
                isShowForm={isShowForm}
                initialValues={initialValues}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleCustomerName={handleCustomerName}
                handleSubmitFailed={handleSubmitFailed}
                handleCallbackFunc={handleCallbackFunc}
            />
        </div>
    );
}

export default React.memo(SalesOrderItemCreateController);
