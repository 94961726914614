import React, { ReactNode } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import ToDoListSidebar from "../../components/ToDoListSidebar/ToDoListSidebar";
import {Layout} from "antd";
import '../../../../assets/scss/style.scss';

interface Props {
    children: ReactNode;
    title?: string;
}

export const ToDoListLayout: React.FC<Props> = (props: Props) => {
    return (
        <Layout className="app-wrapper">
            <Header />
            <Layout className="to-do-list-layout">
                <LeftSidebar />
                <ToDoListSidebar />
                <Layout.Content className="content-wrapper">
                    <div className="main-content">
                        {props.children}
                    </div>
                </Layout.Content>
            </Layout>
            <Footer />
        </Layout>
    )
}