const initialState = {
  isLoading: false,
  isLoaded: false,
  activeMenuKey: null,
  headerTabGroup: null,
  fields: {
    // General Setting
    site_name: null,
    contact_address: null,
    copyright: null,

    // Email Setting
    admin_sender_name: null,
    admin_sender_email: null,

    // Notification Setting
    send_email_notification: null,
    send_sms_notification: null,
    send_push_notification: null,
  },
  error: null,

  updateSettings: (values) => null,
  setHeaderTabGroup: (tabGroup) => null,
  setActiveMenuKey: (key) => null,
};

export default initialState;
