export const exportMapFields = [
    {
        'field_type': 'virtual',
        'field_name': 'serial_number',
        'field_label': 'Ser No',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 1,
    },
    {
        'field_type': 'normal',
        'field_name': 'doc_id',
        'field_label': 'Ref ID',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 2,
    },
    {
        'field_type': 'normal',
        'field_name': 'employee_name',
        'field_label': 'TSE Name',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 3,
    },
    {
        'field_type': 'virtual',
        'field_name': 'employee_code',
        'field_label': 'TSE ID',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 0,
        'visible': 1,
        'position': 4,
    },
    {
        'field_type': 'virtual',
        'field_name': 'customer_org_name',
        'field_label': 'Customer',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 5,
    },
    {
        'field_type': 'virtual',
        'field_name': 'customer_code',
        'field_label': 'Customer Code',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 6,
    },
    {
        'field_type': 'virtual',
        'field_name': 'customer_group',
        'field_label': 'Customer Group',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 0,
        'visible': 1,
        'position': 7,
    },
    {
        'field_type': 'virtual',
        'field_name': 'customer_client_segment',
        'field_label': 'Client Segment',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 0,
        'visible': 1,
        'position': 8,
    },
    {
        'field_type': 'virtual',
        'field_name': 'payment_mode',
        'field_label': 'Payment Mode',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 9,
    },
    {
        'field_type': 'virtual',
        'field_name': 'amount',
        'field_label': 'Amount',
        'default_value': '',
        'format': 'NUMBER',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 10,
    },
    {
        'field_type': 'normal',
        'field_name': 'date',
        'field_label': 'Collection Date',
        'default_value': '',
        'format': 'DATE',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 11,
    },
    {
        'field_type': 'normal',
        'field_name': 'verified_at',
        'field_label': 'Verified Date',
        'default_value': '',
        'format': 'DATE',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 12,
    },
    {
        'field_type': 'virtual',
        'field_name': 'status',
        'field_label': 'Status',
        'default_value': '',
        'format': 'TEXT',
        'relation_field': '',
        'checked': 1,
        'visible': 1,
        'position': 13,
    }
];