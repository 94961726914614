export const UPDATE_USER_CONTEXT = 'UPDATE_USER_CONTEXT';
export const UPDATE_ROLE_CONTEXT = 'UPDATE_ROLE_CONTEXT';
export const UPDATE_EMPLOYEE_CONTEXT = 'UPDATE_EMPLOYEE_CONTEXT';
export const UPDATE_CUSTOMER_CONTEXT = 'UPDATE_CUSTOMER_CONTEXT';
export const UPDATE_CLIENT_CONTEXT = 'UPDATE_CLIENT_CONTEXT';
export const UPDATE_DEALER_CONTEXT = 'UPDATE_DEALER_CONTEXT';
export const UPDATE_RETAILER_CONTEXT = 'UPDATE_RETAILER_CONTEXT';
export const UPDATE_PROJECT_CONTEXT = 'UPDATE_PROJECT_CONTEXT';
export const UPDATE_DRIVER_CONTEXT = 'UPDATE_DRIVER_CONTEXT';
export const UPDATE_VEHICLE_CONTEXT = 'UPDATE_VEHICLE_CONTEXT';
export const UPDATE_SECURITY_TYPE_CONTEXT = 'UPDATE_SECURITY_TYPE_CONTEXT';
export const UPDATE_ADJUSTMENT_TYPE_CONTEXT = 'UPDATE_ADJUSTMENT_TYPE_CONTEXT';
export const UPDATE_BAG_TYPE_CONTEXT = 'UPDATE_BAG_TYPE_CONTEXT';
export const UPDATE_PAYMENT_MOD_CONTEXT = 'UPDATE_PAYMENT_MOD_CONTEXT';
export const UPDATE_BANK_LIST_CONTEXT = 'UPDATE_BANK_LIST_CONTEXT';

export const UPDATE_DIVISION_CONTEXT = 'UPDATE_DIVISION_CONTEXT';
export const UPDATE_DISTRICT_CONTEXT = 'UPDATE_DISTRICT_CONTEXT';
export const UPDATE_THANA_CONTEXT = 'UPDATE_THANA_CONTEXT';
export const UPDATE_DEPARTMENT_CONTEXT = 'UPDATE_DEPARTMENT_CONTEXT';
export const UPDATE_DESIGNATION_CONTEXT = 'UPDATE_DESIGNATION_CONTEXT';
export const UPDATE_PRODUCT_CONTEXT = 'UPDATE_PRODUCT_CONTEXT';
export const UPDATE_WAREHOUSE_CONTEXT = 'UPDATE_WAREHOUSE_CONTEXT';
export const UPDATE_ADDRESS_CONTEXT = 'UPDATE_ADDRESS_CONTEXT';

export const CLEAR_ALL_DATA_STATE = 'CLEAR_ALL_DATA_STATE';

