import React, {createContext, useContext, useEffect, useReducer} from 'react'

import initialState from './count.state';
import countReducer from './count.reducer';
import {
  UPDATE_NOTIFICATION_COUNTER_BADGE,
  UPDATE_SALES_ORDER_COUNTER_BADGE,
  UPDATE_DELIVERY_ORDER_COUNTER_BADGE,
  UPDATE_CHALAN_COUNTER_BADGE,
  UPDATE_COLLECTION_COUNTER_BADGE,
  UPDATE_CREDIT_LIMIT_COUNTER_BADGE,
  UPDATE_RETAILER_COUNTER_BADGE,
} from './count.types';
import {PushNotificationService} from "../../services";
import {AuthContext} from "../auth/auth.context";
import {SocketContext} from "../socket/socket.context";
import {NotificationApi} from "../../api";

export const CountContext = createContext<any>(initialState);

export const CountProvider = ({ children }) => {
  const { userId } = useContext(AuthContext);
  const { socket } = useContext(SocketContext);
  const [state, dispatchCount] = useReducer(countReducer, initialState);

  useEffect(() => {
    if (userId) {
      handleInitialCounter();
    }
  }, [userId])


  useEffect(() => {
    if (socket && userId) {
      socket.on('event_notification', handleCounter);

      return () => {
        socket.off('event_notification');
      };
    }
  }, [socket, userId]);

  const handleInitialCounter = () => {
    NotificationApi.countBadge(userId)
        .then(res => {
          const count = res.data || 0;
          dispatchCount({
            type: UPDATE_NOTIFICATION_COUNTER_BADGE,
            payload: {
              updateType: 'UPDATE_VALUE',
              value: count,
            }
          });
        });
  }

  const handleCounter = (res: any) => {
    if (res.receiver_id && res.receiver_id == userId) {
      dispatchCount({
        type: UPDATE_NOTIFICATION_COUNTER_BADGE,
        payload: {
          updateType: 'INCREMENT',
        }
      });
      PushNotificationService.showNotification(res.subject, res.message);
    }

    if (res.receiver_id && res.receiver_id == userId && res.notification_type == 'SALES_ORDER' && res.responsibility == 'ACCOUNTABLE') {
      dispatchCount({
        type: UPDATE_SALES_ORDER_COUNTER_BADGE,
        payload: {
          updateType: 'INCREMENT',
        }
      });
    }

    if (res.receiver_id && res.receiver_id == userId && res.notification_type == 'SPECIAL_SALES_ORDER' && res.responsibility == 'ACCOUNTABLE') {
      dispatchCount({
        type: UPDATE_SALES_ORDER_COUNTER_BADGE,
        payload: {
          updateType: 'INCREMENT',
        }
      });
    }

    if (res.receiver_id && res.receiver_id == userId && res.notification_type == 'DELIVERY_ORDER' && res.responsibility == 'ACCOUNTABLE') {
      dispatchCount({
        type: UPDATE_DELIVERY_ORDER_COUNTER_BADGE,
        payload: {
          updateType: 'INCREMENT',
        }
      });
    }

    if (res.receiver_id && res.receiver_id == userId && res.notification_type == 'CHALLAN' && res.responsibility == 'ACCOUNTABLE') {
      dispatchCount({
        type: UPDATE_CHALAN_COUNTER_BADGE,
        payload: {
          updateType: 'INCREMENT',
        }
      });
    }

    if (res.receiver_id && res.receiver_id == userId && res.notification_type == 'COLLECTION' && res.responsibility == 'ACCOUNTABLE') {
      dispatchCount({
        type: UPDATE_COLLECTION_COUNTER_BADGE,
        payload: {
          updateType: 'INCREMENT',
        }
      });
    }

    if (res.receiver_id && res.receiver_id == userId && res.notification_type == 'CREDIT_LIMIT' && res.responsibility == 'ACCOUNTABLE') {
      dispatchCount({
        type: UPDATE_CREDIT_LIMIT_COUNTER_BADGE,
        payload: {
          updateType: 'INCREMENT',
        }
      });
    }

    if (res.receiver_id && res.receiver_id == userId && res.notification_type == 'RETAILER' && res.responsibility == 'ACCOUNTABLE') {
      dispatchCount({
        type: UPDATE_RETAILER_COUNTER_BADGE,
        payload: {
          updateType: 'INCREMENT',
        }
      });
    }

  }

  return (
    <CountContext.Provider value={{
      ...state,
      dispatchCount,
    }}>
      {children}
    </CountContext.Provider>
  );
};

export default CountProvider;
