import { useState, useEffect, useContext } from 'react'
import {DataContext} from "../../context/data/data.context";
import {ProjectApi} from "../../api";

export const useProjectContext = () => {
    const dataContext = useContext(DataContext)
    const [projectContext, setProjectContext] = useState<any>([]);
    const [projectList, setProjectList] = useState<any>([]);
    const [activeProjectList, setActiveProjectList] = useState<any>([]);

    const [selectedProject, setSelectedProject] = useState<any>({});
    const [loadingProject, setLoadingProject] = useState(false);

    const [fetchedProjectInfo, setFetchedProjectInfo] = useState<any>({});
    const [fetchedProjectList, setFetchedProjectList] = useState<any>([]);
    const [isProjectLoading, setIsProjectLoading] = useState(false);

    useEffect(() => {
        setProjectContext(dataContext.projectContext);

        if (!dataContext.projectContext.isLoaded) {
            dataContext.loadProject()
        }
        else {
            const items = dataContext.projectContext.items
            prepareProjectList(items);
            prepareActiveProjectList(items);
        }
    }, [dataContext.projectContext.isLoaded]);

    const prepareProjectList = (list: any) => {
        setProjectList(list);
    }

    const prepareActiveProjectList = (list: any) => {
        const activeList = list.filter(item => item.status === 1)
        setActiveProjectList(activeList);
    }

    const getProjectById = (id) => {
        if(!projectList) {
            return;
        }
        return projectList.find(item => item.id === id)
    }

    const loadProject = (payload = {}): Promise<any> => {
        return new Promise((resolve, reject) => {
            setIsProjectLoading(true);
            ProjectApi.list(payload)
                .then(res => {
                    setFetchedProjectList(res.data.results);
                    setIsProjectLoading(false);
                    resolve(res);
                })
                .catch(err => {
                    setFetchedProjectList([]);
                    setIsProjectLoading(false);
                    reject(err);
                });
        });
    }

    const loadProjectById = (id): Promise<any> => {
        return new Promise((resolve, reject) => {
            setIsProjectLoading(true);
            ProjectApi.getById(id)
                .then(res => {
                    setFetchedProjectInfo(res.data);
                    setIsProjectLoading(false);
                    resolve(res);
                })
                .catch(err => {
                    setFetchedProjectInfo({});
                    setIsProjectLoading(false);
                    reject(err);
                });
        });
    }

    const getFetchedAddressById = (id) => {
        if(!fetchedProjectList) {
            return;
        }
        return fetchedProjectList.find(item => item.id === id)
    }

    const setProjectFieldValue = (formRef, key, value) => {
        if (activeProjectList?.find(item => item.id === Number(value))) {
            formRef.setFieldsValue({[key]: value})
        }
        else {
            formRef.setFieldsValue({[key]: null})
        }
    }

    return {
        projectContext,
        isProjectLoading,
        fetchedProjectInfo,
        fetchedProjectList,
        projectList,
        activeProjectList,

        setProjectFieldValue,
        getProjectById,
        getFetchedAddressById,
        loadProject,
        loadProjectById,
    }
}