import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.SCM_SERVER_PREFIX}/export`
const endpoints = {
    exportDealer: () => `${RESOURCE_ENDPOINT}/dealer`,
    exportCustomer: () => `${RESOURCE_ENDPOINT}/customer`,
    exportClient: () => `${RESOURCE_ENDPOINT}/client`,
    exportEmployee: () => `${RESOURCE_ENDPOINT}/employee`,
    exportCollection: () => `${RESOURCE_ENDPOINT}/collection`,
    exportCreditLimit: () => `${RESOURCE_ENDPOINT}/credit-limit`,
    exportDeliveryOrder: () => `${RESOURCE_ENDPOINT}/delivery-order`,
    exportChallan: () => `${RESOURCE_ENDPOINT}/challan`,
    exportSalesOrder: () => `${RESOURCE_ENDPOINT}/sales-order`,    
    exportGrandTotalReport: () => `${RESOURCE_ENDPOINT}/grand-total-report`,
    exportMonetaryReport: () => `${RESOURCE_ENDPOINT}/monetary-report`,
    exportAgeingReport: () => `${RESOURCE_ENDPOINT}/ageing-report`,
    exportSalesQuantityReport: () => `${RESOURCE_ENDPOINT}/sales-quantity-report`,
    exportDealerAndRetailerWiseSalesQuantityReport: () => `${RESOURCE_ENDPOINT}/dealer-and-retailer-wise-sales-quantity-report`,
    exportClientAndProjectWiseSalesQuantityReport: () => `${RESOURCE_ENDPOINT}/client-and-project-wise-sales-quantity-report`,
    exportSecurity: () => `${RESOURCE_ENDPOINT}/security`,
    exportAdjustment: () => `${RESOURCE_ENDPOINT}/adjustment`,
    exportTarget: () => `${RESOURCE_ENDPOINT}/target`,
    exportOpening: () => `${RESOURCE_ENDPOINT}/opening`,
    exportRetailer: () => `${RESOURCE_ENDPOINT}/retailer`,
    exportProject: () => `${RESOURCE_ENDPOINT}/project`,
    exportDriver: () => `${RESOURCE_ENDPOINT}/driver`,
    exportVehicle: () => `${RESOURCE_ENDPOINT}/vehicle`,
    exportPricing: () => `${RESOURCE_ENDPOINT}/pricing`,
    exportFobTransport: () => `${RESOURCE_ENDPOINT}/fob-transport`,
    exportDealerWiseSalesReport: () => `${RESOURCE_ENDPOINT}/dealer-wise-sales-report`,
    exportTallyWiseDealerSalesReport: () => `${RESOURCE_ENDPOINT}/tally-wise-dealer-sales-report`,
    exportDealerWiseDeliveryReport: () => `${RESOURCE_ENDPOINT}/dealer-wise-delivery-report`,
    exportNonTradePriceHistoryReport: () => `${RESOURCE_ENDPOINT}/non-trade-price-history-report`,
    exportDealerLedgerReport: () => `${RESOURCE_ENDPOINT}/dealer-ledger-report`,
    exportLedgerEventBasisReport: () => `${RESOURCE_ENDPOINT}/ledger-event-basis-report`,
    exportInventoryReport: () => `${RESOURCE_ENDPOINT}/inventory-report`,
    exportChallanReport: () => `${RESOURCE_ENDPOINT}/challan-report`,
    exportMonetaryCommitmentReport: () => `${RESOURCE_ENDPOINT}/monetary-commitment-report`,
    exportProductWiseSalesReport: () => `${RESOURCE_ENDPOINT}/product-wise-sales-report`,
    exportSecurityReport: () => `${RESOURCE_ENDPOINT}/security-report`,
    exportCreditLimitReport: () => `${RESOURCE_ENDPOINT}/credit-limit-report`,
    exportCollectionTargetReport: () => `${RESOURCE_ENDPOINT}/collection-target-report`,
    exportSalesTargetReport: () => `${RESOURCE_ENDPOINT}/sales-target-report`,
}

export default class ExportScmApi {
    public exportDealer = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportDealer();
        return HttpService.post(url, payload, params, headers);
    }
    public exportCustomer = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportCustomer();
        return HttpService.post(url, payload, params, headers);
    }
    public exportClient = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportClient();
        return HttpService.post(url, payload, params, headers);
    }
    public exportEmployee = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportEmployee();
        return HttpService.post(url, payload, params, headers);
    }
    public exportCollection = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportCollection();
        return HttpService.post(url, payload, params, headers);
    }
    public exportCreditLimit = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportCreditLimit();
        return HttpService.post(url, payload, params, headers);
    }
    public exportDeliveryOrder = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportDeliveryOrder();
        return HttpService.post(url, payload, params, headers);
    }
    public exportChallan = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportChallan();
        return HttpService.post(url, payload, params, headers);
    }
    public exportSalesOrder = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportSalesOrder();
        return HttpService.post(url, payload, params, headers);
    }
    public exportMonetaryReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportMonetaryReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportGrandTotalReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportGrandTotalReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportAgeingReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportAgeingReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportSalesQuantityReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportSalesQuantityReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportDealerAndRetailerWiseSalesQuantityReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportDealerAndRetailerWiseSalesQuantityReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportClientAndProjectWiseSalesQuantityReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportClientAndProjectWiseSalesQuantityReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportSecurity = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportSecurity();
        return HttpService.post(url, payload, params, headers);
    }
    public exportAdjustment = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportAdjustment();
        return HttpService.post(url, payload, params, headers);
    }
    public exportTarget = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportTarget();
        return HttpService.post(url, payload, params, headers);
    }
    public exportOpening = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportOpening();
        return HttpService.post(url, payload, params, headers);
    }
    public exportRetailer = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportRetailer();
        return HttpService.post(url, payload, params, headers);
    }
    public exportProject = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportProject();
        return HttpService.post(url, payload, params, headers);
    }
    public exportDriver = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportDriver();
        return HttpService.post(url, payload, params, headers);
    }
    public exportVehicle = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportVehicle();
        return HttpService.post(url, payload, params, headers);
    }
    public exportPricing = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportPricing();
        return HttpService.post(url, payload, params, headers);
    }
    public exportFobTransport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportFobTransport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportDealerWiseSalesReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportDealerWiseSalesReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportTallyWiseDealerSalesReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportTallyWiseDealerSalesReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportDealerWiseDeliveryReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportDealerWiseDeliveryReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportNonTradePriceHistoryReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportNonTradePriceHistoryReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportDealerLedgerReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportDealerLedgerReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportLedgerEventBasisReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportLedgerEventBasisReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportInventoryReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportInventoryReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportChallanReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportChallanReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportMonetaryCommitmentReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportMonetaryCommitmentReport();
        return HttpService.post(url, payload, params, headers);
    }

    public exportProductWiseSalesReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportProductWiseSalesReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportSecurityReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportSecurityReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportCreditLimitReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportCreditLimitReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportCollectionTargetReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportCollectionTargetReport();
        return HttpService.post(url, payload, params, headers);
    }
    public exportSalesTargetReport = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.exportSalesTargetReport();
        return HttpService.post(url, payload, params, headers);
    }
}