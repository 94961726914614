import React, {FC} from 'react';
import { Route } from 'react-router-dom';

const PublicRoute: FC<any> = ({ layout: Layout, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                Layout ? (
                    <Layout {...props}>{Component ? <Component {...props} /> : null}</Layout>
                ) : Component ? (
                    <Component {...props} />
                ) : null
            }
        />
    )
}

export default PublicRoute;