import React, {FC, useEffect, useState} from 'react';
import { Button } from "antd";
import WorkflowTransitionFormController from "../Form/WorkflowTransitionForm.controller";
import {usePermissionContext} from "../../../../../hooks/context/usePermissionContext";

interface IProps {
    children?: any,
    workflowRecordId?: any,
    workflowActionName?: any,
    workflowStepName?: any,
    workflowName?: any,
    workflowStepAndPermissionList?: any,
    aclExceedAmountPercentage?: any,
    dclExceedDaysPercentage?: any,
    titleText?: any,
    btnText?: any,
    confirmText?: any,
    forwardTo?: any,
    submitBtnText?: any,
    handleCallbackFunc?: (event:any, action: string, recordId?:any, data?: any) => void,
    [key: string]: any,
}

const WorkflowTransitionAction: FC<IProps> = props => {
    const { children, workflowRecordId, workflowActionName, workflowStepName, workflowName, workflowStepAndPermissionList, aclExceedAmountPercentage, dclExceedDaysPercentage,  titleText, btnText, submitBtnText, confirmText, handleCallbackFunc } = props;
    const { isPermissionLoaded, hasPermission } = usePermissionContext();
    const [isShowFormModal, setIsShowFormModal] = useState(false);
    const [reloadForm, setReloadForm] = useState<number>(Date.now());
    const [isShowActionButton, setIsShowActionButton] = useState(false);

    useEffect(() => {
        if (isPermissionLoaded) {
            let showActionButton = workflowStepAndPermissionList[workflowStepName] && hasPermission(workflowStepAndPermissionList[workflowStepName]);
            if (aclExceedAmountPercentage && (workflowName === 'SPECIAL_SALES_ORDER' || workflowName === 'NONTRADE_SPECIAL_SALES_ORDER') && workflowStepName === 'HOF' && workflowActionName === 'APPROVE' && Number(aclExceedAmountPercentage) > 10) {
                showActionButton = false;
            }
            else if (dclExceedDaysPercentage && (workflowName === 'SPECIAL_SALES_ORDER' || workflowName === 'NONTRADE_SPECIAL_SALES_ORDER') &&  workflowStepName === 'HOF' && workflowActionName === 'APPROVE' && Number(dclExceedDaysPercentage) > 10) {
                showActionButton = false;
            }
            else if (aclExceedAmountPercentage && (workflowName === 'SPECIAL_SALES_ORDER' || workflowName === 'NONTRADE_SPECIAL_SALES_ORDER') && workflowStepName === 'HOF' && workflowActionName === 'CANCEL' && Number(aclExceedAmountPercentage) > 10) {
                showActionButton = false;
            }
            else if (dclExceedDaysPercentage && (workflowName === 'SPECIAL_SALES_ORDER' || workflowName === 'NONTRADE_SPECIAL_SALES_ORDER') &&  workflowStepName === 'HOF' && workflowActionName === 'CANCEL' && Number(dclExceedDaysPercentage) > 10) {
                showActionButton = false;
            }
            else if (aclExceedAmountPercentage && (workflowName === 'SPECIAL_SALES_ORDER' || workflowName === 'NONTRADE_SPECIAL_SALES_ORDER') && workflowStepName === 'HOF' && workflowActionName === 'RECOMMEND' && Number(aclExceedAmountPercentage) <= 10) {
                showActionButton = false;
            }
            else if (dclExceedDaysPercentage && (workflowName === 'SPECIAL_SALES_ORDER' || workflowName === 'NONTRADE_SPECIAL_SALES_ORDER') &&  workflowStepName === 'HOF' && workflowActionName === 'RECOMMEND' && Number(dclExceedDaysPercentage) <= 10) {
                showActionButton = false;
            }
            setIsShowActionButton(showActionButton);
        }
    }, [isPermissionLoaded])

    const showFormModal = (): void => {
        setIsShowFormModal(true);
        handleReloadForm();
    }

    const hideFormModal = () => {
        setIsShowFormModal(false);
    }

    const handleReloadForm = () => {
        setReloadForm(Date.now());
    };

    const handleCallback = (event:any, action: string, recordId?:any, data?: any) => {
        if (action === 'hideForm') {
            hideFormModal();
        }
        else if (handleCallbackFunc) {
            handleCallbackFunc(event, action, recordId, data);
        }
    }

    if (isShowActionButton) {
        return (
            <>
                {children ? (<span onClick={() => showFormModal()}>{children}</span>) : (<Button type="primary" className="btn btn-submit btn-transition" onClick={() => showFormModal()}>{btnText}</Button>)}
                <WorkflowTransitionFormController
                    reloadForm={reloadForm}
                    isShowFormModal={isShowFormModal}
                    workflowRecordId={workflowRecordId}
                    workflowActionName={workflowActionName}
                    workflowStepName={workflowStepName}
                    workflowName={workflowName}
                    titleText={titleText}
                    submitBtnText={submitBtnText}
                    confirmText={confirmText}
                    hideFormModal={hideFormModal}
                    handleCallbackFunc={handleCallback}
                />
            </>
        );
    }

    return <></>
}

export default WorkflowTransitionAction;