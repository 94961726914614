import React, { FC } from 'react'
import {Descriptions, Row, Col} from 'antd';
import {DateTimeUtils} from "../../../../../../utils";
import {StatusEnum} from "../../../../../../utils/enums";
import EditAction from "../../../../../../components/Actions/EditAction";
import {SalesOrderItemAction} from "../Actions/SalesOrderItem.actions";
import DeleteAction from "../../../../../../components/Actions/DeleteAction";

const SalesOrderItemView: FC<any> = props => {
    const {itemData, handleCallbackFunc} = props;
    const DescriptionsItem = Descriptions.Item;

    return (
        <div className="view-page-content view-page-content-salesOrderItem">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <EditAction entityId={itemData.id} actionItem={SalesOrderItemAction.COMMON_ACTION.EDIT} handleCallbackFunc={handleCallbackFunc} />
                    <DeleteAction entityId={itemData.id} actionItem={SalesOrderItemAction.COMMON_ACTION.DELETE} handleCallbackFunc={handleCallbackFunc} />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Descriptions bordered column={1}>
                        <DescriptionsItem label="Title">{itemData.title}</DescriptionsItem>
                        <DescriptionsItem label="Description">{itemData.description}</DescriptionsItem>
                        <DescriptionsItem label="Status">{StatusEnum[itemData.status]}</DescriptionsItem>
                        <DescriptionsItem label="Created Time">{DateTimeUtils.formatDateTimeA(itemData.created_at)}</DescriptionsItem>
                        <DescriptionsItem label="Updated Time">{DateTimeUtils.formatDateTimeA(itemData.updated_at)}</DescriptionsItem>
                    </Descriptions>
                </Col>
            </Row>
        </div>
    );
}
export default React.memo(SalesOrderItemView);