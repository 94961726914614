import React from "react";
import { EyeOutlined, DeleteOutlined  } from '@ant-design/icons';
import AdjustmentFormController from "../Form/AdjustmentForm.controller";
import AdjustmentViewController from "../View/AdjustmentView.controller";
import ViewAction from "../../../../../components/Actions/ViewAction";
import EditAction from "../../../../../components/Actions/EditAction";
import DeleteAction from "../../../../../components/Actions/DeleteAction";
import LoadDrawerView from "../../../../../components/LoadComponent/LoadDrawerView";
import ExportAdjustmentController from "../../../../Integration/Export/components/Adjustment/ExportAdjustment.controller";

export const AdjustmentAction = {
    COMMON_ACTION: {
        CREATE: {
            type: 'item',
            title: 'New Adjustment',
            action: 'create',
            link: { to: '' },
            permission: 'scm:adjustment:create',
            component: AdjustmentFormController,
            className: 'grid-view-action',
            icon: <EyeOutlined />,
        },
        EDIT: {
            type: 'item',
            title: 'Edit Adjustment',
            action: 'edit',
            link: { to: '' },
            permission: 'scm:adjustment:edit',
            component: AdjustmentFormController,
            className: 'grid-view-action',
            icon: <EyeOutlined />,
        },
        VIEW: {
            type: 'item',
            title: 'View Adjustment',
            action: 'view',
            link: { to: '' },
            permission: 'scm:adjustment:view',
            component: AdjustmentViewController,
            className: 'grid-view-action',
            icon: <EyeOutlined />,
        },
        DELETE: {
            type: 'item',
            title: 'Delete Adjustment',
            action: 'delete',
            link: { to: '' },
            permission: 'scm:adjustment:delete',
            component: '',
            className: 'grid-view-action',
            icon: <EyeOutlined />,
        },
    },
    LIST_ITEM_ACTION: [
        {
            type: 'item',
            title: 'View',
            action: 'active',
            link: { to: '' },
            permission: 'scm:adjustment:view',
            component: ViewAction,
            className: 'grid-view-action',
            icon: <EyeOutlined />,
        },
        {
            type: 'item',
            title: 'Edit',
            action: 'inactive',
            link: { to: '' },
            permission: 'scm:adjustment:edit',
            component: EditAction,
            className: 'grid-edit-action',
            icon: <EyeOutlined />,
        },
        {
            type: 'item',
            title: 'Delete',
            action: 'delete',
            link: { to: '' },
            permission: 'scm:adjustment:delete',
            component: DeleteAction,
            className: 'grid-delete-action',
            icon: <DeleteOutlined />,
        },
    ],
    BULK_ACTION: (params) => {
        return {
            permission: 'scm:adjustment:multiSelect',
            action_list: [
                {
                    type: 'item',
                    title: 'Mark as Active',
                    action: 'active',
                    link: {to: ''},
                    permission: '',
                    component: '',
                    className: 'grid-view-action',
                    icon: <EyeOutlined/>,
                },
                {
                    type: 'item',
                    title: 'Mark as Inactive',
                    action: 'inactive',
                    link: {to: ''},
                    permission: '',
                    component: '',
                    className: 'grid-view-action',
                    icon: <EyeOutlined/>,
                },
                {
                    type: 'item',
                    title: 'Delete',
                    action: 'delete',
                    link: {to: ''},
                    permission: 'scm:adjustment:delete',
                    component: '',
                    className: 'grid-view-action',
                    icon: <EyeOutlined/>,
                },
                {
                    type: 'component',
                    title: '',
                    action: '',
                    link: { to: '' },
                    permission: '',
                    component: <LoadDrawerView modalTitle={'Export Adjustment'} component={ExportAdjustmentController} params={params}>Export</LoadDrawerView>,
                    className: 'grid-view-action',
                    icon: <EyeOutlined />,
                },
            ],
        }
    },
}


