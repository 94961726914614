export const rules = {
    customer_id: [
        { required: true, message: 'This field is required.' }
    ],
    employee_id: [
        { required: true, message: 'This field is required.' }
    ],
    adjustment_type: [
        { required: true, message: 'This field is required.' }
    ],
    amount: [
        { required: true, message: 'This field is required.' }
    ],
    date: [
        { required: true, message: 'This field is required.' }
    ],
};