import React, { ReactNode } from 'react';
import {Layout} from "antd";
import '../../../../assets/scss/style.scss';

interface Props {
    children: ReactNode;
    title?: string;
}

export const AuthLayout: React.FC<Props> = (props: Props) => {
    return (
        <Layout className="app-wrapper auth-app-wrapper">
            <Layout className="auth-layout">
                <Layout.Content className="content-wrapper">
                    <div className="main-content">
                        {props.children}
                    </div>
                </Layout.Content>
            </Layout>
        </Layout>
    )
}