import React, {FC, useEffect, useState} from 'react'
import {Avatar, Col, Menu, Row, Steps} from 'antd';
import {CONSTANT_CONFIG} from "../../../../../constants";
import SalesOrderViewTabInfo from "../Tabs/SalesOrderViewTab.info";
import WorkflowTransitionAction from "../../../../Workflow/components/WorkflowTransition/Actions/WorkflowTransitionAction";
import WorkflowTransitionTimelineList from "../../../../Workflow/components/WorkflowTransition/Content/WorkflowTransitionTimeline.list";
import {WorkflowStepApi} from "../../../../../api";
import {WorkflowApprovalStatusEnum, WorkflowStepEnum} from "../../../../../utils/enums";
import {SalesOrderAction} from "../Actions/SalesOrder.actions";
import SalesOrderViewTabMultiSchedule from "../Tabs/SalesOrderViewTab.multiSchedule";
import SalesOrderViewTabDeliveryOrder from "../Tabs/SalesOrderViewTab.deliveryOrder";
import SalesOrderViewTabChallan from "../Tabs/SalesOrderViewTab.challan";
import EditWorkflowAction from "../../../../Workflow/components/WorkflowTransition/Actions/EditWorkflowAction";
import DeleteWorkflowAction from "../../../../Workflow/components/WorkflowTransition/Actions/DeleteWorkflowAction";

const initialState  = {
    workflowStepData: [],
}

const SalesOrderView: FC<any> = props => {
    const { Step } = Steps;
    const {itemData, employeeInfo, customerInfo, loading, reloadView, viewTabIndex, handleCallbackFunc} = props;
    const [workflowName, setWorkflowName] = useState<any>(null);
    const [workflowStepData, setWorkflowStepData] = useState<any>( initialState.workflowStepData );
    const [workflowCurrentStep, setWorkflowCurrentStep] = useState(0);

    useEffect(() => {
        if (workflowName) {
            loadData();
        }
        if (itemData.id) {
            loadWorkflowName();
        }
    }, [itemData.id, itemData.customer_id, itemData.step_name, workflowName])

    const loadData = (): void => {
        const payload = {
            '$filter': "workflow_name='" + workflowName + "' AND status='1'",
            '$orderby': "sort_order asc",
        }
        WorkflowStepApi.list(payload)
            .then(res => {
                setWorkflowStepData(res.data.results);
                const currentStep = res.data.results.findIndex(item => item.name == itemData.step_name);
                setWorkflowCurrentStep(currentStep);
            });
    }

    const loadWorkflowName = (): void => {
        if (itemData.sales_order_type === 'SALES_ORDER' && itemData.customer_group === 'TRADE') {
            setWorkflowName('SALES_ORDER');
        }
        else if (itemData.sales_order_type === 'SALES_ORDER' && itemData.customer_group === 'NONTRADE') {
            setWorkflowName('NONTRADE_SALES_ORDER');
        }
        else if (itemData.sales_order_type === 'SPECIAL_SALES_ORDER' && itemData.customer_group === 'TRADE') {
            setWorkflowName('SPECIAL_SALES_ORDER');
        }
        else if (itemData.sales_order_type === 'SPECIAL_SALES_ORDER' && itemData.customer_group === 'NONTRADE') {
            setWorkflowName('NONTRADE_SPECIAL_SALES_ORDER');
        }
    }

    const handleTabSelect = (tabIndex: any) => {
        handleCallbackFunc(null, 'setViewTabIndex', tabIndex);
    }

    const ViewTabList = [
        {
            tabIndex: 1,
            title: 'Info',
        },
        {
            tabIndex: 2,
            title: 'Schedule',
        },
        {
            tabIndex: 3,
            title: 'Delivery Order',
        },
        {
            tabIndex: 4,
            title: 'Challan',
        },
    ];

    return (
        <div className="view-page-content view-page-content-sales-order">
            <Row>
                <Col span={19}>
                    <div className="view-page-content-left">
                        <div className="record-detail-info">
                            {!loading && itemData.sales_order_type === 'SALES_ORDER' && (
                                <div className="actions sales-order-actions">
                                    <WorkflowTransitionAction
                                        workflowRecordId={itemData.id}
                                        workflowActionName={"SEND_BACK"}
                                        workflowStepName={itemData.step_name}
                                        workflowName={workflowName}
                                        workflowStepAndPermissionList={{
                                            CONSENTER: 'scm:salesOrder:consenter:sendBackAction',
                                            NONTRADE_CONSENTER: 'scm:salesOrder:nonTradeConsenter:sendBackAction',
                                            COORDINATOR: 'scm:salesOrder:coordinator:sendBackAction',
                                            NONTRADE_COORDINATOR: 'scm:salesOrder:nonTradeCoordinator:sendBackAction',
                                            CC: 'scm:salesOrder:cc:sendBackAction',
                                        }}
                                        titleText="Send Back"
                                        btnText="Send Back"
                                        submitBtnText="Yes, Send Back"
                                        confirmText="Are you sure you want to send back this sales order?"
                                        handleCallbackFunc={handleCallbackFunc}
                                    />
                                    <WorkflowTransitionAction
                                        workflowRecordId={itemData.id}
                                        workflowActionName={"FORWARD"}
                                        workflowStepName={itemData.step_name}
                                        workflowName={workflowName}
                                        workflowStepAndPermissionList={{
                                            TSE: 'scm:salesOrder:tse:forwardAction',
                                            NONTRADE_TSE: 'scm:salesOrder:nonTradeTse:forwardAction',
                                            CONSENTER: 'scm:salesOrder:consenter:forwardAction',
                                            NONTRADE_CONSENTER: 'scm:salesOrder:nonTradeConsenter:forwardAction',
                                            COORDINATOR: 'scm:salesOrder:coordinator:forwardAction',
                                            NONTRADE_COORDINATOR: 'scm:salesOrder:nonTradeCoordinator:forwardAction',
                                        }}
                                        titleText="Forward"
                                        btnText="Forward"
                                        submitBtnText="Yes, Forward"
                                        confirmText="Are you sure you want to forward this sales order?"
                                        handleCallbackFunc={handleCallbackFunc}
                                    />
                                    <WorkflowTransitionAction
                                        workflowRecordId={itemData.id}
                                        workflowActionName={"APPROVE"}
                                        workflowStepName={itemData.step_name}
                                        workflowName={workflowName}
                                        workflowStepAndPermissionList={{
                                            CC: 'scm:salesOrder:cc:approveAction',
                                        }}
                                        titleText="Approve"
                                        btnText="Approve"
                                        submitBtnText="Yes, Approve"
                                        confirmText="Are you sure you want to approve this sales order?"
                                        handleCallbackFunc={handleCallbackFunc}
                                    />
                                    <WorkflowTransitionAction
                                        workflowRecordId={itemData.id}
                                        workflowActionName={"CANCEL"}
                                        workflowStepName={itemData.step_name}
                                        workflowName={workflowName}
                                        workflowStepAndPermissionList={{
                                            CC: 'scm:salesOrder:cc:cancelAction',
                                        }}
                                        titleText="Cancel"
                                        btnText="Cancel"
                                        submitBtnText="Yes, Cancel"
                                        confirmText="Are you sure you want to cancel this sales order?"
                                        handleCallbackFunc={handleCallbackFunc}
                                    />
                                </div>
                            )}
                            {!loading && itemData.sales_order_type === 'SPECIAL_SALES_ORDER' && (
                                <div className="actions special-sales-order-actions">
                                    <WorkflowTransitionAction
                                        workflowRecordId={itemData.id}
                                        workflowActionName={"SEND_BACK"}
                                        workflowStepName={itemData.step_name}
                                        workflowName={workflowName}
                                        workflowStepAndPermissionList={{
                                            CONSENTER: 'scm:salesOrder:consenter:sendBackAction',
                                            NONTRADE_CONSENTER: 'scm:salesOrder:nonTradeConsenter:sendBackAction',
                                            COORDINATOR: 'scm:salesOrder:coordinator:sendBackAction',
                                            NONTRADE_COORDINATOR: 'scm:salesOrder:nonTradeCoordinator:sendBackAction',
                                            CC: 'scm:salesOrder:cc:sendBackAction',
                                            HOT: 'scm:salesOrder:hot:sendBackAction',
                                            HOC: 'scm:salesOrder:hoc:sendBackAction',
                                            HOM: 'scm:salesOrder:hom:sendBackAction',
                                            HOF: 'scm:salesOrder:hof:sendBackAction',
                                            CFO: 'scm:salesOrder:cfo:sendBackAction',
                                            CEO: 'scm:salesOrder:ceo:sendBackAction',
                                            BOD: 'scm:salesOrder:bod:sendBackAction',
                                        }}
                                        titleText="Send Back"
                                        btnText="Send Back"
                                        submitBtnText="Yes, Send Back"
                                        confirmText="Are you sure you want to send back this sales order?"
                                        handleCallbackFunc={handleCallbackFunc}
                                    />
                                    <WorkflowTransitionAction
                                        workflowRecordId={itemData.id}
                                        workflowActionName={"FORWARD"}
                                        workflowStepName={itemData.step_name}
                                        workflowName={workflowName}
                                        workflowStepAndPermissionList={{
                                            TSE: 'scm:salesOrder:tse:forwardAction',
                                            NONTRADE_TSE: 'scm:salesOrder:nonTradeTse:forwardAction',
                                            CONSENTER: 'scm:salesOrder:consenter:forwardAction',
                                            NONTRADE_CONSENTER: 'scm:salesOrder:nonTradeConsenter:forwardAction',
                                            COORDINATOR: 'scm:salesOrder:coordinator:forwardAction',
                                            NONTRADE_COORDINATOR: 'scm:salesOrder:nonTradeCoordinator:forwardAction',
                                        }}
                                        titleText="Forward"
                                        btnText="Forward"
                                        submitBtnText="Yes, Forward"
                                        confirmText="Are you sure you want to forward this sales order?"
                                        handleCallbackFunc={handleCallbackFunc}
                                    />
                                    <WorkflowTransitionAction
                                        workflowRecordId={itemData.id}
                                        workflowActionName={"COMMENT"}
                                        workflowStepName={itemData.step_name}
                                        workflowName={workflowName}
                                        workflowStepAndPermissionList={{
                                            CC: 'scm:salesOrder:cc:commentAction',
                                        }}
                                        titleText="Comment"
                                        btnText="Comment"
                                        submitBtnText="Yes, Comment"
                                        confirmText="Are you sure you want to comment and forward this sales order?"
                                        handleCallbackFunc={handleCallbackFunc}
                                    />
                                    <WorkflowTransitionAction
                                        workflowRecordId={itemData.id}
                                        workflowActionName={"REVIEW"}
                                        workflowStepName={itemData.step_name}
                                        workflowName={workflowName}
                                        workflowStepAndPermissionList={{
                                            HOT: 'scm:salesOrder:hot:reviewAction',
                                            HOC: 'scm:salesOrder:hoc:reviewAction',
                                        }}
                                        titleText="Review"
                                        btnText="Review"
                                        submitBtnText="Yes, Review"
                                        confirmText="Are you sure you want to review and forward this sales order?"
                                        handleCallbackFunc={handleCallbackFunc}
                                    />
                                    <WorkflowTransitionAction
                                        workflowRecordId={itemData.id}
                                        workflowActionName={"RECOMMEND"}
                                        workflowStepName={itemData.step_name}
                                        workflowName={workflowName}
                                        workflowStepAndPermissionList={{
                                            HOM: 'scm:salesOrder:hom:recommendAction',
                                            HOF: 'scm:salesOrder:hof:recommendAction',
                                            CFO: 'scm:salesOrder:cfo:recommendAction',
                                            CEO: 'scm:salesOrder:ceo:recommendAction',
                                        }}
                                        titleText="Recommend"
                                        btnText="Recommend"
                                        submitBtnText="Yes, Recommend"
                                        confirmText="Are you sure you want to review and recommend this sales order?"
                                        handleCallbackFunc={handleCallbackFunc}
                                    />
                                    <WorkflowTransitionAction
                                        workflowRecordId={itemData.id}
                                        workflowActionName={"APPROVE"}
                                        workflowStepName={itemData.step_name}
                                        workflowName={workflowName}
                                        workflowStepAndPermissionList={{
                                            HOM: 'scm:salesOrder:hom:approveAction',
                                            HOF: 'scm:salesOrder:hof:approveAction',
                                            CFO: 'scm:salesOrder:cfo:approveAction',
                                            CEO: 'scm:salesOrder:ceo:approveAction',
                                            BOD: 'scm:salesOrder:bod:approveAction',
                                        }}
                                        aclExceedAmountPercentage={customerInfo.acl_exceed_amount_percentage}
                                        dclExceedDaysPercentage={customerInfo.dcl_exceed_days_percentage}
                                        titleText="Approve"
                                        btnText="Approve"
                                        submitBtnText="Yes, Approve"
                                        confirmText="Are you sure you want to approve this sales order?"
                                        handleCallbackFunc={handleCallbackFunc}
                                    />
                                    <WorkflowTransitionAction
                                        workflowRecordId={itemData.id}
                                        workflowActionName={"CANCEL"}
                                        workflowStepName={itemData.step_name}
                                        workflowName={workflowName}
                                        workflowStepAndPermissionList={{
                                            CC: 'scm:salesOrder:cc:cancelAction',
                                            HOT: 'scm:salesOrder:hot:cancelAction',
                                            HOC: 'scm:salesOrder:hoc:cancelAction',
                                            HOM: 'scm:salesOrder:hom:cancelAction',
                                            HOF: 'scm:salesOrder:hof:cancelAction',
                                            CFO: 'scm:salesOrder:cfo:cancelAction',
                                            CEO: 'scm:salesOrder:ceo:cancelAction',
                                            BOD: 'scm:salesOrder:bod:cancelAction',
                                        }}
                                        aclExceedAmountPercentage={customerInfo.acl_exceed_amount_percentage}
                                        dclExceedDaysPercentage={customerInfo.dcl_exceed_days_percentage}
                                        titleText="Cancel"
                                        btnText="Cancel"
                                        submitBtnText="Yes, Cancel"
                                        confirmText="Are you sure you want to cancel this sales order?"
                                        handleCallbackFunc={handleCallbackFunc}
                                    />
                                </div>
                            )}
                            <div className="photo">
                                <Avatar size={80} shape="circle" src={CONSTANT_CONFIG.CDN_URL + 'image/' + customerInfo?.image} alt="Profile Pic" />
                            </div>
                            <div className="info">
                                <h2>{customerInfo?.org_name}</h2>
                                <p>{customerInfo?.contact_person}, {customerInfo?.contact_number}</p>
                                <p>SO Number - {itemData.sales_order_no}, TSE Name - {employeeInfo?.name}</p>
                                <p>Status - {WorkflowApprovalStatusEnum[itemData.status]}, Customer Group: {customerInfo?.customer_group}</p>
                            </div>
                            <div className="record-detail-button-bar">
                                {!loading && (
                                    <>
                                        <EditWorkflowAction entityId={itemData.id} workflowName={workflowName} workflowStepName={itemData.step_name} actionItem={SalesOrderAction.COMMON_ACTION.EDIT} handleCallbackFunc={handleCallbackFunc} />
                                        <DeleteWorkflowAction entityId={itemData.id} workflowName={workflowName} workflowStepName={itemData.step_name} actionItem={SalesOrderAction.COMMON_ACTION.DELETE} handleCallbackFunc={handleCallbackFunc} />
                                    </>
                                )}
                            </div>
                            <div className="workflow-step">
                                <Steps current={workflowCurrentStep} size="small" >
                                    { workflowStepData?.map((item, index) => (
                                        <Step key={item.name} title={WorkflowStepEnum[item.name] || item.name} />
                                    ))}
                                </Steps>
                            </div>
                        </div>

                        <div className="record-detail-tabs">
                            <Menu className="record-detail-tab-menu" mode="horizontal">
                                {ViewTabList.map((tab: any, index: number) => (
                                    <Menu.Item
                                        key={`tab-${index}`}
                                        className={tab.tabIndex == viewTabIndex ? 'tab-item active' : 'tab-item'}
                                    >
                                        <a onClick={() => handleTabSelect(tab.tabIndex)} >{tab.title}</a>
                                    </Menu.Item>
                                ))}
                            </Menu>
                        </div>

                        <div className="record-detail-tabs-content">
                            {viewTabIndex == 1 && (
                                <SalesOrderViewTabInfo
                                    itemData={itemData}
                                    employeeInfo={employeeInfo}
                                    customerInfo={customerInfo}
                                    reloadView={reloadView}
                                />
                            )}
                            {viewTabIndex == 2 && (
                                <SalesOrderViewTabMultiSchedule itemData={itemData} />
                            )}
                            {viewTabIndex == 3 && (
                                <SalesOrderViewTabDeliveryOrder itemData={itemData} />
                            )}
                            {viewTabIndex == 4 && (
                                <SalesOrderViewTabChallan itemData={itemData} />
                            )}
                        </div>
                    </div>
                </Col>
                <Col span={5}>
                    <div className="view-page-content-right activity-timeline">
                        <h2>Activity Timeline</h2>
                        <WorkflowTransitionTimelineList
                            workflowRecordId={itemData.id}
                            workflowStepName={itemData.step_name}
                            workflowName={workflowName}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default React.memo(SalesOrderView);