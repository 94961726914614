const initialState = {
  loading: true,
  isLoaded: false,
  isAuthenticated: null,
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
  user: null,
  userId: null,
  profile:null,
  profileType: null,
  profileId: null,
  profileImage: null,
  profileCode: null,
  profileDesignation: null,
  userGroup: null,
  groupId: null,
  group: null,
  userRole: null,
  roleId: null,
  role: null,
  userWorkflowSteps: [] as any,
  scopes: [] as any,
  workspaceId: null,
  workspace: null,
  organizationId: null,
  organization: null,
  error: null,

  dispatchAuth: (action) => null,
  loadAuthState: () => null,
  clearAuthState: () => null,
  setLoading: (isShow) => null,
};

export default initialState;
