export enum LOOKUP_ENUM {
    'bag-type' = 'BAG_TYPE',
    'adjustment-type' = 'ADJUSTMENT_TYPE',
    'payment-mode' = 'PAYMENT_MODE',
    'security-type' = 'SECURITY_TYPE',
    'bank-list' = 'BANK_LIST',
    'vehicle-condition' = 'VEHICLE_CONDITION',
}

export const LOOKUP_CONST = {
    BAG_TYPE: {
        type: 'BAG_TYPE',
        title: 'Bag Type',
        description: 'Manage bag type'
    },
    ADJUSTMENT_TYPE: {
        type: 'ADJUSTMENT_TYPE',
        title: 'Adjustment Type',
        description: 'Manage adjustment type'
    },
    PAYMENT_MODE: {
        type: 'PAYMENT_MODE',
        title: 'Payment Mode',
        description: 'Manage payment mode'
    },
    SECURITY_TYPE: {
        type: 'SECURITY_TYPE',
        title: 'Security Type',
        description: 'Manage security type'
    },
    BANK_LIST: {
        type: 'BANK_LIST',
        title: 'Bank List',
        description: 'Manage bank list'
    },
    VEHICLE_CONDITION: {
        type: 'VEHICLE_CONDITION',
        title: 'Vehicle Condition',
        description: 'Manage vehicle condition'
    },
}
